import { 
    REQUEST_CITIES, 
    RECEIVE_CITIES, 
    CREATE_CITY,
    MODIFY_CITY,
    ON_DELETE_CITY,
    AFT_DELETE_CITY,
    ON_SAVE_CITY,
    AFT_SAVE_CITY,
    CLOSE_CITY_EDIT,
    SELECT_CITY,
    CITY_MODIFIED
} from "../ActionTypes/cityActionTypes";

const initialState = {
    error: null,
    isFetching: false,
    cities_list: null,
    selected_city: -1,
    city_editor: {
        mode: null,
        modified: false,
        city: null,
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_CITIES: {
            return { 
                ...state,
                error: null,
                isFetching: true
            };
        }
        case RECEIVE_CITIES: {
            return { 
                ...state,
                error: action.payload.error,
                isFetching: false,
                cities_list: action.payload.cities
            };
        }
        case CREATE_CITY: {
            return { 
                ...state,
                error: null,
                isFetching: false,
                city_editor: { ...state.city_editor, mode: 'create', city: {} } 
            };
        }
        case MODIFY_CITY: {
            return {
                ...state, 
                error: null,
                isFetching: false,
                city_editor: { ...state.city_editor, mode: 'edit', city: action.payload } 
            };
        }
        case ON_DELETE_CITY: {
            return { 
                ...state,
                error: null,
                isFetching: true,
            };
        }
        case AFT_DELETE_CITY: {
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };
        }
        case ON_SAVE_CITY: {
            return {
                ...state,
                error: null,
                isFetching: true,
            };
        }
        case AFT_SAVE_CITY: {
            return {
                ...state,
                error: action.payload.error,
                isFetching: false,
                city_editor: {
                    ...state.city_editor,
                    mode: 'edit',
                    city: {
                        ...state.city_editor.city,
                        ...action.payload.city,
                        id: (action.payload.mode == 'create') ? action.payload.id : state.city_editor.city.id
                    }
                }
            };
        }
        case CLOSE_CITY_EDIT: {
            return { 
                ...state, 
                city_editor: initialState.city_editor,
            };
        }
        case SELECT_CITY:
            return {
                ...state,
                selected_city: action.payload
            }
        case CITY_MODIFIED:
            return {
                ...state,
                city_editor: {
                    ...state.city_editor,
                    modified: action.payload.modified
                }
            }
        default:
            return state;
    }
}