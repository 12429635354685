import React from 'react';

import { Card } from 'antd';

export default class HelloPage extends React.Component{
    render(){
        return <Card>
            <p>Добро пожаловать в панель администрирования сайта "Домашний погребок"!</p>
            <p>Для редактирования содержимого сайта, а также обработки звонков и заказов воспользуйтесь необходимыми разделами меню!</p>
        </Card>
    }
}