import {
    REQUEST_EXCEL_UPLOAD,
    RECEIVE_EXCEL_UPLOAD
} from '../ActionTypes/excelActionTypes';

const initialState = {
    error: null,
    isFetching: false,
    log: null
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_EXCEL_UPLOAD: return {
            ...state,
            error: null,
            isFetching: true
        };
        case RECEIVE_EXCEL_UPLOAD: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            log: action.payload.log
        };
        default: return state;
    }
}