import {
    SET_PHONECALL_FILTER,
    SET_PHONECALL_LIST_PAGING,
    REQUEST_PHONECALLS,
    RECEIVE_PHONECALLS,
    PAGINATE_PHONECALLS,
    CREATE_PHONECALL,
    MODIFY_PHONECALL,
    CLOSE_PHONECALL_EDIT,
    PHONECALL_MODIFIED,
    SELECT_PHONECALL,
    ON_DELETE_PHONECALL,
    AFT_DELETE_PHONECALL,
    ON_SAVE_PHONECALL,
    AFT_SAVE_PHONECALL
} from '../ActionTypes/phoneCallActionTypes';

const initialState = {
    filter: {
        minDate: null,
        maxDate: null,
        status: null,
        order: 0,
    },
    error: null,
    isFetching: false,
    phoneCallList: null,
    phoneCallListPaging: {
        selectedPage: 0,
        totalPages: 0
    },
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: {}
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case SET_PHONECALL_FILTER: return {
            ...state,
            filter: {
                minDate: action.payload.minDate,
                maxDate: action.payload.maxDate,
                status: action.payload.status,
                order: action.payload.order
            }
        }
        case SET_PHONECALL_LIST_PAGING: return {
            ...state,
            phoneCallListPaging: {
                selectedPage: action.payload.selectedPage,
                totalPages: action.payload.totalPages
            }
        }
        case REQUEST_PHONECALLS: return {
            ...state,
            error: null,
            isFetching: true
        }
        case RECEIVE_PHONECALLS: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            phoneCallList: action.payload.phoneCallList
        }
        case PAGINATE_PHONECALLS: return {
            ...state,
            error: null,
            phoneCallListPaging: {
                ...state.phoneCallListPaging,
                selectedPage: action.payload.selectedPage
            }
        }
        case CREATE_PHONECALL: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'create',
                item: {}
            }
        }
        case MODIFY_PHONECALL: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'edit',
                item: action.payload.item
            }
        }
        case CLOSE_PHONECALL_EDIT: return {
            ...state,
            editor: initialState.editor
        }
        case PHONECALL_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        }
        case SELECT_PHONECALL: return {
            ...state,
            selectedId: action.payload.selectedId
        }
        case ON_SAVE_PHONECALL: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_SAVE_PHONECALL: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
        }
        case ON_DELETE_PHONECALL: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_DELETE_PHONECALL: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
        }
        default: return state;
    }
}