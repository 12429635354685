import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs, Select, Empty, Space, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import {
    onDeleteBill,
    onSaveBill,
    closeBillEdit,
    billModified
} from '../../Redux/Actions/billActions';
import {
    onProductSelection
} from '../../Redux/Actions/productSelectorActions';
import { connect } from 'react-redux';

import './BillEditor.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

class BillEditor extends React.Component{

    
    aftModify = false;

    initialState = {
        id: "",
        createDateTime: null,
        currencyCode: "RUB",
        status: 0,
        deliveryInfo: {
            type: "PickupInfo",
            customerName: "",
            customerSurname: "",
            customerPhone: "",
            customerEmail: ""
        },
        paymentInfo: {
            type: "UnknownPaymentInfo",
            details: ""
        },
        positions: []
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...props.editor.item,
            deliveryInfo: {
                ...this.initialState.deliveryInfo,
                ...props.editor.item.deliveryInfo
            },
            paymentInfo: {
                ...this.initialState.paymentInfo,
                ...props.editor.item.paymentInfo,
            }
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete =this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);

        this.onChangeDeliveryType = this.onChangeDeliveryType.bind(this);
        this.onChangeCustomerSurname = this.onChangeCustomerSurname.bind(this);
        this.onChangeCustomerName = this.onChangeCustomerName.bind(this);
        this.onChangeCustomerPhone = this.onChangeCustomerPhone.bind(this);
        this.onChangeCustomerEmail = this.onChangeCustomerEmail.bind(this);
        this.onChangeCityName = this.onChangeCityName.bind(this);
        this.onChangeInCityAddress = this.onChangeInCityAddress.bind(this);
        
        this.onChangePaymentType = this.onChangePaymentType.bind(this);
        this.onChangePaymentDetails = this.onChangePaymentDetails.bind(this);

        this.onChangePositionCost = this.onChangePositionCost.bind(this);
        this.onChangePositionQuantity = this.onChangePositionQuantity.bind(this);
        this.onDeletePosition = this.onDeletePosition.bind(this);
        this.onAddPosition = this.onAddPosition.bind(this);
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        if (!newProps.editor.item.id){
            delete this.state.id;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.item,
            deliveryInfo: {
                ...this.initialState.deliveryInfo,
                ...newProps.editor.item.deliveryInfo
            },
            paymentInfo: {
                ...this.initialState.paymentInfo,
                ...newProps.editor.item.paymentInfo,
            }
        });
    }

    onSave(e){
        this.props.onSaveBill(this.state, this.props.editor.mode);
    }

    onDelete(e){
        if (!window.confirm(`Вы действительно хотите удалить выбранный заказ?`))
            return;

        this.props.onDeleteBill(this.state);
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.closeBillEdit();
    }

    onChangeStatus(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            status: parseInt(e)
        });
        
        this.props.billModified(true);
    }

    onChangeCurrencyCode(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            currencyCode: e
        });
        this.props.billModified(true);
    }

    onChangeDeliveryType(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                type: e
            }
        });
        this.props.billModified(true);
    }

    onChangeCustomerSurname(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                customerSurname: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangeCustomerName(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                customerName: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangeCustomerPhone(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                customerPhone: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangeCustomerEmail(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                customerEmail: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangeCityName(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                cityName: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangeInCityAddress(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            deliveryInfo: {
                ...this.state.deliveryInfo,
                inCityAddress: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangePaymentType(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            paymentInfo: {
                ...this.state.paymentInfo,
                type: e
            }
        });
        this.props.billModified(true);
    }

    onChangePaymentDetails(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            paymentInfo: {
                ...this.state.paymentInfo,
                details: e.target.value
            }
        });
        this.props.billModified(true);
    }

    onChangePositionCost(indx, e){
        var arr = [ ...this.state.positions];
        arr[indx].cost = (e != "") ? e : 0;

        this.setState({
            ...this.state,
            positions: arr
        });

        this.aftModify = true;
        this.props.billModified(true);
    }

    onChangePositionQuantity(indx, e){
        var arr = [ ...this.state.positions];
        arr[indx].quantity = (e != "") ? e : 0;

        if (arr[indx].product && arr[indx].product.productInfo){
            arr[indx].cost = arr[indx].product.productInfo.cost * arr[indx].quantity;
        }

        this.setState({
            ...this.state,
            positions: arr
        });

        this.aftModify = true;
        this.props.billModified(true);
    }

    onDeletePosition(indx){
        var arr = [ ...this.state.positions];
        arr.splice(indx, 1);
        arr.forEach((item, idx) => item.num = idx + 1);

        this.setState({
            ...this.state,
            positions: arr
        });

        this.aftModify = true;
        this.props.billModified(true);
    }

    onAddPosition(product){
        var newItem = {
            num: this.state.positions.length + 1,
            name: product.name,
            quantity: 1,
            cost: product.productInfo.cost,
            product: product
        }

        this.setState({
            ...this.state,
            positions: [ ...this.state.positions, newItem ]
        });

        this.aftModify = true;
        this.props.billModified(true);
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание заказа';
                break;
            case 'edit':
                title_text = 'Изменение заказа';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null: case 'create':
                return <Fragment/>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button"
                    onClick={this.onDelete}
                >
                    Удалить заказ
                </Button>;
        }
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span class="label">Статус:</span>
                    <Select 
                        value={this.state.status.toString()}
                        onChange={this.onChangeStatus}
                        style={{ width: "100%" }}
                    >
                        <Option value="0">Объявлен</Option>
                        <Option value="1">Согласован</Option>
                        <Option value="2">Отправлен</Option>
                        <Option value="3">Отменён</Option>
                    </Select>
                </div>
                <div class="inpLine">
                    <span class="label">Валюта</span>
                    <Select
                        value={this.state.currencyCode}
                        onChange={this.onChangeCurrencyCode}
                        style={{ width: "100%" }}
                    >
                        <Option value="RUB">Рубли</Option>
                    </Select>
                </div>
                <div class="inpLine">
                    <span class="label">Дата создания:</span>
                    <Input value={moment(this.state.createDateTime).format("DD.MM.YYYY HH:mm:ss")} disabled={true}/>
                </div>
                
            </div>
        </TabPane>
    }

    renderDeliveryInfoTab(){
        return <TabPane tab="Способ доставки" key="2">
            <div class="mainTab">
                <div class="inpLine">
                    <span class="label">Способ:</span>
                    <Select
                        value={this.state.deliveryInfo.type}
                        onChange={this.onChangeDeliveryType}
                        style={{ width: "100%" }}
                    >
                        <Option value="PickupInfo">Самовывоз</Option>
                        <Option value="InCityDeliveryInfo">Доставка по городу</Option>
                        <Option value="CountryDeliveryInfo">Доставка по России</Option>
                    </Select>
                </div>
                <div class="inpLine">
                    <span class="label">Фамилия заказчика:</span>
                    <Input value={this.state.deliveryInfo.customerSurname} onChange={this.onChangeCustomerSurname}/>
                </div>
                <div class="inpLine">
                    <span class="label">Имя/отчество:</span>
                    <Input value={this.state.deliveryInfo.customerName} onChange={this.onChangeCustomerName}/>
                </div>
                <div class="inpLine">
                    <span class="label">Телефон:</span>
                    <Input value={this.state.deliveryInfo.customerPhone} onChange={this.onChangeCustomerPhone}/>
                </div>
                <div class="inpLine">
                    <span class="label">Эл. почта:</span>
                    <Input value={this.state.deliveryInfo.customerEmail} onChange={this.onChangeCustomerEmail}/>
                </div>
                {
                    (this.state.deliveryInfo.type == "CountryDeliveryInfo") &&
                    <div class="inpLine">
                        <span class="label">Город:</span>
                        <Input value={this.state.deliveryInfo.cityName} onChange={this.onChangeCityName}/>
                    </div>
                }
                {
                    (this.state.deliveryInfo.type != "PickupInfo") &&
                    <div class="inpLine">
                        <span class="label">Адрес:</span>
                        <Input value={this.state.deliveryInfo.inCityAddress} onChange={this.onChangeInCityAddress}/>
                    </div>
                }
            </div>
        </TabPane>
    }

    renderPaymentInfoTab(){
        return <TabPane tab="Способ оплаты" key="3">
            <div class="mainTab">
                <div class="inpLine">
                    <span class="label">Способ оплаты</span>
                    <Select
                        value={this.state.paymentInfo.type}
                        onChange={this.onChangePaymentType}
                        style={{ width: "100%" }}
                    >
                        <Option value="UnknownPaymentInfo">Неизвестный способ оплаты</Option>
                    </Select>
                </div>
                <div class="inpLine">
                    <span class="label">Описание:</span>
                    <TextArea rows={10} value={this.state.paymentInfo.details} onChange={this.onChangePaymentDetails}/>
                </div>
            </div>
        </TabPane>
    }

    renderPositionsEditor(){

        if (this.state.positions.length == 0)
            return <Empty description="Список позиций пуст"/>;

        return <div class="posEditor">
            <div class="cell head">
                <strong>Артикул</strong>
            </div>
            <div class="cell head">
                <strong>Название</strong>
            </div>
            <div class="cell head">
                <strong>Количество</strong>
            </div>
            <div class="cell head">
                <strong>Общая цена</strong>
            </div>
            <div class="cell head">
                <strong>Действия</strong>
            </div>
            {
                this.state.positions.map((pos, index) => {
                    return <Fragment>
                        <div class="cell">
                            <span>{(pos.product) ? pos.product.productInfo.article : "без артикула"}</span>
                        </div>
                        <div class="cell">
                            <span>{pos.name}</span>
                        </div>
                        <div class="cell">
                            <InputNumber min={0} precision={0} value={pos.quantity} onChange={e => this.onChangePositionQuantity(index, e)} style={{ width: "100%"}}/>                        
                        </div>
                        <div class="cell">
                            <InputNumber value={pos.cost} onChange={e => this.onChangePositionCost(index, e)} style={{ width: "100%"}}/>
                        </div>
                        <div class="cell">
                            <Button danger size="small" onClick={e => this.onDeletePosition(index)}>Удалить</Button>
                        </div>
                    </Fragment>
                })
            }
        </div>
    }

    renderPositionsTab(){
        return <TabPane tab="Позиции" key="4">
            <div class="posTab">
                <Space direction="vertical">
                    {this.renderPositionsEditor()}
                    <Button 
                        type="primary"
                        onClick={ f => this.props.onProductSelection((e) => this.onAddPosition(e))}
                    >
                        Добавить продукт
                    </Button>
                </Space>
            </div>
        </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            {this.renderMainTab()}
            {this.renderDeliveryInfoTab()}
            {this.renderPaymentInfoTab()}
            {this.renderPositionsTab()}
        </Tabs>
    }

    render(){
        if (this.props.editor.mode == null)
            return <Fragment/>;

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="billEditor" title={this.getCardTitle()}>
                {this.renderTabs()}
                <div class="btnLine">
                    <Space direction="horizontal">
                        <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                        {this.getDeleteButton()}
                    </Space>
                </div>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    editor: state.bill.editor,
    isFetching: state.bill.isFetching
});

export default connect(
    mapStateToProps,
    {
        onDeleteBill, onSaveBill,
        closeBillEdit, billModified,
        onProductSelection
    }
)(BillEditor);