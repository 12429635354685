import {
    REQUEST_SHOPS,
    RECEIVE_SHOPS,
    CREATE_SHOP,
    MODIFY_SHOP,
    ON_DELETE_SHOP,
    AFT_DELETE_SHOP,
    ON_SAVE_SHOP,
    AFT_SAVE_SHOP,
    CLOSE_SHOP_EDIT,
    SELECT_SHOP,
    SHOP_MODIFIED
} from '../ActionTypes/shopActionTypes';

const initialState = {
    error: null,
    isFetching: false,
    shopList: null,
    selectedShop: -1,
    shopEditor: {
        mode: null,
        modified: false,
        shop: { },
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_SHOPS: {
            return {
                ...state,
                error: null,
                isFetching: true
            };
        }
        case RECEIVE_SHOPS: {
            return {
                ...state,
                error: action.payload.error,
                isFetching: false,
                shopList: action.payload.shops
            }
        }
        case CREATE_SHOP: {
            return {
                ...state,
                error: null,
                shopEditor: {
                    mode: 'create',
                    shop: {} 
                }
            }
        }
        case MODIFY_SHOP: {
            return {
                ...state,
                error: null,
                shopEditor: { 
                    mode: 'edit', 
                    shop: action.payload
                }
            }
        }
        case ON_DELETE_SHOP: {
            return {
                ...state,
                error: null,
                isFetching: true
            }
        }
        case AFT_DELETE_SHOP:{
            return {
                ...state,
                error: action.payload.error,
                isFetching: false
            }
        }
        case ON_SAVE_SHOP: {
            return {
                ...state,
                error: null,
                isFetching: true
            }
        }
        case AFT_SAVE_SHOP: {
            return {
                ...state,
                error: action.payload.error,
                isFetching: false,
                shopEditor: {
                    mode: 'edit',
                    shop: {
                        ...state.shopEditor.shop,
                        id: (action.payload.mode == 'create') ? action.payload.shopid : state.shopEditor.shop.id
                    }
                }
            }
        }
        case CLOSE_SHOP_EDIT: {
            return {
                ...state,
                shopEditor: initialState.shopEditor
            }
        }
        case SELECT_SHOP: {
            return {
                ...state,
                selectedShop: action.payload
            }
        }
        case SHOP_MODIFIED: {
            return {
                ...state,
                shopEditor: {
                    ...state.shopEditor,
                    modified: action.payload.modified
                }
            }
        }
        default:
            return state;
    }
}