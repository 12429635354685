import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs, Select, Empty, Space, DatePicker, InputNumber } from 'antd';
import moment from 'moment';

import {
    onDeletePhoneCall,
    onSavePhoneCall,
    closePhoneCallEdit,
    phoneCallModified
} from '../../Redux/Actions/phoneCallActions';
import { connect } from 'react-redux';

import './PhoneCallEditor.less';

const { TabPane } = Tabs;
const { Option } = Select;

class PhoneCallEditor extends React.Component{

    aftModify = false;

    initialState = {
        id: null,
        phone: "",
        dateTime: null,
        status: false
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...props.editor.item
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete =this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);

        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        if (!newProps.editor.item.id){
            delete this.state.id;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.item,
        });
    }

    onSave(e){
        this.props.onSavePhoneCall(this.state, this.props.editor.mode);
    }

    onDelete(e){
        if (!window.confirm(`Вы действительно хотите удалить выбранный звонок?`))
            return;

        this.props.onDeletePhoneCall(this.state);
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.closePhoneCallEdit();
    }

    onChangePhone(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            phone: e.target.value
        });
        this.props.phoneCallModified(true);
    }

    onChangeStatus(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            status: e == "true"
        });
        this.props.phoneCallModified(true);
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание звонка';
                break;
            case 'edit':
                title_text = 'Изменение звонка';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null: case 'create':
                return <Fragment/>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button"
                    onClick={this.onDelete}
                >
                    Удалить звонок
                </Button>;
        }
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span class="label">Телефон:</span>
                    <Input value={this.state.phone} onChange={this.onChangePhone}/>
                </div>
                <div class="inpLine">
                    <span class="label">Статус:</span>
                    <Select 
                        value={this.state.status.toString()}
                        onChange={this.onChangeStatus}
                        style={{ width: "100%" }}
                    >
                        <Option value="true">Обработан</Option>
                        <Option value="false">Не обработан</Option>
                    </Select>
                </div>
                <div class="inpLine">
                    <span class="label">Дата создания:</span>
                    <Input value={moment.utc(this.state.dateTime).local().format("DD.MM.YYYY HH:mm:ss")} disabled={true}/>
                </div>
                
            </div>
        </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            {this.renderMainTab()}
        </Tabs>
    }

    render(){
        if (this.props.editor.mode == null)
            return <Fragment/>;

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="phoneCallEditor" title={this.getCardTitle()}>
                {this.renderTabs()}
                <div class="btnLine">
                    <Space direction="horizontal">
                        <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                        {this.getDeleteButton()}
                    </Space>
                </div>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    editor: state.phoneCall.editor,
    isFetching: state.phoneCall.isFetching
});

export default connect(
    mapStateToProps,
    {
        onDeletePhoneCall, onSavePhoneCall,
        closePhoneCallEdit, phoneCallModified
    }
)(PhoneCallEditor);