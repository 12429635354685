import React, { Fragment } from 'react';
import { Button, List, Card, Input, Spin, Tabs, InputNumber, Avatar, Empty, Space, DatePicker, TimePicker, Checkbox } from 'antd';


import { API_URL } from '../../Variables';

import AboutTab from './SubSections/AboutTab/AboutTab.js';
import SmallImgTab from './SubSections/SmallImgTab/SmallImgTab.js';
import ProductSublist from './SubSections/ProductSublist/ProductSublist.js';
import Scroll from '../../Utils/Scroll/Scroll.js';

import {
    onDeletePromo,
    onSavePromo,
    closePromoEdit,
    promoModified,
    onAddPromoProduct,
    onDeletePromoProduct,
    onSavePromoSmallImg,
    onDeletePromoSmallImg
} from '../../Redux/Actions/promoActions';
import {
    onProductSelection
} from '../../Redux/Actions/productSelectorActions';
import { connect } from 'react-redux';

import './PromoEditor.less';

const { TabPane } = Tabs;

class PromoEditor extends React.Component{

    aftModify = false;

    initialState = {
        id: 0,
        name: "",
        mainText: "",
        subText: "",
        onMainPage: false,
        image: [],
        description: "",
        order: 0,
        products: []
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...props.editor.item
        }

        

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);

        this.onModifyMainText = this.onModifyMainText.bind(this);
        this.onModifySubText = this.onModifySubText.bind(this);
        this.onModifyDescription = this.onModifyDescription.bind(this);
        this.onModifyOrder = this.onModifyOrder.bind(this);
        this.onModifyOnMainPage = this.onModifyOnMainPage.bind(this);

        this.onAddImage = this.onAddImage.bind(this);
        this.onDelImage = this.onDelImage.bind(this);
        
        this.onAddProduct = this.onAddProduct.bind(this);
        this.aftAddProduct = this.aftAddProduct.bind(this);
        this.onDeleteProduct = this.onDeleteProduct.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.item
        });
    }

    onSave(e){
        this.props.onSavePromo(this.state, this.props.editor.mode);
    }

    onDelete(e){
        if (!window.confirm(`Вы действительно хотите удалить выбранную промоакцию?`))
            return;

        this.props.onDeletePromo(this.state);
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.closePromoEdit();
    }

    onModifyMainText(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            mainText: e.target.value
        });
        this.props.promoModified(true);
    }

    onModifySubText(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            subText: e.target.value
        });
        this.props.promoModified(true);
    }

    onModifyOnMainPage(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            onMainPage: e.target.checked
        });
        this.props.promoModified(true);
    }

    onModifyDescription(e){
        if (e != this.state.description){
            this.aftModify = true;
            this.props.promoModified(true);
        }
        this.setState({ ...this.state, description: e });
    }

    onModifyOrder(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            order: (e != "") ? e : 0
        });
        this.props.promoModified(true);
    }

    aftAddProduct(e){
        this.props.onAddPromoProduct(this.state, e);
    }

    onAddProduct(e){
        this.props.onProductSelection(this.aftAddProduct);
    }

    onDeleteProduct(e){
        if (!window.confirm("Действительно удалить продукт?"))
            return;

        this.props.onDeletePromoProduct(this.state, e);
    }

    onAddImage(e){
        this.props.onSavePromoSmallImg(this.state, e);
    }

    onDelImage(e){
        if (!window.confirm("Действительно удалить изображение?"))
            return;

        this.props.onDeletePromoSmallImg(this.state);
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание промоакции';
                break;
            case 'edit':
                title_text = 'Изменение промоакции';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null: case 'create':
                return <Fragment/>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button" 
                    onClick={this.onDelete}
                >
                    Удалить промоакцию
                </Button>;
        }
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span>Основной текст:</span>
                    <Input onChange={this.onModifyMainText} value={ this.state.mainText } disabled={ !this.hasEditorRights() }/>
                </div>
                <div class="inpLine">
                    <span>Подтекст:</span>
                    <Input onChange={this.onModifySubText} value={ this.state.subText } disabled={ !this.hasEditorRights() }/>
                </div>
                <div class="inpLine">
                    <span>Порядок:</span>
                    <InputNumber onChange={this.onModifyOrder} value={ this.state.order } disabled={ !this.hasEditorRights() }/>
                </div>
                <div class="inpLine">
                    <span>Главный:</span>
                    <Checkbox onChange={this.onModifyOnMainPage} checked={this.state.onMainPage} disabled={ !this.hasEditorRights() }/>
                </div>
            </div>
        </TabPane>
    }

    renderDescriptionTab(){
        
        return <TabPane tab="Описание" key="2">
            <AboutTab
                value={this.state.description}
                onChange={this.onModifyDescription}
                disabled={ !this.hasEditorRights() }
                key={ this.state.id }
            />
        </TabPane>
    }

    renderImageTab(){

        return <TabPane tab="Изображение" key="3">
            <SmallImgTab
                imageList={this.state.image}
                onSave={this.onAddImage}
                onDelete={this.onDelImage}
                disabled={ !this.hasEditorRights() }
            />
        </TabPane>
    }

    renderProductTab(){
        return <TabPane tab="Продукты" key="4">
            <ProductSublist
                products={this.state.products}
                onAddProduct={this.onAddProduct}
                onDeleteProduct={this.onDeleteProduct}
                disabled={ !this.hasEditorRights() }
            />
        </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            { this.renderMainTab() }
            { this.renderDescriptionTab() }
            { this.props.editor.mode == 'edit' && this.renderImageTab() }
            { this.props.editor.mode == 'edit' && this.renderProductTab() }
        </Tabs>
    }

    render(){
        if (this.props.editor.mode == null)
            return <Fragment/>

        return <Spin tip="Выполнение операции" spinning={this.props.isFetching}>
            <Card className="promoEditor" title={this.getCardTitle()}>
                {this.renderTabs()}
                {
                    this.hasEditorRights() &&
                    <div class="btnLine">
                        <Space direction="horizontal">
                            <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                            {this.getDeleteButton()}
                        </Space>
                    </div>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.promo.editor,
    isFetching: state.promo.isFetching
});

export default connect(
    mapStateToProps,
    {
        onDeletePromo, onSavePromo, closePromoEdit, promoModified,
        onAddPromoProduct, onDeletePromoProduct,
        onSavePromoSmallImg, onDeletePromoSmallImg,
        onProductSelection
    }
)(PromoEditor);