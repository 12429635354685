import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs,InputNumber, Empty } from 'antd';
import * as _ from 'lodash';

import { onDeleteShop, onSaveShop, closeShopEdit, shopModified } from '../../Redux/Actions/shopActions';
import { onCitySelection } from '../../Redux/Actions/citySelectorActions';
import { connect } from 'react-redux';

import TimeTable from './SubSections/TimeTable/TimeTable.js';
import Emails from './SubSections/Emails/Emails.js';
import YaMap from './SubSections/YaMap/YaMap.js';
import PhoneList from './SubSections/PhoneList/PhoneList.js';

import './ShopEditor.less';

const { TabPane } = Tabs;

function isEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
}

class ShopEditor extends React.Component{

    constructor(props){
        super(props);

        this.initialState = {
            id: 0,
            name: "",
            address: "",
            order: 0,
            yaMap: "",
            shopOSId: "",
            city: {},
            emails: [],
            phones: [],
            timeTable: []
        }

        this.state = {
            ...this.initialState,
            ...props.editor.shop
        };

        this.aftModify = false;

        this.onSaveShop = this.onSaveShop.bind(this);
        this.onDeleteShop = this.onDeleteShop.bind(this);
        this.onCloseEditor = this.onCloseEditor.bind(this);
        this.onModifyName = this.onModifyName.bind(this);
        this.onModifyAddress = this.onModifyAddress.bind(this);
        this.onModifyOrder = this.onModifyOrder.bind(this);
        this.onModifyTimeTable = this.onModifyTimeTable.bind(this);
        this.onModifyEmails = this.onModifyEmails.bind(this);
        this.onModifyYaMap = this.onModifyYaMap.bind(this);
        this.onModifyPhoneList = this.onModifyPhoneList.bind(this);
        this.onModifyCity = this.onModifyCity.bind(this);
        this.onModifyShopOsId = this.onModifyShopOsId.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    componentWillReceiveProps(newProps){

        if (this.aftModify == true ){
            this.aftModify = false;
            return;
        }
        
        var newState = {
            ...this.initialState,
            ...newProps.editor.shop
        };

        this.setState(newState);
    }

    getCardTitle(){

        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание магазина';
                break;
            case 'edit':
                title_text = 'Изменение магазина';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onCloseEditor}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null:
                return <Fragment></Fragment>;
            case 'create':
                return <Fragment></Fragment>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button" 
                    onClick={this.onDeleteShop}
                >
                    Удалить магазин
                </Button>;
        }
    }

    onSaveShop(e){
        this.props.onSaveShop(this.state, this.props.editor.mode);
    }

    onDeleteShop(e){
        if (!window.confirm("Вы действительно хотите удалить выбранный магазин?"))
            return;

        this.props.onDeleteShop(this.state);
    }

    onCloseEditor(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите закрыть редактор? При закрытии внесённые изменения не будут сохранены!")){
            return;
        }
        this.props.closeShopEdit();
    }

    onModifyName(e){
        this.aftModify = true;
        this.setState({ ...this.state, name: e.target.value });
        this.props.shopModified(true);
    }

    onModifyAddress(e){
        this.aftModify = true;
        this.setState({ ...this.state, address: e.target.value });
        this.props.shopModified(true);
    }

    onModifyOrder(e){
        this.aftModify = true;
        this.setState({ ...this.state, order: (e != "") ? e : 0 });
        this.props.shopModified(true);
    }

    onModifyTimeTable(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            timeTable: e
        });
        this.props.shopModified(true);
    }

    onModifyEmails(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            emails: e
        });
        this.props.shopModified(true);
    }

    onModifyYaMap(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            yaMap: e
        });
        this.props.shopModified(true);
    }

    onModifyShopOsId(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            shopOSId: e.target.value
        });
        this.props.shopModified(true);
    }

    onModifyPhoneList(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            phones: e
        });
        this.props.shopModified(true);
    }

    onModifyCity(e){
        this.aftModify = true;
        this.setState({ ...this.state, city: e });
        this.props.shopModified(true);
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="inpLine">
                <span>Название:</span>
                <Input onChange={this.onModifyName} value={ this.state.name } disabled={!this.hasEditorRights()}/>
            </div>
            <div class="inpLine">
                <span>Адрес:</span>
                <Input onChange={this.onModifyAddress} value={ this.state.address } disabled={!this.hasEditorRights()}/>
            </div>
            <div class="inpLine">
                <span>Порядок:</span>
                <InputNumber onChange={this.onModifyOrder} value={ this.state.order } disabled={!this.hasEditorRights()}/>
            </div>
            <div class="inpLine">
                <span>ID 1C:</span>
                <Input onChange={this.onModifyShopOsId} value={ this.state.shopOSId } disabled={!this.hasEditorRights()}/>
            </div>
        </TabPane>
    }

    renderTimeTableTab(){
        return <TabPane tab="Расписание" key="2">
            <TimeTable timeTable={this.state.timeTable} onModify={ this.onModifyTimeTable } disabled={!this.hasEditorRights()}/>
        </TabPane>
    }

    renderEmailsTab(){
        return <TabPane tab="Электронная почта" key="3">
            <Emails emails={this.state.emails} onModify={ this.onModifyEmails } disabled={!this.hasEditorRights()}/>
        </TabPane>
    }

    renderYaMap(){
        return <TabPane tab="Яндекс-карта" key="4">
            <YaMap yaMap={this.state.yaMap} onModify={ this.onModifyYaMap } disabled={!this.hasEditorRights()}/>
        </TabPane>
    }

    renderPhoneList(){
        return <TabPane tab="Телефоны" key="5">
            <PhoneList phones={this.state.phones} onModify={this.onModifyPhoneList} disabled={!this.hasEditorRights()}/>
        </TabPane>
    }

    renderCity(){
        if (this.state.city == null || isEmpty(this.state.city))
            return <Empty description="Город не выбран"/>

        return <Fragment>
            <span>Данный магазин принадлежит городу <b>{ this.state.city.name }</b></span>
            <br/>
            <span>Регион: <b>{ this.state.city.region }</b></span>
            <br/>
        </Fragment>
    }

    renderCitySelect(){
        
        return <TabPane tab="Город" key="6">
            { this.renderCity() }
            {
                this.hasEditorRights() &&
                <Button 
                    type="primary"
                    onClick={ e => this.props.onCitySelection(this.onModifyCity) }
                >Сменить город</Button>
            }
        </TabPane>
    }

    render(){
        if (this.props.editor.mode == null){
            return <Fragment/>
        }

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="shopEditor" title={this.getCardTitle()}>
                <Tabs defaultActiveKey="1">
                    { this.renderMainTab() }
                    { this.renderCitySelect() }
                    { this.renderPhoneList() }
                    { this.renderEmailsTab() }
                    { this.renderTimeTableTab() }
                    { this.renderYaMap() }
                </Tabs>
                {
                    this.hasEditorRights() &&
                    <div class="btnLine">
                        <Button type="primary" onClick={this.onSaveShop}>Сохранить</Button>
                        { this.getDeleteButton() }
                    </div>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.shop.shopEditor,
    isFetching: state.shop.isFetching
});

export default connect(
    mapStateToProps,
    { onSaveShop, onDeleteShop, closeShopEdit, onCitySelection, shopModified }
)(ShopEditor);