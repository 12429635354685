import {
    ON_CITY_SELECTION,
    AFT_CITY_SELECTION,
    ON_CITY_SELECTION_REQUEST,
    ON_CITY_SELECTION_RECEIVE,
    ON_CITY_SELECTION_SELECT,
    ON_CITY_SELECTION_CLOSE
} from "../ActionTypes/citySelectorActionTypes";

const initialState = {
    visible: false,
    error: null,
    isFetching: false,
    itemList: null,
    selectedId: -1,
    selectedItem: null,
    selectHandler: null
}

export default function(state = initialState, action){
    switch (action.type){
        case ON_CITY_SELECTION: {
            return {
                ...state,
                visible: true,
                error: null,
                isFetching: true,
                selectHandler: action.payload.selectHandler
            }
        }
        case AFT_CITY_SELECTION: {
            return initialState;
        }
        case ON_CITY_SELECTION_REQUEST: {
            return {
                ...state,
                error: null,
                isFetching: true
            }
        }
        case ON_CITY_SELECTION_RECEIVE: {
            return {
                ...state,
                error: action.payload.error,
                isFetching: false,
                itemList: action.payload.itemList
            }
        }
        case ON_CITY_SELECTION_SELECT: {
            return {
                ...state,
                selectedId: action.payload.selectedId,
                selectedItem: action.payload.selectedItem
            }
        }
        case ON_CITY_SELECTION_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}