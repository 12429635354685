import {
    REQUEST_USERS,
    RECEIVE_USERS,
    CREATE_USER,
    MODIFY_USER,
    CLOSE_USER_EDIT,
    ON_DELETE_USER,
    AFT_DELETE_USER,
    ON_SAVE_USER,
    AFT_SAVE_USER,
    SELECT_USER,
    USER_MODIFIED,
    RESET_UMANAGER_ERROR
} from '../ActionTypes/uManagerActionTypes';

const initialState = {
    error: null,
    isFetching: true,
    itemList: null,
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: { }
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_USERS: return {
            ...state,
            isFetching: true
        }
        case RECEIVE_USERS: return {
            ...state,
            error: (action.payload.error == null) ? state.error : action.payload.error,
            isFetching: false,
            itemList: action.payload.itemList
        }
        case CREATE_USER: return {
            ...state,
            editor: {
                mode: 'create',
                modified: false,
                item: {}
            }
        }
        case MODIFY_USER: return {
            ...state,
            editor: {
                mode: 'edit',
                modified: false,
                item: action.payload.item
            }
        }
        case CLOSE_USER_EDIT: return {
            ...state,
            editor: initialState.editor
        }
        case ON_DELETE_USER: return {
            ...state,
            isFetching: true
        }
        case AFT_DELETE_USER: return {
            ...state,
            error: (action.payload.error == null) ? state.error : action.payload.error,
            isFetching: false
        }
        case ON_SAVE_USER: return {
            ...state,
            isFetching: true
        }
        case AFT_SAVE_USER: return {
            ...state,
            error: (action.payload.error == null) ? state.error : action.payload.error,
            isFetching: false
        }
        case USER_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        }
        case SELECT_USER: return {
            ...state,
            selectedId: action.payload.selectedId
        }
        case RESET_UMANAGER_ERROR: return {
            ...state,
            error: null,
        }
        default: return state;
    }
}