import {
    ON_PRODUCT_GROUP_SELECTION,
    AFT_PRODUCT_GROUP_SELECTION,
    ON_PRODUCT_GROUP_SELECTION_REQUEST,
    ON_PRODUCT_GROUP_SELECTION_RECEIVE,
    ON_PRODUCT_GROUP_SELECTION_SELECT,
    ON_PRODUCT_GROUP_SELECTION_CLOSE
} from '../ActionTypes/productGroupSelectorActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const onProductGroupSelectionSelect = (selectedItem, selectedId) => ({
    type: ON_PRODUCT_GROUP_SELECTION_SELECT,
    payload: {
        selectedItem: selectedItem,
        selectedId: selectedId
    }
});

function removeRestrictedId(group, id){
    if (!group.children)
        return;

    var removeIndex = group.children.map(child => child.id).indexOf(id);
    if (removeIndex != -1)
        group.children.splice(removeIndex, 1);

    group.children.forEach((child) => removeRestrictedId(child, id));
}

export const onProductGroupSelectionReceive = (rootGroup, error) => (dispatch, getState) => {
    
    var restrictedId = getState().productGroupSelector.restrictedId;
    if (rootGroup.id == restrictedId)
        rootGroup = null;
    else
        removeRestrictedId(rootGroup, restrictedId);
    
    dispatch({
        type: ON_PRODUCT_GROUP_SELECTION_RECEIVE,
        payload: {
            rootGroup: rootGroup,
            error: error
        }
    });
    dispatch(onProductGroupSelectionSelect(null, -1));
}

export const onProductGroupSelectionRequest = () => dispatch => {
    dispatch({
        type: ON_PRODUCT_GROUP_SELECTION_REQUEST,
        payload: null
    });

    var url = new URL("productgroup/root", API_URL);
    url.searchParams.set('allChildren', 'true');

    axios.get(url.toString())
    .then((response) => {
        dispatch(onProductGroupSelectionReceive(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(onProductGroupSelectionReceive(null, error));
        }
    });
}

export const onProductGroupSelectionClose = () => ({
    type: ON_PRODUCT_GROUP_SELECTION_CLOSE,
    payload: null
});

export const onProductGroupSelection = (selectHandler, restrictedId) => dispatch => {
    dispatch({
        type: ON_PRODUCT_GROUP_SELECTION,
        payload: {
            selectHandler: selectHandler,
            restrictedId: restrictedId
        }
    });
    dispatch(onProductGroupSelectionRequest());
}

export const aftProductGroupSelection = () => (dispatch, getState) => {
    var handler = getState().productGroupSelector.selectHandler;
    var selectedItem = getState().productGroupSelector.selectedItem;
    
    dispatch({
        type: AFT_PRODUCT_GROUP_SELECTION,
        payload: null
    });

    if (handler != null && selectedItem !== undefined)
        handler(selectedItem);
}