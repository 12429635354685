import {
    REQUEST_EXCEL_UPLOAD,
    RECEIVE_EXCEL_UPLOAD
} from '../ActionTypes/excelActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const receiveExcelUpload = (log, error) => dispatch => {
    dispatch({
        type: RECEIVE_EXCEL_UPLOAD,
        payload: {
            error: error,
            log: log
        }
    });
}

export const requestExcelUpload = (file) => (dispatch, getState) => {
    dispatch({
        type: REQUEST_EXCEL_UPLOAD,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL('excel/import', API_URL);

    var formData = new FormData();
    formData.append("excelFile", file);

    axios.post(
        url.toString(),
        formData,
        { headers: headers }
    )
    .then((response) => {
        dispatch(receiveExcelUpload(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveExcelUpload(null, error));
        }
    })
}