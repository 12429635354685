import {
    SET_PRODUCT_FILTER,
    SET_PRODUCT_LIST_PAGING,
    REQUEST_PRODUCTS,
    RECEIVE_PRODUCTS,
    PAGINATE_PRODUCTS,
    CREATE_PRODUCT,
    MODIFY_PRODUCT,
    CLOSE_PRODUCT_EDIT,
    ON_DELETE_PRODUCT,
    AFT_DELETE_PRODUCT,
    ON_SAVE_PRODUCT,
    AFT_SAVE_PRODUCT,
    SELECT_PRODUCT,
    PRODUCT_MODIFIED,
    ON_PRODUCT_ADD_CATEGORY,
    AFT_PRODUCT_ADD_CATEGORY,
    ON_PRODUCT_DEL_CATEGORY,
    AFT_PRODUCT_DEL_CATEGORY,
    ON_PRODUCT_ADD_SMALL_IMG,
    AFT_PRODUCT_ADD_SMALL_IMG,
    ON_PRODUCT_DEL_SMALL_IMG,
    AFT_PRODUCT_DEL_SMALL_IMG,
    ON_PRODUCT_ADD_GALLERY,
    AFT_PRODUCT_ADD_GALLERY,
    ON_PRODUCT_DEL_GALLERY,
    AFT_PRODUCT_DEL_GELLERY
} from '../ActionTypes/productActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";
import { sortByOrderAndName } from "../../funcs";

export const setProductFilter = (name, article, onlyHidden) => dispatch => {
    dispatch({
        type: SET_PRODUCT_FILTER,
        payload: {
            name: name,
            article: article,
            onlyHidden: onlyHidden
        }
    });
}

export const setProductListPaging = (selectedPage, totalPages) => dispatch => {
    dispatch({
        type: SET_PRODUCT_LIST_PAGING,
        payload: {
            selectedPage: selectedPage,
            totalPages: totalPages
        }
    });
}

export const productModified = (modified) => dispatch => {
    dispatch({
        type: PRODUCT_MODIFIED,
        payload: { modified: modified }
    });
}

export const selectProduct = (selectedId) => dispatch => {
    dispatch({
        type: SELECT_PRODUCT,
        payload: { selectedId: selectedId }
    });
}

export const receiveProducts = (productList, error, selectedPage, totalPages) => dispatch => {
    dispatch({
        type: RECEIVE_PRODUCTS,
        payload: {
            error: error,
            productList: productList
        }
    });
    dispatch(setProductListPaging(selectedPage, totalPages));
}

export const requestProducts = (initialPage = false) => (dispatch, getState) => {
    dispatch({
        type: REQUEST_PRODUCTS,
        payload: null
    })

    var headers = {
        'Content-Type': 'application/json',
    };

    var url = new URL("product/search", API_URL);

    var filt = getState().product.filter;

    var querry = {
        withCateg: false,
        article: filt.article,
        searchText: filt.name,
        pageNum: (initialPage == true) ? 0 : getState().product.productListPaging.selectedPage,
        pageCount: 10,
        getParams: {
            smallImg: true,
            gallery: true,
            about: true,
            info: true,
            counts: true,
            group: true,
            filters: true
        }
    }

    if (filt.onlyHidden == true){
        querry.hidden = true;
    }

    axios.post(
        url.toString(),
        querry,
        { headers: headers }
    )
    .then((response) => {
        dispatch(receiveProducts(response.data.products, null, response.data.page, response.data.pages));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveProducts(null, error, 0, 0));
        }
    });
}

export const paginateProducts = (selectedPage) => dispatch => {
    dispatch({
        type: PAGINATE_PRODUCTS,
        payload: {
            selectedPage: selectedPage
        }
    });
    dispatch(requestProducts());
}

export const createProduct = () => dispatch => {
    dispatch(selectProduct(-1));
    dispatch(productModified(false));
    dispatch({
        type: CREATE_PRODUCT,
        payload: null
    });
}

export const modifyProduct = (product) => dispatch => {
    dispatch(selectProduct(product.id));
    dispatch(productModified(false));
    dispatch({
        type: MODIFY_PRODUCT,
        payload: {
            item: product
        }
    });
}

export const closeProductEdit = () => dispatch => {
    dispatch(selectProduct(-1));
    dispatch(productModified(false));
    dispatch({
        type: CLOSE_PRODUCT_EDIT,
        payload: null
    });
}

export const aftDeleteProduct = error => dispatch => {
    dispatch({
        type: AFT_DELETE_PRODUCT,
        payload: { error: error }
    });
    dispatch(closeProductEdit());
    dispatch(requestProducts());
}

export const onDeleteProduct = product => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PRODUCT,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "product/" + product.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeleteProduct(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteProduct(error));
        }
    });
}

export const aftSaveProduct = (product, productId, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_PRODUCT,
        payload: { error: error }
    });

    if (error == null){
        var newProduct = product;
        if (productId != -1)
            newProduct.id = productId;
        dispatch(modifyProduct(newProduct));
    }
    dispatch(requestProducts());
}

export const onSaveProduct = (product, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_PRODUCT,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("product", API_URL);
    if (product.productGroup.id != undefined){
        url.searchParams.set("groupId", product.productGroup.id);
    }

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                product,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                product,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSaveProduct(product, -1, null));
        else
            dispatch(aftSaveProduct(product, parseInt(response.data), null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveProduct(product, -1, error));
        }
    });
}

export const aftProductAddCategory = (newCategories ,error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_ADD_CATEGORY,
        payload: { 
            error: error,
            newCategories: newCategories 
        }
    });
    
    dispatch(requestProducts());
}

export const onProductAddCategory = (product, category) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_ADD_CATEGORY,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var path = `product/${product.id.toString()}/category/${category.id.toString()}`;
    var url = new URL(path, API_URL);

    axios.post(
        url.toString(),
        null,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductAddCategory(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductAddCategory(null, error));
        }
    })
}

export const aftProductDelCategory = (newCategories ,error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_DEL_CATEGORY,
        payload: { 
            error: error,
            newCategories: newCategories 
        }
    });
    
    dispatch(requestProducts());
}

export const onProductDelCategory = (product, category) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_DEL_CATEGORY,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var path = `product/${product.id.toString()}/category/${category.id.toString()}`;
    var url = new URL(path, API_URL);

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductDelCategory(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductDelCategory(null, error));
        }
    })
}


export const aftProductAddSmallImg = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_ADD_SMALL_IMG,
        payload: { newImg: newImg, error: error }
    });

    dispatch(requestProducts());
}

export const onProductAddSmallImg = (product, file) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_ADD_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`product/${ product.id.toString() }/smallimg`, API_URL);

    var formData = new FormData();
    formData.append("img", file);

    axios.post(
        url.toString(),
        formData,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductAddSmallImg(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductAddSmallImg(null, error));
        }
    });
}

export const aftProductDelSmallImg = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_DEL_SMALL_IMG,
        payload: { newImg: newImg, error: error }
    });

    dispatch(requestProducts());
}

export const onProductDelSmallImg = (product) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_DEL_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`product/${ product.id.toString() }/smallimg`, API_URL);

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductDelSmallImg(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductDelSmallImg(null, error));
        }
    });
}

export const aftProductAddGallery = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_ADD_GALLERY,
        payload: { newImg: newImg, error: error }
    });
    
    dispatch(requestProducts());
}

export const onProductAddGallery = (product, file, pos = null) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_ADD_GALLERY,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`product/${ product.id.toString() }/gallery`, API_URL);
    if (pos != null)
        url.searchParams.set('position', pos.toString());

    var formData = new FormData();
    formData.append("img", file);

    axios.post(
        url.toString(),
        formData,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductAddGallery(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductAddGallery(null, error));
        }
    });
}

export const aftProductDelGallery = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_PRODUCT_DEL_GELLERY,
        payload: { newImg: newImg, error: error }
    });
    
    dispatch(requestProducts());
}

export const onProductDelGallery = (product, pos = null) => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_DEL_GALLERY,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`product/${ product.id.toString() }/gallery`, API_URL);
    if (pos != null)
        url.searchParams.set('position', pos.toString());


    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftProductDelGallery(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftProductDelGallery(null, error));
        }
    });
}