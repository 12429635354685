import React, { Fragment } from 'react';
import { Card, Input, Space, Button, Spin, Alert } from 'antd';
import Scroll from '../../Utils/Scroll/Scroll.js';

import { requestExcelUpload, receiveExcelUpload } from '../../Redux/Actions/excelActions';
import { connect } from 'react-redux';

import './ExcelPage.less';

class ExcelPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            file: null
        }

        this.onChangeFile = this.onChangeFile.bind(this);
        this.onRequest = this.onRequest.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onChangeFile(e){
        if (!e.target.files || !e.target.files.length || e.target.files.length == 0){
            this.setState({
                ...this.state,
                file: null
            });
            return;
        }

        this.setState({
            ...this.state,
            file: e.target.files[0]
        });
    }

    onRequest(e){
        this.props.requestExcelUpload(this.state.file);
    }

    renderError(){
        if (this.props.error == null)
            return <Fragment/>;

        return <Alert
            type="error"
            message="Ошибка импорта"
            description="Не удалось выполнить импорт из Excel"
        />
    }

    renderInput(){
        return <Spin tip="Загрузка..." spinning={this.props.isFetching}>
            <Card title="Выбор файла">
                <Space direction='vertical'>
                    <Input 
                        type="file" 
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onChangeFile}
                    />
                    {
                        this.hasEditorRights() &&
                        <Button 
                            type="primary" 
                            size="small" 
                            onClick={this.onRequest}
                            disabled={this.state.file === null}
                            block
                        >
                            Импортировать
                        </Button>
                    }
                </Space>
            </Card>
        </Spin>
    }

    renderLog(){
        if (this.props.log == null)
            return <Fragment/>;

        return <Spin tip="Загрузка..." spinning={this.props.isFetching}>
            <Card title="Лог">
                <p>Загрузка окончена! Список ошибок</p>
                <hr/>                
                <Scroll maxHeight="500" minHeight="0">
                {
                    this.props.log.map((row) => {
                        return <p>{row}</p>
                    })
                }
                </Scroll>
            </Card>
        </Spin>
    }

    render(){
        return <div class="excelPage">
            <Space direction="vertical">
                {this.renderError()}
                {this.renderInput()}
                {this.renderLog()}
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    error: state.excel.error,
    isFetching: state.excel.isFetching,
    log: state.excel.log
});

export default connect(
    mapStateToProps,
    { requestExcelUpload, receiveExcelUpload }
)(ExcelPage);