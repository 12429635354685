export const REQUEST_PROMOS = 'REQUEST_PROMOS';
export const RECEIVE_PROMOS = 'RECEIVE_PROMOS';

export const CREATE_PROMO = 'CREATE_PROMO';
export const MODIFY_PROMO = 'MODIFY_PROMO';
export const CLOSE_PROMO_EDIT = 'CLOSE_PROMO_EDIT';

export const ON_DELETE_PROMO = 'ON_DELETE_PROMO';
export const AFT_DELETE_PROMO = 'AFT_DELETE_PROMO';

export const ON_SAVE_PROMO = 'ON_SAVE_PROMO';
export const AFT_SAVE_PROMO = 'AFT_DELETE_PROMO';

export const SELECT_PROMO = 'SELECT_PROMO';

export const PROMO_MODIFIED = 'PROMO_MODIFIED';

export const ON_SAVE_PROMO_SMALL_IMG = 'ON_SAVE_PROMO_SMALL_IMG';
export const AFT_SAVE_PROMO_SMALL_IMG = 'AFT_SAVE_PROMO_SMALL_IMG';

export const ON_DELETE_PROMO_SMALL_IMG = 'ON_DELETE_PROMO_SMALL_IMG';
export const AFT_DELETE_PROMO_SMALL_IMG = 'AFT_DELETE_PROMO_SMALL_IMG';

export const ON_ADD_PROMO_PRODUCT = "ON_ADD_PROMO_PRODUCT";
export const AFT_ADD_PROMO_PRODUCT = "AFT_ADD_PROMO_PRODUCT";

export const ON_DELETE_PROMO_PRODUCT = "ON_DELETE_PROMO_PRODUCT";
export const AFT_DELETE_PROMO_PRODUCT = "AFT_DELETE_PROMO_PRODUCT";