import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import UManagerList from '../UManagerList/UManagerList.js';
import UManagerEditor from '../UManagerEditor/UManagerEditor.js';

import {
    requestUsers,
    resetUManagerError
} from '../../Redux/Actions/uManagerActions';
import { connect } from 'react-redux';

import './UManagerPage.less';

class UManagerPage extends React.Component{

    componentWillMount(){
        this.props.requestUsers();

        this.onCloseError = this.onCloseError.bind(this);
    }

    onCloseError(e){
        this.props.resetUManagerError();
    }

    showErr(){
        if (this.props.error == null)
            return <Fragment/>
        
        return <Alert
            type='error'
            message="Ошибка при выполнении операции с пользователями"
            description={this.props.error}
            closable
            onClose={this.onCloseError}
        />
    }

    render(){
        return <div class="uManagerPage">
            <Space direction="vertical">
                { this.showErr() }
                <UManagerList/>
                <UManagerEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.uManager.error
});

export default connect(
    mapStateToProps,
    { requestUsers, resetUManagerError }
)(UManagerPage);