import {
    REQUEST_ROOT_PGROUP,
    RECEIVE_ROOT_PGROUP,
    CREATE_PGROUP,
    MODIFY_PGROUP,
    CLOSE_PGROUP_EDIT,
    ON_DELETE_PGROUP,
    AFT_DELETE_PRGOUP,
    ON_SAVE_PGROUP,
    AFT_SAVE_PGROUP,
    SELECT_PGROUP,
    PGROUP_MODIFIED,
    PGROUP_SET_PARENT_ID,
    ON_PGROUP_SET_SMALL_IMG,
    AFT_PGROUP_SET_SMALL_IMG,
    ON_PGROUP_DEL_SMALL_IMG,
    AFT_PGROUP_DEL_SMALL_IMG
} from '../ActionTypes/productGroupActionTypes';

const initialState = {
    error: null,
    isFetching: false,
    rootGroup: null,
    selectedGroupId: -1,
    productGroupEditor: {
        mode: null,
        modified: null,
        parentGroupId: null,
        productGroup: null
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_ROOT_PGROUP: return {
            ...state,
            error: null,
            isFetching: true,
        };
        case RECEIVE_ROOT_PGROUP: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            rootGroup: action.payload.rootGroup
        };
        case CREATE_PGROUP: return {
            ...state,
            error: null,
            isFetching: false,
            productGroupEditor: {
                ...state.productGroupEditor,
                mode: 'create',
                parentGroupId: action.payload.parentGroupId,
                productGroup: {
                    parentGroup: action.payload.parentGroup
                }
            }
        };
        case MODIFY_PGROUP: return {
            ...state,
            error: null,
            isFetching: false,
            productGroupEditor: {
                ...state.productGroupEditor,
                mode: 'edit',
                parentGroupId: action.payload.parentGroupId,
                productGroup: action.payload.productGroup
            }
        };
        case ON_DELETE_PGROUP: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_DELETE_PRGOUP: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case ON_SAVE_PGROUP: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_SAVE_PGROUP: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            productGroupEditor: {
                ...state.productGroupEditor,
                mode: 'edit',
                productGroup: {
                    ...state.productGroupEditor.productGroup,
                    id: (action.payload.mode == 'create') ? action.payload.productGroupId : state.productGroupEditor.productGroup.id
                }
            }
        };
        case CLOSE_PGROUP_EDIT: return {
            ...state,
            productGroupEditor: initialState.productGroupEditor
        };
        case SELECT_PGROUP: return {
            ...state,
            selectedGroupId: action.payload.selectedGroupId
        };
        case PGROUP_MODIFIED: return {
            ...state,
            productGroupEditor: {
                ...state.productGroupEditor,
                modified: action.payload.modified
            }
        };
        case PGROUP_SET_PARENT_ID: return {
            ...state,
            productGroupEditor: {
                ...state.productGroupEditor,
                parentGroupId: action.payload.parentGroupId
            }
        }
        case ON_PGROUP_SET_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        }
        case ON_PGROUP_DEL_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_PGROUP_SET_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        }
        case AFT_PGROUP_DEL_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        }
        default: return state;
    }
}