import React from 'react';
import { Input, Space } from 'antd';

import './YaMap.less';

export default class YaMap extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            yaMap: (props.yaMap == null) ? "" : props.yaMap
        }

        this.onYaMapChange = this.onYaMapChange.bind(this);
    }

    componentWillReceiveProps(newProps){
        if (newProps === this.props)
            return;

        this.setState({
            ...this.state,
            yaMap: (newProps.yaMap == null) ? "" : newProps.yaMap
        });
    }

    onYaMapChange(e){
        this.props.onModify(e.target.value);
    }

    render(){
        return <div class="yaMap">
            <Space direction="vertical">
                <span>Введите ссылку на яндекс-карту в поле ниже. В случае успеха карта отобразится ниже</span>
                <Input value={this.state.yaMap} onChange={this.onYaMapChange} disabled={this.props.disabled}/>
                <iframe class="mapContainer" src={ this.state.yaMap } frameBorder="0"/>
            </Space>
        </div>
    }
}