import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs, InputNumber, Empty, Space } from 'antd';

import {
    onDeleteCategoryGroup,
    onDeleteCategory,
    onSaveCategoryGroup,
    onSaveCategory,
    closeCategoryGroupEdit,
    closeCategoryEdit,
    categoryItemModified
} from '../../Redux/Actions/categoryActions';
import {  
    onCategoryGroupSelection
} from '../../Redux/Actions/categoryGroupSelectorActions';
import { connect } from 'react-redux';

import './CategoryEditor.less';
import { isEmpty } from 'lodash';

const { TabPane } = Tabs;

class CategoryEditor extends React.Component{

    aftModify = false;

    categoryGroupInitialState = {
        id: 0,
        name: "",
        order: 0
    }

    categoryInitialState = {
        id: 0,
        name: "",
        order: 0,
        group: {}
    }

    constructor(props){
        super(props);

        switch (props.editor.mode){
            case "createGroup": case "editGroup":
                this.state = { ...this.categoryGroupInitialState, ...props.editor.item };
                break;
            case "createCategory": case "editCategory":
                this.state = { ...this.categoryInitialState, ...props.editor.item };
                break;
        }

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);

        this.onModifyName = this.onModifyName.bind(this);
        this.onModifyOrder = this.onModifyOrder.bind(this);
        this.onModifyGroup = this.onModifyGroup.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        switch (newProps.editor.mode){
            case "createGroup": case "editGroup":
                if (this.state != null && this.state.group != null) delete this.state.group;
                this.setState({ ...this.categoryGroupInitialState, ...newProps.editor.item });
                break;
            case "createCategory": case "editCategory":
                this.setState({ ...this.categoryInitialState, ...newProps.editor.item });
                break;
        }
    }

    onSave(e){
        switch (this.props.editor.mode){
            case "createGroup": case "editGroup":
                this.props.onSaveCategoryGroup(this.state, this.props.editor.mode);
                break;
            case "createCategory": case "editCategory":
                this.props.onSaveCategory(this.state, this.props.editor.mode);
                break;
        }
    }

    onDelete(e){
        var itemName = (this.props.editor.mode == "createGroup" || this.props.editor.mode == "editGroup")
            ? "группу категорий товаров"
            : "категорию товаров";
        if (!window.confirm(`Вы действительно хотите удалить выбранную ${itemName}?`))
            return;

        switch (this.props.editor.mode){
            case "createGroup": case "editGroup":
                this.props.onDeleteCategoryGroup(this.state);
                break;
            case "createCategory": case "editCategory":
                this.props.onDeleteCategory(this.state);
                break;
        }
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        switch (this.props.editor.mode){
            case "createGroup": case "editGroup":
                this.props.closeCategoryGroupEdit();
                break;
            case "createCategory": case "editCategory":
                this.props.closeCategoryEdit();
                break;
        }
    }

    onModifyName(e){
        this.aftModify = true;
        this.setState({ ...this.state, name: e.target.value });
        this.props.categoryItemModified(true);
    }

    onModifyOrder(e){
        this.aftModify = true;
        this.setState({ ...this.state, order: (e != "") ? e : 0 });
        this.props.categoryItemModified(true);
    }

    onModifyGroup(e){
        this.aftModify = true;
        this.setState({ ...this.state, group: e });
        this.props.categoryItemModified(true);
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'createGroup':
                title_text = 'Создание группы категорий товаров';
                break;
            case 'editGroup':
                title_text = 'Изменение группы категорий товаров';
                break;
            case 'createCategory':
                title_text = 'Создание категории товаров';
                break;
            case 'editCategory':
                title_text = 'Изменение категории товаров';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>
    }

    getDeleteButton(){
        var itemName = (this.props.editor.mode == "createGroup" || this.props.editor.mode == "editGroup")
            ? "группу категорий товаров"
            : "категорию товаров";

        switch (this.props.editor.mode) {
            case null: case 'createCategoryGroup': case 'createCategory':
                return <Fragment/>;
            default:
                return <Button
                    type="danger"
                    onClick={this.onDelete}
                >Удалить {itemName}</Button>
        }
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span>Название:</span>
                    <Input onChange={this.onModifyName} value={ this.state.name } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Порядок:</span>
                    <InputNumber onChange={this.onModifyOrder} value={ this.state.order } disabled={!this.hasEditorRights()}/>
                </div>
            </div>
        </TabPane>
    }

    renderGroup(){
        if (this.state.group == null || isEmpty(this.state.group))
            return <Empty description="Группа категорий товаров не выбрана"/>

        return <Fragment>
            <span>Данная категория входит в группу <b>{this.state.group.name}</b></span>
        </Fragment>
    }

    renderGroupSelect(){
        return <TabPane tab="Группа категорий" key="2">
            <Space direction="vertical">
                {this.renderGroup()}
                {
                    this.hasEditorRights() &&
                    <Button 
                        type="primary"
                        onClick={ e => this.props.onCategoryGroupSelection(this.onModifyGroup) }
                    >
                        Сменить группу категорий
                    </Button>
                }
            </Space>
        </TabPane>
    }

    render(){
        if (this.props.editor.mode == null){
            return <Fragment/>
        }

        return <Spin typ="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="categoryEditor" title={this.getCardTitle()}>
                {
                    (this.state.group != null)
                        ? <Tabs defaultActiveKey="1">{ this.renderMainTab() }{ this.renderGroupSelect() }</Tabs>
                        : <Tabs defaultActiveKey="1">{ this.renderMainTab() }</Tabs>
                }
                {
                    this.hasEditorRights() &&
                    <div class="btnLine">
                        <Space direction="horizontal">
                            <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                            {this.getDeleteButton()}
                        </Space>
                    </div>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.category.editor,
    isFetching: state.category.isFetching
});

export default connect(
    mapStateToProps,
    { onDeleteCategoryGroup, onDeleteCategory, onSaveCategoryGroup, onSaveCategory, closeCategoryGroupEdit, closeCategoryEdit, categoryItemModified, onCategoryGroupSelection }
)(CategoryEditor);