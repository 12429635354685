import React, { Fragment } from 'react';
import { Card, Space, Input, Button } from 'antd';

import {
    onChangePassword
} from '../../Redux/Actions/userActions';
import { connect } from 'react-redux';

import './UserPage.less';

class UserPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            passBlock: {
                cPassword: "",
                nPassword: "",
                nPasswordCopy: ""
            }
        }

        this.onChangeNPassword = this.onChangeNPassword.bind(this);
        this.onChangeNPassCopy = this.onChangeNPassCopy.bind(this);
        this.onChangeCPassword = this.onChangeCPassword.bind(this);
        this.onClickPassChange = this.onClickPassChange.bind(this);
    }

    onChangeNPassword(e){
        this.setState({
            ...this.state,
            passBlock: {
                ...this.state.passBlock,
                nPassword: e.target.value
            }
        });
    }

    onChangeNPassCopy(e){
        this.setState({
            ...this.state,
            passBlock: {
                ...this.state.passBlock,
                nPasswordCopy: e.target.value
            }
        });
    }

    onChangeCPassword(e){
        this.setState({
            ...this.state,
            passBlock: {
                ...this.state.passBlock,
                cPassword: e.target.value
            }
        });
    }

    onClickPassChange(e){
        if (this.state.passBlock.cPassword == "" || this.state.passBlock.nPassword == ""){
            window.alert("Для смены пароля необходимо указать текущий и новый пароль!");
            return;
        }

        if (this.state.passBlock.nPassword != this.state.passBlock.nPasswordCopy){
            window.alert("Пароли не совпадают");
            return;
        }

        this.props.onChangePassword({
            ...this.props.token,
            CPassword: this.state.passBlock.cPassword,
            NPassword: this.state.passBlock.nPassword
        })

        this.setState({
            ...this.state,
            passBlock: {
                ...this.state.passBlock,
                cPassword: "",
                nPassword: "",
                nPasswordCopy: ""
            }
        })
    }

    renderUserInfo(){
        if (this.props.token == null)
            return <Fragment/>

        return <Card title="Данные пользователя">
            <p><strong>Имя пользователя:</strong> { (this.props.token.userName) ? this.props.token.userName : "ошибка" }</p>
            <p><strong>Роль пользователя:</strong> { (this.props.token.userRole) ? this.props.token.userRole : "ошибка" }</p>
        </Card>
    }

    renderPassBlock(){
        return <Card title="Изменение пароля">
            <Space direction="vertical">
                <div class="inpLine">
                    <span>Текущий пароль:</span>
                    <Input type="password" value={this.state.passBlock.cPassword} onChange={this.onChangeCPassword}/>
                </div>
                <div class="inpLine">
                    <span>Новый пароль:</span>
                    <Input type="password" value={this.state.passBlock.nPassword} onChange={this.onChangeNPassword}/>
                </div>
                <div class="inpLine">
                    <span>Новый пароль (ещё раз):</span>
                    <Input type="password" value={this.state.passBlock.nPasswordCopy} onChange={this.onChangeNPassCopy}/>
                </div>
                <Button type="primary" onClick={this.onClickPassChange} block>Сменить пароль</Button>
            </Space>
        </Card>
    }

    render(){
        return <div class="userPage">
            <Space direction="vertical">
                { this.renderUserInfo() }
                { this.renderPassBlock() }
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    token: state.user.token
});

export default connect(
    mapStateToProps,
    { onChangePassword }
)(UserPage);