import { 
    REQUEST_CITIES, 
    RECEIVE_CITIES, 
    CREATE_CITY,
    MODIFY_CITY,
    ON_DELETE_CITY,
    AFT_DELETE_CITY,
    ON_SAVE_CITY,
    AFT_SAVE_CITY,
    CLOSE_CITY_EDIT,
    SELECT_CITY,
    CITY_MODIFIED
} from "../ActionTypes/cityActionTypes";
import { logout } from '../Actions/userActions';
import { closeShopEdit, requestShops, modifyShop } from '../../Redux/Actions/shopActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const cityModified = (modified) => ({
    type: CITY_MODIFIED,
    payload: { modified: modified }
})

export const selectCity = city_id => dispatch => {
    dispatch({ type: SELECT_CITY, payload: city_id });
};

export const receiveCities = (cities, error) => ({
    type: RECEIVE_CITIES,
    payload: {
        cities: cities,
        error: error
    }
});

export const requestCities = () => dispatch => {

    dispatch({ type: REQUEST_CITIES, payload: null });

    axios.get( new URL("city", API_URL).toString() )
    .then((response) => {
        dispatch(receiveCities(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveCities(null, error));
        }
    });
};

export const createCity = () => dispatch => {
    dispatch(selectCity(-1));
    dispatch({type: CREATE_CITY, payload: null });
    dispatch(cityModified(false));
}

export const modifyCity = city => dispatch => {
    dispatch(selectCity(city.id));
    dispatch({ type: MODIFY_CITY, payload: city });
    dispatch(cityModified(false));
}

export const closeCityEdit = () => dispatch => {
    dispatch({ type: CLOSE_CITY_EDIT, payload: null });
    dispatch(selectCity(-1));
    dispatch(cityModified(false));
}

export const aftDeleteCity = error => dispatch => {

    dispatch({ type: AFT_DELETE_CITY, payload: error });
    if (error){
        dispatch(closeCityEdit());
    }
    else{
        dispatch(requestCities());
    }

    dispatch(selectCity(-1));
    dispatch(closeCityEdit());
};

export const onDeleteCity = city => (dispatch, getState) => {

    dispatch({ type: ON_DELETE_CITY, payload: null });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "city/" + city.id.toString(),
        API_URL
    );

    axios.delete( 
        url.toString(), 
        { headers: headers } 
    )
    .then((response) => {
        dispatch(aftDeleteCity(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteCity(error));
        }
    });
}

export const aftSaveCity = (cityId, city, error, mode) => dispatch => {
    dispatch({
        type: AFT_SAVE_CITY,
        payload: {
            cityId: cityId,
            error: error,
            city: city,
            mode: mode
        }
    });

    dispatch(requestCities());
    
    var newCity = city;
    if (cityId != -1)
        newCity.id = cityId
    dispatch(modifyCity(city));
}

export const onSaveCity = (city, mode) => (dispatch, getState) => {

    dispatch({ type: ON_SAVE_CITY, payload: null });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var json = {
        Id: (city.id == null) ? 0 : city.id,
        Name: city.name,
        Region: city.region,
        sypexGeoId: city.sypexGeoId
    }

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                new URL("city", API_URL).toString(),
                json,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                new URL("city", API_URL).toString(),
                json,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSaveCity(-1, city, null, mode));
        else
            dispatch(aftSaveCity(parseInt(response.data), city, null, mode));
        dispatch(requestCities());
    }).catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveCity(null, city, error, mode));
        }
    });
}