import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import BillList from '../BillList/BillList.js';
import BillEditor from '../BillEditor/BillEditor.js';

import {
    requestBills,
    closeBillEdit
} from '../../Redux/Actions/billActions';
import { connect } from 'react-redux';

import './BillPage.less';

class BillPage extends React.Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.closeBillEdit();
        this.props.requestBills();
    }

    showErr(){
        if (this.props.error == null)
            return <Fragment/>

        return <Alert
            type="error"
            message="Внутренняя ошибка"
            description="Ошибка при выполнении операции с заказом"
        />
    }

    render(){
        return <div class="billPage">
            <Space direction="vertical">
                {this.showErr()}
                <BillList/>
                <BillEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.bill.error
});

export default connect(
    mapStateToProps,
    { requestBills, closeBillEdit }
)(BillPage);