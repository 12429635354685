import React from 'react';
import { Spin, Tree, Empty, Button, Modal } from 'antd';

import {
    onCategorySelection,
    aftCategorySelection,
    onCategorySelectionSelect,
    onCategorySelectionClose
} from '../../Redux/Actions/categorySelectorActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

class CategorySelector extends React.Component{
    constructor(props){
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    onCancel(e){
        this.props.onCategorySelectionClose();
    }

    onOk(e){
        this.props.aftCategorySelection();
    }

    onCheck(checkedKeys, info){
        this.props.onCategorySelectionSelect(info.node, info.node.id);
    }

    renderTree(){
        if (this.props.categoryGroups == null || this.props.categoryGroups.length == 0)
            return <Empty description="Не удалось найти ни одной категории"/>

        return <Scroll maxHeight="500" minHeight="100">
            <Spin tip="Загрузка..." spinning={this.props.isFetching}>
                <Tree
                    checkedKeys={ (this.props.selectedId == -1) ? [] : [ this.props.selectedId.toString() ]}
                    onCheck={this.onCheck}
                    treeData={this.props.categoryGroups}
                    checkable={true}
                    showLine={true}
                    selectable={false}
                    checkStrictly={true}
                    defaultExpandAll={true}
                />
            </Spin>
        </Scroll>
    }

    render(){
        return <Modal
            visible={this.props.visible}
            title="Выбор категории"
            confirmLoading={this.props.isFetching}
            className="categorySelector"
            footer={[
                <Button key="submit" type="primary" onClick={this.onOk} disabled={ this.props.selectedId == -1} >
                    Выбрать
                </Button>
            ]}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            {this.renderTree()}
        </Modal>
    }
}


const mapStateToProps = (state) => ({
    visible: state.categorySelector.visible,
    error: state.categorySelector.error,
    isFetching: state.categorySelector.isFetching,
    categoryGroups: state.categorySelector.categoryGroups,
    selectedId: state.categorySelector.selectedId
});

export default connect (
    mapStateToProps,
    { onCategorySelection, aftCategorySelection, onCategorySelectionSelect, onCategorySelectionClose }
)(CategorySelector);