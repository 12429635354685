import React, { Fragment } from 'react';
import { Input, Button, Empty } from 'antd';

import './Emails.less';

export default class Emails extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            emails: (props.emails == null) ? [] : props.emails
        }
    }

    componentWillReceiveProps(newProps){
        if (newProps === this.props)
            return;

        this.setState({
            ...this.state,
            emails: (newProps.emails == null) ? [] : newProps.emails
        });
    }

    onItemChange(index, value){
        var eM = this.state.emails;
        eM[index] = value;
        this.setState({
            ...this.state,
            emails: eM
        });
        this.props.onModify(this.state.emails);
    }

    onItemAdd(){
        var eM = this.state.emails;
        eM.push("");
        this.setState({
            ...this.state,
            emails: eM
        });
        this.props.onModify(this.state.emails);
    }

    onItemDelete(index){
        var eM = this.state.emails;
        eM.splice(index, 1);
        this.setState({
            ...this.state,
            emails: eM
        });
        this.props.onModify(this.state.emails);
    }

    renderList(){
        if (typeof(this.state.emails) == "undefined" || this.state.emails.length == 0)
            return <Empty description="Список пуст"/>;

        return this.state.emails.map((item, index) => {
            return <div class="row">
                <Input value={item} onChange={ e => this.onItemChange(index, e.target.value) }/>
                {
                    !this.props.disabled &&
                    <Button type='danger' onClick={ e => this.onItemDelete(index) }>Удалить</Button>
                }
            </div>
        });
    }

    render(){
        return <div class='emails'>
            { this.renderList() }
            {
                !this.props.disabled &&
                <Button type='primary' className="addBtn" onClick={ e => this.onItemAdd() }>Добавить</Button>
            }
        </div>
    }
}