import React, { Fragment } from 'react';
import ShopList from '../ShopList/ShopList.js';
import ShopEditor from '../ShopEditor/ShopEditor.js';

import { requestShops } from '../../Redux/Actions/shopActions';
import { Alert, Space } from 'antd';

import { connect } from 'react-redux';

import './ShopPage.less';

class ShopPage extends React.Component{

    componentWillMount(){
        this.props.requestShops();
    }

    showErr(){
        if (this.props.error == null)
            return <Fragment/>
        
        return <Alert
            type='error'
            message='Внутренняя ошибка'
            description="Ошибка при выполнении операции с магазинами"
        />
    }

    render(){
        return <div class="shopPage">
            <Space direction="vertical">
                { this.showErr() }
                <ShopList/>
                <ShopEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.city.error,
    shopEditor: state.shop.shopEditor
})

export default connect(
    mapStateToProps,
    { requestShops }
)(ShopPage);