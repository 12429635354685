import React, { Fragment } from 'react';
import { Input, Button, Empty } from 'antd';

import './TimeTable.less';

export default class TimeTable extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            timeTable: (props.timeTable == null) ? [] : props.timeTable
        }
    }

    componentWillReceiveProps(newProps){
        if (newProps === this.props)
            return;

        this.setState({
            ...this.state,
            timeTable: (newProps.timeTable == null) ? [] : newProps.timeTable
        });
    }

    onItemChange(index, value){
        var tTable = this.state.timeTable;
        tTable[index] = value;
        this.setState({
            ...this.state,
            timeTable: tTable
        });
        this.props.onModify(this.state.timeTable);
    }

    onItemAdd(){
        var tTable = this.state.timeTable;
        tTable.push("");
        this.setState({
            ...this.state,
            timeTable: tTable
        });
        this.props.onModify(this.state.timeTable);
    }

    onItemDelete(index){
        var tTable = this.state.timeTable;
        tTable.splice(index, 1);
        this.setState({
            ...this.state,
            timeTable: tTable
        });
        this.props.onModify(this.state.timeTable);
    }

    renderList(){
        if (this.state.timeTable.length == 0)
            return <Empty description="Список пуст"/>;

        return <Fragment>
            {
                this.state.timeTable.map((item, index) => {
                    return <div class="row">
                        <Input value={item} onChange={ e => this.onItemChange(index, e.target.value) }/>
                        {
                            !this.props.disabled &&
                            <Button type='danger' onClick={ e => this.onItemDelete(index) }>Удалить</Button>
                        }
                    </div>
                })
            }
        </Fragment>
    }

    render(){
        return <div class="timeTable">
            { this.renderList() }
            {
                !this.props.disabled &&
                <Button type='primary' className="addBtn" onClick={ e => this.onItemAdd() }>Добавить</Button>
            }
        </div>
    }
}