import {
    REQUEST_CATEGORY_GROUPS,
    RECEIVE_CATEGORY_GROUPS,
    CREATE_CATEGORY_GROUP,
    MODIFY_CATEGORY_GROUP,
    CLOSE_CATEGORY_GROUP_EDIT,
    ON_SAVE_CATEGORY_GROUP,
    AFT_SAVE_CATEGORY_GROUP,
    ON_DELETE_CATEGORY_GROUP,
    AFT_DELETE_CATEGORY_GROUP,
    CREATE_CATEGORY,
    MODIFY_CATEGORY,
    CLOSE_CATEGORY_EDIT,
    ON_SAVE_CATEGORY,
    AFT_SAVE_CATEGORY,
    ON_DELETE_CATEGORY,
    AFT_DELETE_CATEGORY,
    SELECT_CATEGORY_ITEM,
    CATEGORY_ITEM_MODIFIED
} from '../ActionTypes/categoryActionTypes';


const initialState = {
    error: null,
    isFetching: false,
    categoryGroupList: null,
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: {}
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_CATEGORY_GROUPS: return {
            ...state,
            error: null,
            isFetching: true
        };
        case RECEIVE_CATEGORY_GROUPS: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            categoryGroupList: action.payload.categoryGroupList
        };
        case CREATE_CATEGORY_GROUP: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'createGroup',
                item: {}
            }
        };
        case MODIFY_CATEGORY_GROUP: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'editGroup',
                item: action.payload.item
            }
        };
        case CLOSE_CATEGORY_GROUP_EDIT: return {
            ...state,
            editor: initialState.editor
        };
        case ON_SAVE_CATEGORY_GROUP: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_SAVE_CATEGORY_GROUP: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case ON_DELETE_CATEGORY_GROUP: return {
            ...state,
            error: null,
            isFetching: true,
        };
        case AFT_DELETE_CATEGORY_GROUP: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case CREATE_CATEGORY: return {
            ...state,
            error: null,
            isFetching: false,
            editor: {
                ...state.editor,
                mode: 'createCategory',
                item: {
                    group: action.payload.group
                }
            }
        };
        case MODIFY_CATEGORY: return {
            ...state,
            error: null,
            isFetching: false,
            editor: {
                ...state.editor,
                mode: 'editCategory',
                item: action.payload.item
            }
        };
        case CLOSE_CATEGORY_EDIT: return {
            ...state,
            editor: initialState.editor
        };
        case ON_SAVE_CATEGORY: return {
            ...state,
            error: null,
            isFetching: true,
        };
        case AFT_SAVE_CATEGORY: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case ON_DELETE_CATEGORY: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_DELETE_CATEGORY: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case SELECT_CATEGORY_ITEM: return {
            ...state,
            selectedId: action.payload.id
        };
        case CATEGORY_ITEM_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        }
        default: return state;
    }
}