import {
    SET_PRODUCT_FILTER,
    SET_PRODUCT_LIST_PAGING,
    REQUEST_PRODUCTS,
    RECEIVE_PRODUCTS,
    PAGINATE_PRODUCTS,
    CREATE_PRODUCT,
    MODIFY_PRODUCT,
    CLOSE_PRODUCT_EDIT,
    ON_DELETE_PRODUCT,
    AFT_DELETE_PRODUCT,
    ON_SAVE_PRODUCT,
    AFT_SAVE_PRODUCT,
    SELECT_PRODUCT,
    PRODUCT_MODIFIED,
    ON_PRODUCT_ADD_CATEGORY,
    AFT_PRODUCT_ADD_CATEGORY,
    ON_PRODUCT_DEL_CATEGORY,
    AFT_PRODUCT_DEL_CATEGORY,
    ON_PRODUCT_ADD_SMALL_IMG,
    AFT_PRODUCT_ADD_SMALL_IMG,
    ON_PRODUCT_DEL_SMALL_IMG,
    AFT_PRODUCT_DEL_SMALL_IMG,
    ON_PRODUCT_ADD_GALLERY,
    AFT_PRODUCT_ADD_GALLERY,
    ON_PRODUCT_DEL_GALLERY,
    AFT_PRODUCT_DEL_GELLERY
} from '../ActionTypes/productActionTypes';

const initialState = {
    filter: {
        article: null,
        name: null,
        onlyHidden: false,
    },
    error: null,
    isFetching: false,
    productList: null,
    productListPaging: {
        selectedPage: 0,
        totalPages: 0
    },
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: {}
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case SET_PRODUCT_FILTER: return {
            ...state,
            filter: {
                name: action.payload.name,
                article: action.payload.article,
                onlyHidden: action.payload.onlyHidden
            }
        };
        case SET_PRODUCT_LIST_PAGING: return {
            ...state,
            productListPaging: {
                selectedPage: action.payload.selectedPage,
                totalPages: action.payload.totalPages
            }
        }
        case REQUEST_PRODUCTS: return {
            ...state,
            error: null,
            isFetching: true
        };
        case RECEIVE_PRODUCTS: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            productList: action.payload.productList
        };
        case PAGINATE_PRODUCTS: return {
            ...state,
            productListPaging : {
                ...state.productListPaging,
                selectedPage: action.payload.selectedPage
            }
        }
        case CREATE_PRODUCT: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'create',
                item: {}
            }
        };
        case MODIFY_PRODUCT: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'edit',
                item: action.payload.item
            }
        };
        case CLOSE_PRODUCT_EDIT: return {
            ...state,
            editor: initialState.editor
        };
        case ON_DELETE_PRODUCT: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_DELETE_PRODUCT: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        case ON_SAVE_PRODUCT: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_SAVE_PRODUCT: return {
            ...state,
            error: null,
            isFetching: false
        };
        case SELECT_PRODUCT: return {
            ...state,
            selectedId: action.payload.selectedId
        }
        case PRODUCT_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        };
        case ON_PRODUCT_ADD_CATEGORY: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_ADD_CATEGORY: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    categories: (action.payload.newCategories == null) 
                        ? state.editor.item.categories
                        : action.payload.newCategories
                }
            }
        };
        case ON_PRODUCT_DEL_CATEGORY: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_DEL_CATEGORY: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    categories: (action.payload.newCategories == null) 
                        ? state.editor.item.categories
                        : action.payload.newCategories
                }
            }
        };
        case ON_PRODUCT_ADD_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_ADD_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    smallImg: (action.payload.newImg == null)
                        ? state.editor.item.smallImg
                        : action.payload.newImg
                }
            }
        };
        case ON_PRODUCT_DEL_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_DEL_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    smallImg: (action.payload.newImg == null)
                        ? state.editor.item.categories
                        : action.payload.newImg
                }
            }
        };
        case ON_PRODUCT_ADD_GALLERY: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_ADD_GALLERY: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    gallery: (action.payload.newImg == null)
                        ? state.editor.item.gallery
                        : action.payload.newImg
                }
            }
        };
        case ON_PRODUCT_DEL_GALLERY: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_PRODUCT_DEL_GELLERY: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    gallery: (action.payload.newImg == null)
                        ? state.editor.item.gallery
                        : action.payload.newImg
                }
            }
        }
        default: return state;
    }
}