import { 
    LOGIN,
    LOGOUT,
    RECEIVE_TOKEN,
    RECEIVE_ERROR,
    ON_CHANGE_PASSWORD,
    AFT_CHANGE_PASSWORD
} from '../ActionTypes/userActionTypes';
import axios from 'axios';
import { API_URL } from "../../Variables";


export const receiveToken = token => ({
    type: RECEIVE_TOKEN,
    payload: token
});

export const receiveError = error => ({
    type: RECEIVE_ERROR,
    payload: error
});

export const login = content => dispatch => {

    dispatch({ type: LOGIN, payload: null });

    var logPass = {
        userName: content.username,
        cPassword: content.password
    };

    var url = new URL("auth/login", API_URL).toString();

    axios.post(
        new URL("auth/login", API_URL).toString(),
        logPass,
        { headers: { 'Content-Type': 'application/json' } }
    )
    .then((response) =>{
        dispatch(receiveToken(response.data));
    })
    .catch((error) => {
        if (error.request || error.response)
            dispatch(receiveError(error));
    });
}

export const logout = () => ({ 
    type: LOGOUT,
    payload: null
});

export const aftChangePassword = (error) => (dispatch) => {
    dispatch({
        type: AFT_CHANGE_PASSWORD,
        payload: { error: error }
    });

    if (error == null){
        dispatch(logout());
    }
}

export const onChangePassword = (user) => (dispatch, getState) => {
    dispatch({
        type: ON_CHANGE_PASSWORD,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("auth/change", API_URL);

    axios.put(
        url.toString(),
        user,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftChangePassword(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftChangePassword(error));
        }
    })
}