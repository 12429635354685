import React, { Fragment } from 'react';
import { Input, Button, Space } from 'antd';

import { API_URL } from "../../../../Variables";

import './SmallImgTab.less';

export default class SmallImgTab extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            file: null,
            filePath: null
        };

        this.onFileChange = this.onFileChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    renderImage(){
        if (!this.props.imageList.length)
            return <span class="noImage">Изображение отсутствует</span>

        var url = new URL(`file/${ this.props.imageList[0].Id }`, API_URL).toString();

        return <img class="image" src={url} alt="Ошибка загрузки"/>
    }

    onFileChange(e){
        this.setState({
            file: e.target.files[0],
            filePath: e.target.value
        });
    }

    onSave(e){
        this.props.onSave(this.state.file);
        this.setState({
            file: null,
            filePath: ""
        });
    }

    onDelete(e){
        this.props.onDelete();
        this.setState({
            file: null,
            filePath: ""
        });
    }

    renderFileSelector(){
        if (this.props.disabled == true)
            return <Fragment/>

        return <div class="fileSelector">
            <Space>
                <Input type='file' accept="image/*" size="small" onChange={this.onFileChange} value={this.filePath} />
                <Button type="primary" size="small" onClick={this.onSave} disabled={this.state.file == null}>
                    Сохранить изображение
                </Button>
                <Button type="primary" size="small" danger onClick={this.onDelete} disabled={!this.props.imageList.length}>
                    Удалить изображение
                </Button>
            </Space>
        </div>
    }

    render(){
        return <div class="smallImgTab">
            { this.renderImage() }
            { this.renderFileSelector() }
        </div>
    }
}