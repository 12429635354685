import React, { Fragment } from 'react';
import { Button, Card, Spin, Tabs, Empty, Checkbox } from 'antd';
import { Input, InputNumber } from 'antd';

import {
    onDeletePgroup,
    onSavePgroup,
    closePgroupEdit,
    pgroupModified,
    pgroupSetParentId,
    onPgroupSetSmallImg,
    onPGroupDelSmallImg
} from '../../Redux/Actions/productGroupActions';
import { onProductGroupSelection } from '../../Redux/Actions/productGroupSelectorActions';

import AboutTab from './Subsections/AboutTab/AboutTab.js';
import SmallImgTab from './Subsections/SmallImgTab/SmallImgTab.js';

import './ProductGroupEditor.less';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

const validProps = [ 'id', 'name', 'smallImage', 'mainDescription', 'lvlOrder', 'parentGroup', 'children', 'onMainPage' ];

function removeInvalidProps(obj){
    if (!obj) return obj;
    var newObj = obj;
    Object.keys(newObj).forEach((key) => validProps.includes(key) || delete newObj[key]);
    return newObj;
}


class ProductGroupEditor extends React.Component{

    aftModify = false;

    initialState = {
        id: 0,
        name: "",
        onMainPage: false,
        smallImage: [],
        mainDescription: "",
        lvlOrder: 0,
        parentGroup: {},
        children: []
    };

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...removeInvalidProps(props.editor.productGroup)
        }

        this.onSaveProductGroup = this.onSaveProductGroup.bind(this);
        this.onDeleteProductGroup = this.onDeleteProductGroup.bind(this);
        this.onCloseEditor = this.onCloseEditor.bind(this);

        this.onModifyName = this.onModifyName.bind(this);
        this.onModifyLvlOrder = this.onModifyLvlOrder.bind(this);
        this.onModifyParentGroup = this.onModifyParentGroup.bind(this);
        this.onModifyMainDescription = this.onModifyMainDescription.bind(this);

        this.onModifyOnMainPage = this.onModifyOnMainPage.bind(this);

        this.onAddSmallImg = this.onAddSmallImg.bind(this);
        this.onDelSmallImg = this.onDelSmallImg.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }
      
        return JSON.stringify(obj) === JSON.stringify({});
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        var newState = {
            ...this.initialState,
            ...removeInvalidProps(newProps.editor.productGroup)
        }

        this.setState(newState);
    }

    onSaveProductGroup(e){
        if(this.state.name == "Root"){
            window.alert('Название группы "Root" является зарезервированным и не может использоваться в других группах товаров!');
            return;
        }

        this.props.onSavePgroup(this.state, this.props.editor.parentGroupId, this.props.editor.mode);
    }

    onDeleteProductGroup(e){
        if (!window.confirm("Вы действительно хотите удалить группу товаров?"))
            return;

        this.props.onDeletePgroup(this.state);
    }

    onCloseEditor(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите закрыть редактор? При закрытии внесённые изменения не будут сохранены!")){
            return;
        }

        this.props.closePgroupEdit();
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание группы товаров';
                break;
            case 'edit':
                title_text = 'Изменение группы товаров';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onCloseEditor}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null:
                return <Fragment></Fragment>;
            case 'create':
                return <Fragment></Fragment>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button" 
                    onClick={this.onDeleteProductGroup}
                >
                    Удалить группу товаров
                </Button>;
        }
    }

    onModifyName(e){
        this.aftModify = true;
        this.setState({ ...this.state, name: e.target.value });
        this.props.pgroupModified(true);
    }

    onModifyLvlOrder(e){
        this.aftModify = true;
        this.setState({ ...this.state, lvlOrder: (e != "") ? e : 0 });
        this.props.pgroupModified(true);
    }

    onModifyOnMainPage(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            onMainPage: e.target.checked
        });
        this.props.pgroupModified(true);
    }

    onModifyParentGroup(e){
        this.aftModify = true;
        this.setState({ ...this.state, parentGroup: e });
        this.props.pgroupSetParentId(e.id);
        this.props.pgroupModified(true);
    }

    onModifyMainDescription(e){
        this.setState({ ...this.state, mainDescription: e });
        if (e != this.state.mainDescription){
            this.aftModify = true;
            this.props.pgroupModified(true);
        }
    }

    onAddSmallImg(e){
        if (   this.props.editor.modified == true
            && !window.confirm("При редактировании изображений уже выполненные изменения не будут сохранены! Продолжить?")){
            return;
        }

        this.props.onPgroupSetSmallImg(this.state.id, e);
    }

    onDelSmallImg(e){
        if (   this.props.editor.modified == true
            && !window.confirm("При редактировании изображений уже выполненные изменения не будут сохранены! Продолжить?")){
            return;
        }
        this.props.onPGroupDelSmallImg(this.state.id);
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="pgroupMainTab">
                <div class="inpLine">
                    <span>Название:</span>
                    <Input onChange={this.onModifyName} value={this.state.name} disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Порядок:</span>
                    <InputNumber onChange={this.onModifyLvlOrder} value={ this.state.lvlOrder } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Главный:</span>
                    <Checkbox onChange={this.onModifyOnMainPage} checked={this.state.onMainPage} disabled={!this.hasEditorRights()}/>
                </div>
            </div>
        </TabPane>
    }

    renderPgroup(){
        if (this.state.parentGroup == null || this.isEmpty(this.state.parentGroup))
            return <Empty description="Данная группа товаров не имеет родителя"/>

        return <Fragment>
            <span>Данная группа товаров входит в группу <b>{this.state.parentGroup.name}</b></span>
            <br/>
        </Fragment>
    }

    renderPgroupSelect(){
        return <TabPane tab="Группа-родитель" key="2">
            { this.renderPgroup() }
            {
                this.hasEditorRights() &&
                <Button
                    type="primary"
                    onClick={ e => this.props.onProductGroupSelection(this.onModifyParentGroup, this.state.id) }
                >Сменить группу-родителя</Button>
            }
        </TabPane>
    }

    renderMainDescriptionTab(){
        return <TabPane tab="Описание группы товаров" key="3">
            <AboutTab
                value={this.state.mainDescription}
                onChange={this.onModifyMainDescription}
                disabled={!this.hasEditorRights()}
                key={ this.state.id }
            />
        </TabPane>
    }

    renderSmallImgTab(){
        if (this.props.editor.mode != 'edit')
            return <Fragment/>

        return <TabPane tab="Иконка группы" key="4">
            <SmallImgTab 
                imageList={this.state.smallImage}
                onSave={this.onAddSmallImg}
                onDelete={this.onDelSmallImg}
                disabled={!this.hasEditorRights()}
            />
        </TabPane>
    }

    render(){
        if (this.props.editor.mode == null){
            return <Fragment/>
        }

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="productGroupEditor" title={this.getCardTitle()}>
                <Tabs defaultActiveKey="1">
                    {this.renderMainTab()}
                    {this.renderPgroupSelect()}
                    {this.renderMainDescriptionTab()}
                    {this.renderSmallImgTab()}
                </Tabs>
                {
                    this.hasEditorRights() &&
                    <div class="btnLine">
                        <Button type="primary" onClick={this.onSaveProductGroup}>Сохранить</Button>
                        { this.getDeleteButton() }
                    </div>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.productGroup.productGroupEditor,
    isFetching: state.productGroup.isFetching
});

export default connect(
    mapStateToProps,
    { onSavePgroup, onDeletePgroup, closePgroupEdit, pgroupModified, pgroupSetParentId, onProductGroupSelection, onPgroupSetSmallImg, onPGroupDelSmallImg }
)(ProductGroupEditor);