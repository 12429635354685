import { combineReducers } from 'redux';
import user from './user';
import city from './city';
import shop from './shop';
import citySelector from './citySelector';
import productGroup from './productGroup';
import productGroupSelector from './productGroupSelector';
import excel from './excel';
import category from './category';
import categoryGroupSelector from './categoryGroupSelector';
import categorySelector from './categorySelector';
import product from './product';
import productSelector from './productSelector';
import promo from './promo';
import bill from './bill';
import phoneCall from './phoneCall';
import uManager from './uManager';

export default combineReducers({
    user,
    city,
    shop,
    citySelector,
    productGroup,
    productGroupSelector,
    excel,
    category,
    categoryGroupSelector,
    categorySelector,
    product,
    productSelector,
    promo,
    bill,
    phoneCall,
    uManager
});