import React from 'react';
import { Card, Spin, List, Empty, Button } from 'antd';

import { requestShops, createShop, modifyShop, selectShop } from '../../Redux/Actions/shopActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

import './ShopList.less';

class ShopList extends React.Component{
    constructor(props){
        super(props);

        this.onCreate = this.onCreate.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onCreate(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите сменить магазин? При смене внесённые изменения не будут сохранены!")){
            return;
        }
        this.props.createShop();
    }

    onClick(shop){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите сменить магазин? При смене внесённые изменения не будут сохранены!")){
            return;
        }
        this.props.modifyShop(shop);
    }

    renderList(){
        if (this.props.shopList == null || this.props.shopList.list == []){
            return <Empty description="Список магазинов пуст"/>
        }

        return <Scroll maxHeight="500" minHeight="100">
            <List
                itemLayout="horizontal"
                dataSource={this.props.shopList}
                renderItem={item => (
                    <List.Item 
                        onClick={ (e) => this.onClick(item) } 
                        className={ (this.props.selectedShop == item.id) ? `selectedItem` : `` }
                    >
                        <List.Item.Meta
                            title={ <span>{ ('name' in item && item.name != "") ? item.name : 'Безымянный магазин' }</span> }
                            description= { <span>{ ('address' in item && item.address != "") ? item.address : 'Адрес не указан' }</span> }
                        />
                    </List.Item>
                )}
            />
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка..." spinning={this.props.isFetching}>
            <Card className="shopList">
                {this.renderList()}
                {
                    this.hasEditorRights() &&
                    <Button type="primary" onClick={this.onCreate} block>Добавить магазин</Button>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    shopList: state.shop.shopList,
    selectedShop: state.shop.selectedShop,
    isFetching: state.shop.isFetching,
    editor: state.shop.shopEditor
});

export default connect(
    mapStateToProps,
    { requestShops, createShop, modifyShop, selectShop }
)(ShopList);