import {
    SET_BILL_FILTER,
    SET_BILL_LIST_PAGING,
    REQUEST_BILLS,
    RECEIVE_BILLS,
    PAGINATE_BILLS,
    CREATE_BILL,
    MODIFY_BILL,
    CLOSE_BILL_EDIT,
    BILL_MODIFIED,
    SELECT_BILL,
    ON_SAVE_BILL,
    AFT_SAVE_BILL,
    ON_DELETE_BILL,
    AFT_DELETE_BILL
} from '../ActionTypes/billActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const setBillFilter = (minDate, maxDate, status, order) => dispatch => {
    dispatch({
        type: SET_BILL_FILTER,
        payload: {
            minDate: minDate,
            maxDate: maxDate,
            status: status,
            order: order
        }
    });
}

export const setBillListPaging = (selectedPage, totalPages) => dispatch => {
    dispatch({
        type: SET_BILL_LIST_PAGING,
        payload: {
            selectedPage: selectedPage,
            totalPages: totalPages
        }
    });
}

export const billModified = (modified) => dispatch => {
    dispatch({
        type: BILL_MODIFIED,
        payload: { modified: modified }
    });
}

export const selectBill = (selectedId) => dispatch => {
    dispatch({
        type: SELECT_BILL,
        payload: { selectedId: selectedId }
    });
}

export const receiveBills = (billList, error, selectedPage, totalPages) => dispatch => {
    dispatch({
        type: RECEIVE_BILLS,
        payload: {
            error: error,
            billList: billList
        }
    });
    dispatch(setBillListPaging(selectedPage, totalPages));
}

export const requestBills = () => (dispatch, getState) => {
    dispatch({
        type: REQUEST_BILLS,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("bill/search", API_URL);

    var querry = {
        minDate: getState().bill.filter.minDate,
        maxDate: getState().bill.filter.maxDate,
        status: getState().bill.filter.status,
        order: getState().bill.filter.order,
        pageNum: getState().bill.billListPaging.selectedPage,
        pageCount: 10,
        getParams: {
            deliveryInfo: true,
            paymentInfo: true,
            billPositions: true
        }
    };

    axios.post(
        url.toString(),
        querry,
        { headers: headers }
    )
    .then((response) => {
        dispatch(receiveBills(response.data.bills, null, response.data.page, response.data.pages));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveBills(null, error, 0, 0));
        }
    });
}

export const paginateBills = (selectedPage) => dispatch => {
    dispatch({
        type: PAGINATE_BILLS,
        payload: {
            selectedPage: selectedPage
        }
    });
    dispatch(requestBills());
}

export const createBill = () => dispatch => {
    dispatch(selectBill(-1));
    dispatch(billModified(false));
    dispatch({
        type: CREATE_BILL,
        payload: null
    });
}

export const modifyBill = (bill) => dispatch => {
    dispatch(selectBill(bill.id));
    dispatch(billModified(false));
    dispatch({
        type: MODIFY_BILL,
        payload: {
            item: bill
        }
    });
}

export const closeBillEdit = () => dispatch => {
    dispatch(selectBill(-1));
    dispatch(billModified(false));
    dispatch({
        type: CLOSE_BILL_EDIT,
        payload: null
    });
}

export const aftSaveBill = (bill, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_BILL,
        payload: { error: error }
    });

    if (error == null)
        dispatch(modifyBill(bill));
    dispatch(requestBills());
}

export const onSaveBill = (bill, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_BILL,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("bill", API_URL);
    
    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                bill,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                bill,
                { headers: headers }
            )
        }
    }

    savePromise.then((response) => {
        dispatch(aftSaveBill(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveBill(null, error));
        }
    });
}

export const aftDeleteBill = error => dispatch => {
    dispatch({
        type: AFT_DELETE_BILL,
        payload: { error: error }
    });
    dispatch(closeBillEdit());
    dispatch(requestBills());
}

export const onDeleteBill = bill => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_BILL,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "bill/" + bill.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeleteBill(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteBill(error));
        }
    });
}