export const SET_PHONECALL_FILTER = "SET_PHONECALL_FILTER";
export const SET_PHONECALL_LIST_PAGING = "SET_PHONECALL_LIST_PAGING";

export const REQUEST_PHONECALLS = "REQUEST_PHONECALLS";
export const RECEIVE_PHONECALLS = "RECEIVE_PHONECALLS";
export const PAGINATE_PHONECALLS = "PAGINATE_PHONECALLS";

export const CREATE_PHONECALL = "CREATE_PHONECALL";
export const MODIFY_PHONECALL = "MODIFY_PHONECALL";
export const CLOSE_PHONECALL_EDIT = "CLOSE_PHONECALL_EDIT";

export const PHONECALL_MODIFIED = "PHONECALL_MODIFIED";
export const SELECT_PHONECALL = "SELECT_PHONECALL";

export const ON_DELETE_PHONECALL = "ON_DELETE_PHONECALL";
export const AFT_DELETE_PHONECALL = "AFT_DELETE_PHONECALL";

export const ON_SAVE_PHONECALL = "ON_SAVE_PHONECALL";
export const AFT_SAVE_PHONECALL = "AFT_SAVE_PHONECALL";