import React, { useRef } from 'react';
import JoditEditor from "jodit-react";

export default class AboutTab extends React.Component{

    jodit;
    setRef = jodit => this.jodit = jodit;

    key = -1;

    constructor(props){
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState){
        if (this.props.key == this.key)
            return false;

        this.key = this.props.key;
        if (this.key == 0)
            this.key = -1;
        return true;
    }

    render(){
        return <JoditEditor
            ref={this.setRef}
            value={this.props.value}
            onChange={this.props.onChange}
            config={{ readonly: this.props.disabled }}
        />
    }
}