import React, { Fragment } from 'react';
import { Card, Spin, List, Empty, Button, Pagination, Space, Input, Avatar, Table, DatePicker, Select } from 'antd';
import Scroll from '../../Utils/Scroll/Scroll.js';
import moment from 'moment';

import {
    setPhoneCallFilter,
    paginatePhoneCalls,
    requestPhoneCalls,
    createPhoneCall,
    modifyPhoneCall,
} from '../../Redux/Actions/phoneCallActions';
import { connect } from 'react-redux';

import './PhoneCallList.less';

const { Option } = Select;

class PhoneCallList extends React.Component{

    constructor(props){
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onModify = this.onModify.bind(this);

        this.onChangeMinDate = this.onChangeMinDate.bind(this);
        this.onChangeMaxDate = this.onChangeMaxDate.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeOrder = this.onChangeOrder.bind(this);

        this.renderItemTitle = this.renderItemTitle.bind(this);
        this.renderItemDescr = this.renderItemDescr.bind(this);
    }

    onSearch(e){
        this.props.requestPhoneCalls();
    }

    onPaging(page, pageSize){
        this.props.paginatePhoneCalls(page);
    }

    onCreate(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.createPhoneCall();
    }

    onModify(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.modifyPhoneCall(e);
    }

    onChangeMinDate(date, dateString){
        this.props.setPhoneCallFilter(
            date,
            this.props.filter.maxDate,
            this.props.filter.status,
            this.props.filter.order
        );
    }

    onChangeMaxDate(date, dateString){
        this.props.setPhoneCallFilter(
            this.props.filter.minDate,
            date,
            this.props.filter.status,
            this.props.filter.order
        );
    }

    onChangeStatus(e){
        this.props.setPhoneCallFilter(
            this.props.filter.minDate,
            this.props.filter.maxDate,
            (e == "null") ? null : (e == "true"),
            this.props.filter.order
        )
    }

    onChangeOrder(e){
        this.props.setPhoneCallFilter(
            this.props.filter.minDate,
            this.props.filter.maxDate,
            this.props.filter.status,
            parseInt(e)
        );
    }

    renderSearch(){
        return <div class="search">
            <Space direction="horizontal">
                <span>Дата с:</span>
                <DatePicker 
                    value={ (this.props.filter.minDate != null) ? moment(this.props.filter.minDate, 'DD/MM/YYYY') : null }
                    onChange={this.onChangeMinDate} 
                />
                <span>Дата по:</span>
                <DatePicker 
                    value={ (this.props.filter.maxDate != null) ? moment(this.props.filter.maxDate, 'DD/MM/YYYY') : null }
                    onChange={this.onChangeMaxDate} 
                />
                <span>Статус:</span>
                <Select 
                    value={ (this.props.filter.status == null) ? "null" : this.props.filter.status.toString()} 
                    onChange={this.onChangeStatus}
                    style={{ width: 120 }}
                >
                    <Option value="null">Любой</Option>
                    <Option value="true">Обработан</Option>
                    <Option value="false">Не обработан</Option>
                </Select>
                <span>Порядок:</span>
                <Select 
                    value={ this.props.filter.order.toString()} 
                    onChange={this.onChangeOrder}
                    style={{ width: 200 }}
                >
                    <Option value="0">Сначала новые</Option>
                    <Option value="1">Сначала старые</Option>
                </Select>
                <Button type="primary" onClick={this.onSearch}>Поиск</Button>
            </Space>
        </div>
    }

    renderItemTitle(item){
        switch (item.status){
            case false: return <span>Необработанный звонок: { item.phone }</span>;
            case true: return <span>Обработанный звонок: { item.phone }</span>;
        }
    }

    renderItemDescr(item){
        var result = ``;

        if (item.dateTime){
            result = `${moment.utc(item.dateTime).local().format("DD.MM.YYYY HH:mm:ss")};`;
        }

        return result;
    }

    renderPagination(){
        if (this.props.phoneCallListPaging.totalPages <= 1)
            return <Fragment/>;
        
        return <div class="pagination">
            <Pagination
                current={this.props.phoneCallListPaging.selectedPage}
                total={this.props.phoneCallListPaging.totalPages * 10}
                onChange={this.onPaging}
            />
        </div>
    }

    renderList(){
        if (this.props.phoneCallList == null || this.props.phoneCallList.length == 0)
            return <Empty description="Список звонков пуст"/>

        return <Scroll maxHeight="400" minHeight="0">
            <List
                itemLayout="vertical"
                dataSource={this.props.phoneCallList}
                renderItem={item => (
                    <List.Item
                        onClick={ (e) => this.onModify(item) }
                        className={ (this.props.selectedId == item.id) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            title={this.renderItemTitle(item)}
                            description={this.renderItemDescr(item)}
                        />
                    </List.Item>
                )}
            >
            </List>
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка" spinning={this.props.isFetching}>
            <Card className="phoneCallList">
                <Space direction="vertical">
                    {this.renderSearch()}
                    {this.renderList()}
                    {this.renderPagination()}
                    <Button type="primary" onClick={this.onCreate} block>Создать звонок</Button>
                </Space>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    filter: state.phoneCall.filter,
    isFetching: state.phoneCall.isFetching,
    phoneCallList: state.phoneCall.phoneCallList,
    phoneCallListPaging: state.phoneCall.phoneCallListPaging,
    selectedId: state.phoneCall.selectedId,
    editor: state.phoneCall.editor
});

export default connect(
    mapStateToProps,
    {
        setPhoneCallFilter, paginatePhoneCalls,
        requestPhoneCalls,
        createPhoneCall, modifyPhoneCall
    }
)(PhoneCallList);