import {
    SET_PHONECALL_FILTER,
    SET_PHONECALL_LIST_PAGING,
    REQUEST_PHONECALLS,
    RECEIVE_PHONECALLS,
    PAGINATE_PHONECALLS,
    CREATE_PHONECALL,
    MODIFY_PHONECALL,
    CLOSE_PHONECALL_EDIT,
    PHONECALL_MODIFIED,
    SELECT_PHONECALL,
    ON_DELETE_PHONECALL,
    AFT_DELETE_PHONECALL,
    ON_SAVE_PHONECALL,
    AFT_SAVE_PHONECALL
} from '../ActionTypes/phoneCallActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const setPhoneCallFilter = (minDate, maxDate, status, order) => dispatch => {
    dispatch({
        type: SET_PHONECALL_FILTER,
        payload: {
            minDate: minDate,
            maxDate: maxDate,
            status: status,
            order: order
        }
    });
}

export const setPhoneCallListPaging = (selectedPage, totalPages) => dispatch => {
    dispatch({
        type: SET_PHONECALL_LIST_PAGING,
        payload: {
            selectedPage: selectedPage,
            totalPages: totalPages
        }
    });
}

export const phoneCallModified = (modified) => dispatch => {
    dispatch({
        type: PHONECALL_MODIFIED,
        payload: { modified: modified }
    });
}

export const selectPhoneCall = (selectedId) => dispatch => {
    dispatch({
        type: SELECT_PHONECALL,
        payload: { selectedId: selectedId }
    });
}

export const receivePhoneCalls = (phoneCallList, error, selectedPage, totalPages) => dispatch => {
    dispatch({
        type: RECEIVE_PHONECALLS,
        payload: {
            error: error,
            phoneCallList: phoneCallList
        }
    });
    dispatch(setPhoneCallListPaging(selectedPage, totalPages));
}

export const requestPhoneCalls = () => (dispatch, getState) => {
    dispatch({
        type: REQUEST_PHONECALLS,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("phone/search", API_URL);

    var querry = {
        minDate: getState().phoneCall.filter.minDate,
        maxDate: getState().phoneCall.filter.maxDate,
        status: getState().phoneCall.filter.status,
        order: getState().phoneCall.filter.order,
        pageNum: getState().phoneCall.phoneCallListPaging.selectedPage,
        pageCount: 10
    };

    axios.post(
        url.toString(),
        querry,
        { headers: headers }
    )
    .then((response) => {
        dispatch(receivePhoneCalls(response.data.phones, null, response.data.page, response.data.pages));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receivePhoneCalls(null, error, 0, 0));
        }
    });
}

export const paginatePhoneCalls = (selectedPage) => dispatch => {
    dispatch({
        type: PAGINATE_PHONECALLS,
        payload: {
            selectedPage: selectedPage
        }
    });
    dispatch(requestPhoneCalls());
}

export const createPhoneCall = () => dispatch => {
    dispatch(selectPhoneCall(-1));
    dispatch(phoneCallModified(false));
    dispatch({
        type: CREATE_PHONECALL,
        payload: null
    });
}

export const modifyPhoneCall = (phoneCall) => dispatch => {
    dispatch(selectPhoneCall(phoneCall.id));
    dispatch(phoneCallModified(false));
    dispatch({
        type: MODIFY_PHONECALL,
        payload: {
            item: phoneCall
        }
    });
}

export const closePhoneCallEdit = () => dispatch => {
    dispatch(selectPhoneCall(-1));
    dispatch(phoneCallModified(false));
    dispatch({
        type: CLOSE_PHONECALL_EDIT,
        payload: null
    });
}

export const aftSavePhoneCall = (phoneCall, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_PHONECALL,
        payload: { error: error }
    });

    if (error == null)
        dispatch(modifyPhoneCall(phoneCall));
    dispatch(requestPhoneCalls());
}

export const onSavePhoneCall = (phoneCall, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_PHONECALL,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("phone", API_URL);
    
    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                phoneCall,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                phoneCall,
                { headers: headers }
            )
        }
    }

    savePromise.then((response) => {
        dispatch(aftSavePhoneCall(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSavePhoneCall(null, error));
        }
    });
}

export const aftDeletePhoneCall = error => dispatch => {
    dispatch({
        type: AFT_DELETE_PHONECALL,
        payload: { error: error }
    });
    dispatch(closePhoneCallEdit());
    dispatch(requestPhoneCalls());
}

export const onDeletePhoneCall = phoneCall => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PHONECALL,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "phone/" + phoneCall.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeletePhoneCall(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeletePhoneCall(error));
        }
    });
}