import React, { Fragment } from "react";
import { Card, Spin, Tree, Empty, Button, Space } from 'antd';

import {
    createCategoryGroup,
    modifyCategoryGroup,
    createCategory,
    modifyCategory
} from '../../Redux/Actions/categoryActions';
import { connect } from 'react-redux';

import './CategoryTree.less';

class CategoryTree extends React.Component{
    constructor(props){
        super(props);

        this.selectedGroup = null;
        this.onCreateGroup = this.onCreateGroup.bind(this);
        this.onCreateCategory = this.onCreateCategory.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }


    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onCreateGroup(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.createCategoryGroup();
    }

    onCreateCategory(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.createCategory(this.selectedGroup);
    }

    onCheck(checkedKeys, info){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        if (!info.node.group){
            this.props.modifyCategoryGroup(info.node);
            this.selectedGroup = info.node;
        }
        else{
            this.props.modifyCategory(info.node);
            this.selectedGroup = info.node.group;
        }
    }

    renderButtons(){
        if (!this.hasEditorRights())
            return <Fragment/>

        return <Fragment>
            <Button type="primary" block onClick={this.onCreateGroup} >
                Добавить группу категорий
            </Button>
            <Button type="primary" block onClick={this.onCreateCategory} disabled={this.props.selectedId == -1}>
                Добавить категорию
            </Button>
        </Fragment>
    }

    renderTree(){
        if (!this.props.categoryGroupList || this.props.categoryGroupList.length === 0)
            return <Empty description="Дерево пусто"/>

        return <Tree
            checkedKeys={ (this.props.selectedId == -1) ? [] : [ this.props.selectedId.toString() ]}
            onCheck={this.onCheck}
            treeData={this.props.categoryGroupList}
            checkable={true}
            showLine={true}
            selectable={false}
            checkStrictly={true}
            defaultExpandAll={true}
        />
    }

    render(){
        return <Spin tip="Обновление дерева..." spinning={this.props.isFetching}>
            <Card className="categoryTree">
                <Space direction="vertical">
                    {this.renderTree()}
                    {this.renderButtons()}
                </Space>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    isFetching: state.category.isFetching,
    categoryGroupList: state.category.categoryGroupList,
    selectedId: state.category.selectedId,
    editor: state.category.editor
});

export default connect(
    mapStateToProps,
    { createCategory, modifyCategory, createCategoryGroup, modifyCategoryGroup }
)(CategoryTree);