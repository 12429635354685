export const SET_PRODUCT_FILTER = "SET_PRODUCT_FILTER";
export const SET_PRODUCT_LIST_PAGING = "SET_PRODUCT_LIST_PAGING";

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const PAGINATE_PRODUCTS = "PAGINATE_PRODUCTS";


export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const MODIFY_PRODUCT = "MODIFY_PRODUCT";
export const CLOSE_PRODUCT_EDIT = "CLOSE_PRODUCT_EDIT";

export const ON_DELETE_PRODUCT = "ON_DELETE_PRODUCT";
export const AFT_DELETE_PRODUCT = "AFT_DELETE_PRODUCT";

export const ON_SAVE_PRODUCT = "ON_SAVE_PRODUCT";
export const AFT_SAVE_PRODUCT = "AFT_SAVE_PRODUCT";

export const SELECT_PRODUCT = "SELECT_PRODUCT";

export const PRODUCT_MODIFIED = "PRODUCT_MODIFIED";

export const ON_PRODUCT_ADD_CATEGORY = "ON_PRODUCT_ADD_CATEGORY";
export const AFT_PRODUCT_ADD_CATEGORY = "AFT_PRODUCT_ADD_CATEGORY";

export const ON_PRODUCT_DEL_CATEGORY = "ON_PRODUCT_DEL_CATEGORY";
export const AFT_PRODUCT_DEL_CATEGORY = "AFT_PRODUCT_DEL_CATEGORY";

export const ON_PRODUCT_DEL_SMALL_IMG = "ON_PRODUCT_DEL_SMALL_IMG";
export const AFT_PRODUCT_DEL_SMALL_IMG = "AFT_PRODUCT_DEL_SMALL_IMG";

export const ON_PRODUCT_ADD_SMALL_IMG = "ON_PRODUCT_ADD_SMALL_IMG";
export const AFT_PRODUCT_ADD_SMALL_IMG = "AFT_PRODUCT_ADD_SMALL_IMG";

export const ON_PRODUCT_DEL_GALLERY = "ON_PRODUCT_DEL_GALLERY";
export const AFT_PRODUCT_DEL_GELLERY = "AFT_PRODUCT_DEL_GALLERY";

export const ON_PRODUCT_ADD_GALLERY = "ON_PRODUCT_ADD_GALLERY";
export const AFT_PRODUCT_ADD_GALLERY = "AFT_PRODUCT_ADD_GALLERY";