import React, { Fragment } from 'react';
import { Spin, List, Empty, Button, Modal } from 'antd';

import { 
    onCitySelection, 
    aftCitySelection,
    onCitySelectionSelect,
    onCitySelectionClose
} from '../../Redux/Actions/citySelectorActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

import './CitySelector.less';

class CitySelector extends React.Component{
    constructor(props){
        super(props);

        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel(e){
        this.props.onCitySelectionClose();
    }
    onOk(e){
        this.props.aftCitySelection();
    }

    onCityClick(city){
        this.props.onCitySelectionSelect(city, city.id);
    }

    renderList(){
        if (this.props.itemList == null || this.props.itemList.list == []){
            return <Empty description="Список городов пуст"/>
        }

        return <Scroll maxHeight="500" minHeight="100">
            <Spin tip="Загрузка..." spinning={this.props.isFetching}>
                <List
                    itemLayout="horizontal"
                    dataSource={this.props.itemList}
                    renderItem={item => (
                        <List.Item 
                            onClick={ (e) => this.onCityClick(item) } 
                            className={ (this.props.selectedId == item.id) ? `selectedItem` : `` }
                        >
                            <List.Item.Meta
                                title={ <span>{ ('name' in item) ? item.name : 'Безымянный город' }</span> }
                                description= { <span>{ ('region' in item) ? item.region : 'Регион не указан' }</span> }
                            />
                        </List.Item>
                    )}
                />
            </Spin>
        </Scroll>
    }

    render(){
        return <Modal
            visible={this.props.visible}
            title="Выбор города"
            confirmLoading={this.props.isFetching}
            className="citySelector"
            footer={[
                <Button key="submit" type="primary" onClick={this.onOk} disabled={ this.props.selectedId == -1} >
                    Выбрать
                </Button>
            ]}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            {this.renderList()}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    visible: state.citySelector.visible,
    error: state.citySelector.error,
    isFetching: state.citySelector.isFetching,
    itemList: state.citySelector.itemList,
    selectedId: state.citySelector.selectedId
});

export default connect(
    mapStateToProps,
    { onCitySelection, aftCitySelection, onCitySelectionSelect, onCitySelectionClose }
)(CitySelector);