import React, { Fragment } from 'react';
import { Card, Spin, List, Empty, Button, Pagination, Space, Input, Avatar, Checkbox } from 'antd';
import Scroll from '../../Utils/Scroll/Scroll.js';

import { API_URL } from '../../Variables';


import { 
    setProductFilter,
    paginateProducts,
    requestProducts,
    createProduct,
    modifyProduct
} from '../../Redux/Actions/productActions';

import { connect } from 'react-redux';

import './ProductList.less';

class ProductList extends React.Component{
    constructor(props){
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onModify = this.onModify.bind(this);
        
        this.onChangeArticle = this.onChangeArticle.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeOnlyHidden = this.onChangeOnlyHidden.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    getAvatarSrc(item){
        try{
            var id = item.smallImg[0].Id;
        } catch {
            id = 0;
        }

        var urlPath = `file/${id.toString()}`;
        var url = new URL(urlPath, API_URL);
        return url.toString();
    }

    onSearch(e){
        this.props.requestProducts();
    }

    onPaging(page, pageSize){
        this.props.paginateProducts(page);
    }

    onCreate(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }
        
        this.props.createProduct();
    }

    onModify(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }
        this.props.modifyProduct(e);
    }

    articleBuff = null;
    nameBuf = null;
    onlyHiddenBuff = false;

    onChangeArticle(e){
        this.articleBuff = (e.target.value == "") ? null : e.target.value;
        this.props.setProductFilter(this.nameBuf, this.articleBuff, this.onlyHiddenBuff);
    }

    onChangeName(e){
        this.nameBuf = e.target.value;
        this.props.setProductFilter(this.nameBuf, this.articleBuff, this.onlyHiddenBuff);
    }

    onChangeOnlyHidden(e){
        this.onlyHiddenBuff = e.target.checked;
        this.props.setProductFilter(this.nameBuf, this.articleBuff, this.onlyHiddenBuff);
    }

    renderSearch(){
        return <div class="search">
            <Space direction="horizontal">
                <span>Артикул:</span>
                <Input size="small" value={this.props.filter.article} onChange={this.onChangeArticle}/>
                <span>Название:</span>
                <Input size="small" value={this.props.filter.name} onChange={this.onChangeName}/>
                <Checkbox value={this.props.filter.onlyHidden} onChange={this.onChangeOnlyHidden}>Только скрытые</Checkbox>
                <Button type="primary" size="small" onClick={this.onSearch}>Поиск</Button>
            </Space>
        </div>
    }

    renderPagination(){
        if (this.props.productListPaging.totalPages == 0)
            return <Fragment/>

        return <div class="pagination">
            <Pagination
                current={this.props.productListPaging.selectedPage}
                total={this.props.productListPaging.totalPages * 10}
                onChange={this.onPaging}
            />
        </div>
    }

    renderList(){
        if (this.props.productList == null || this.props.productList.length == 0)
            return <Empty description="Список продуктов пуст"/>

        return <Scroll maxHeight="400" minHeight="0">
            <List
                itemLayout="horizontal"
                dataSource={this.props.productList}
                renderItem={item => (
                    <List.Item
                        onClick={ (e) => this.onModify(item) }
                        className={ (this.props.selectedId == item.id) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            avatar={ <Avatar src={this.getAvatarSrc(item)}/> }
                            title={ <span>{ item.name }</span> }
                            description={ <span>{ item.productGroup?.name }</span> }
                        />
                    </List.Item>
                )}
            />
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка..." spinning={this.props.isFetching}>
            <Card className="productList">
                <Space direction="vertical">
                    {this.renderSearch()}
                    {this.renderList()}
                    {this.renderPagination()}
                    {
                        this.hasEditorRights() &&
                        <Button type="primary" onClick={this.onCreate} block>Создать продукт</Button>
                    }
                </Space>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    filter: state.product.filter,
    isFetching: state.product.isFetching,
    productList: state.product.productList,
    productListPaging: state.product.productListPaging,
    selectedId: state.product.selectedId,
    editor: state.product.editor
});

export default connect(
    mapStateToProps,
    { setProductFilter, paginateProducts, requestProducts, createProduct, modifyProduct }
)(ProductList);