import { 
    LOGIN, 
    LOGOUT, 
    RECEIVE_TOKEN, 
    RECEIVE_ERROR,
    ON_CHANGE_PASSWORD,
    AFT_CHANGE_PASSWORD, 
} from "../ActionTypes/userActionTypes";

const initialState = {
    token: null,
    token_err: null,
    onLogin: false,
    error: null,
    isFetching: false
}

export default function(state = initialState, action){
    switch (action.type){
        case LOGIN: {
            return { ...state, token: null, token_err: false, onLogin: true };
        }
        case LOGOUT: {
            return { ...state, token: null, token_err: false, onLogin: false };
        }
        case RECEIVE_TOKEN: {
            return { ...state, token: action.payload, token_err: null, onLogin: false };
        }
        case RECEIVE_ERROR: {
            return { ...state, token: null, token_err: action.payload, onLogin: false };
        }
        case ON_CHANGE_PASSWORD: return {
            ...state,
            error: null,
            isFetching: true,
        }
        case AFT_CHANGE_PASSWORD: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
        }
        default:
            return state;
    }
}