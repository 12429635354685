import {
    REQUEST_PROMOS,
    RECEIVE_PROMOS,
    CREATE_PROMO,
    MODIFY_PROMO,
    CLOSE_PROMO_EDIT,
    ON_DELETE_PROMO,
    AFT_DELETE_PROMO,
    ON_SAVE_PROMO,
    AFT_SAVE_PROMO,
    SELECT_PROMO,
    PROMO_MODIFIED,
    ON_SAVE_PROMO_SMALL_IMG,
    AFT_SAVE_PROMO_SMALL_IMG,
    ON_DELETE_PROMO_SMALL_IMG,
    AFT_DELETE_PROMO_SMALL_IMG,
    ON_ADD_PROMO_PRODUCT,
    AFT_ADD_PROMO_PRODUCT,
    ON_DELETE_PROMO_PRODUCT,
    AFT_DELETE_PROMO_PRODUCT
} from '../ActionTypes/promoActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";
import { sortByOrderAndName } from "../../funcs";
import product from '../Reducers/product';
import { get } from 'lodash';

export const promoModified = (modified) => dispatch => {
    dispatch({
        type: PROMO_MODIFIED,
        payload: {
            modified: modified
        }
    });
}

export const selectPromo = (selectedId) => dispatch => {
    dispatch({
        type: SELECT_PROMO,
        payload: {
            selectedId: selectedId
        }
    });
}

export const receivePromos = (promoList, error) => dispatch => {
    dispatch({
        type: RECEIVE_PROMOS,
        payload: {
            promoList: promoList,
            error: error
        }
    });
}

export const requestPromos = () => dispatch => {
    dispatch({
        type: REQUEST_PROMOS,
        payload: null
    });

    var url = new URL("promo", API_URL);
    url.searchParams.set("mainText", true);
    url.searchParams.set("subText", true);
    url.searchParams.set("image", true);
    url.searchParams.set("description", true);
    url.searchParams.set("products", true);
    url.searchParams.set("order", true);

    axios.get(url.toString())
    .then((response) => {
        dispatch(receivePromos(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receivePromos(null, error));
        }
    });
}

export const createPromo = () => dispatch => {
    dispatch(selectPromo(-1));
    dispatch(promoModified(false));
    dispatch({
        type: CREATE_PROMO,
        payload: null
    });
}

export const modifyPromo = (promo) => dispatch => {
    dispatch(selectPromo(promo.id));
    dispatch(promoModified(false));
    dispatch({
        type: MODIFY_PROMO,
        payload: {
            item: promo
        }
    });
}

export const closePromoEdit = () => dispatch => {
    dispatch(selectPromo(-1));
    dispatch(promoModified(false));
    dispatch({
        type: CLOSE_PROMO_EDIT,
        payload: null
    });
}

export const aftDeletePromo = error => dispatch => {
    dispatch({
        type: AFT_DELETE_PROMO,
        payload: { error: error }
    });
    dispatch(closePromoEdit());
    dispatch(requestPromos());
}

export const onDeletePromo = promo => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PROMO,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "promo/" + promo.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeletePromo(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeletePromo(error));
        }
    });
}

export const aftSavePromo = (promo, promoId, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_PROMO,
        payload: { error: error }
    });

    if (error == null){
        var newPromo = promo;
        if (promoId != -1)
            newPromo.id = promoId;
        dispatch(modifyPromo(newPromo));
    }
    dispatch(requestPromos());
}

export const onSavePromo = (promo, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_PROMO,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("promo", API_URL);

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                promo,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                promo,
                { headers: headers }
            );
            break;
        }
    }

    savePromise
    .then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSavePromo(promo, -1, null));
        else
            dispatch(aftSavePromo(promo, parseInt(response.data), null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSavePromo(promo, -1, error));
        }
    })
}

export const aftSavePromoSmallImg = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_PROMO_SMALL_IMG,
        payload: { newImg: newImg, error: error }
    });
    dispatch(requestPromos());
}

export const onSavePromoSmallImg = (promo, file) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_PROMO_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`promo/${ promo.id.toString() }/image`, API_URL);

    var formData = new FormData();
    formData.append("img", file);

    axios.post(
        url.toString(),
        formData,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftSavePromoSmallImg(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSavePromoSmallImg(null, error));
        }
    });
}

export const aftDeletePromoSmallImg = (newImg, error) => dispatch => {
    dispatch({
        type: AFT_DELETE_PROMO_SMALL_IMG,
        payload: { newImg: newImg, error: error }
    });
    dispatch(requestPromos());
}

export const onDeletePromoSmallImg = (promo) => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PROMO_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`promo/${ promo.id.toString() }/image`, API_URL);

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeletePromoSmallImg(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeletePromoSmallImg(null, error));
        }
    })
}

export const aftAddPromoProduct = (newProducts, error) => dispatch => {
    dispatch({
        type: AFT_ADD_PROMO_PRODUCT,
        payload: {
            newProducts: newProducts,
            error: error
        }
    });
    dispatch(requestPromos());
}

export const onAddPromoProduct = (promo, product) => (dispatch, getState) => {
    dispatch({
        type: ON_ADD_PROMO_PRODUCT,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        `promo/${ promo.id.toString() }/product/${ product.id.toString() }`,
        API_URL
    );

    axios.post(
        url.toString(),
        null,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftAddPromoProduct(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftAddPromoProduct(null, error));
        }
    })
}

export const aftDeletePromoProduct = (newProducts, error) => dispatch => {
    dispatch({
        type: AFT_DELETE_PROMO_PRODUCT,
        payload: {
            newProducts: newProducts,
            error: error
        }
    });
    dispatch(requestPromos());
}

export const onDeletePromoProduct = (promo, product) => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PROMO_PRODUCT,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        `promo/${ promo.id.toString() }/product/${ product.id.toString() }`,
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeletePromoProduct(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeletePromoProduct(null, error));
        }
    })
}