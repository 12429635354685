import React, { Fragment } from 'react';
import { Input, Button, Space, InputNumber } from 'antd';
import ImageGallery from 'react-image-gallery';

import { API_URL } from "../../../../Variables";

import './GalleryTab.less';

export default class GalleryTab extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            addIndex: 0,
            delIndex: 0,
            file: null,
        }

        this.onFileChange = this.onFileChange.bind(this);
        this.onAddIndexChange = this.onAddIndexChange.bind(this);
        this.onDelIndexChange = this.onDelIndexChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteAll = this.onDeleteAll.bind(this);
    }

    onFileChange(e){
        this.setState({
            ...this.state,
            file: e.target.files[0]
        });
    }

    onAddIndexChange(e){
        this.setState({
            ...this.state,
            addIndex: (e != "") ? e : 0
        });
    }

    onDelIndexChange(e){
        this.setState({
            ...this.state,
            delIndex: (e != "") ? e : 0
        });
    }

    onAdd(e){

        this.props.onAdd({ file: this.state.file, pos: this.state.addIndex });
        this.setState({
            index: 0,
            file: null
        });

    }

    onDelete(e){
        this.props.onDelete({ pos: this.state.delIndex });
        this.setState({
            index: 0,
            file: null
        })
    }

    onDeleteAll(e){
        this.props.onDelete({ pos: null });
        this.setState({
            index: 0,
            file: null
        })
    }

    convertGalleryLinks(){
        var links = [];
        this.props.imageList.forEach(img => {
            var url = new URL(`file/${ img.Id }`, API_URL).toString();
            links.push({
                original: url,
                thumbnail: url
            });
        });

        return links;
    }

    renderGallery(){
        if (!this.props.imageList.length)
            return <span class="noImage">Изображения отсутствуют</span>

        var links = this.convertGalleryLinks();
        return <div class='imgGalleryContainer'>
            <ImageGallery
                items={links}
                autoPlay={false}
            />
        </div>;
    }

    renderControls(){
        if (this.props.disabled == true)
            return <Fragment/>

        return <div class="controls">
            <Space direction="vertical">
                <Space direction="horizontal">
                    <Input type='file' accept="image/*" size="small" onChange={this.onFileChange}/>
                    <span>Позиция:</span>
                    <InputNumber min={0} max={Math.max(0, this.props.imageList.length)} value={this.state.addIndex} onChange={this.onAddIndexChange}/>
                    <Button type="primary" size="small" onClick={this.onAdd} disabled={this.state.file == null}>
                        Добавить изображение
                    </Button>
                </Space>
                <Space direction="horizontal">
                    <span>Позиция:</span>
                    <InputNumber min={0} max={Math.max(0, this.props.imageList.length - 1)} value={this.state.delIndex} onChange={this.onDelIndexChange}/>
                    <Button type="primary" size="small" danger onClick={this.onDelete} disabled={!this.props.imageList.length}>
                        Удалить изображение
                    </Button>
                    <Button type="primary" size="small" danger onClick={this.onDeleteAll} disabled={!this.props.imageList.length}>
                        Удалить все изображения
                    </Button>
                    
                </Space>
            </Space>
        </div>
    }

    render(){
        return <div class="galleryTab">
            <Space direction="vertical">
                {this.renderControls()}
                {this.renderGallery()}
            </Space>
        </div>
    }
}