import React, { Fragment } from 'react';
import { Layout, Card, Menu, Button } from 'antd';
import { Link, Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import { logout } from '../Redux/Actions/userActions';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import './MainLayout.less';

import HelloPage from '../Pages/HelloPage/HelloPage.js';
import CitiesPage from '../Pages/CitiesPage/CitiesPage.js';
import ShopPage from '../Pages/ShopPage/ShopPage.js';
import LoginPage from '../Pages/LoginPage/LoginPage.js';
import ProductGroupPage from '../Pages/ProductGroupPage/ProductGroupPage.js';
import ExcelPage from '../Pages/ExcelPage/ExcelPage.js';
import CategoryPage from '../Pages/CategoryPage/CategoryPage.js';
import ProductPage from '../Pages/ProductPage/ProductPage.js';
import PromoPage from '../Pages/PromoPage/PromoPage.js';
import BillPage from '../Pages/BillPage/BillPage.js';
import PhoneCallPage from '../Pages/PhoneCallPage/PhoneCallPage.js';
import UserPage from '../Pages/UserPage/UserPage.js';
import UManagerPage from '../Pages/UManagerPage/UManagerPage.js';

import CitySelector from '../Selectors/CitySelector/CitySelector.js';
import ProductGroupSelector from '../Selectors/ProductGroupSelector/ProductGroupSelector.js';
import CategoryGroupSelector from '../Selectors/CategoryGroupSelector/CategoryGroupSelector.js';
import CategorySelector from '../Selectors/CategorySelector/CategorySelector.js';
import ProductSelector from '../Selectors/ProductSelector/ProductSelector.js';
import { compose } from 'redux';

const { Header, Sider, Content } = Layout;

class MainLayout extends React.Component{
    
    constructor(props){
        super(props);

        this.logout = this.logout.bind(this);
    }

    getMenuItems(){
        return [
            { name: "Города", link: "/show/cities", title: "Редактор городов", lvl: 3 },
            { name: "Магазины", link: "/show/shops", title: "Редактор магазинов", lvl: 3 },
            { name: "Группы товаров", link: "/show/pgroup", title: "Редактор групп товаров", lvl: 3 },
            { name: "Импорт из Excel", link: "/show/excel", title: "Импорт из Excel", lvl: 2 },
            { name: "Категории товаров", link: "/show/category", title: "Редактор категорий товаров", lvl: 2 },
            { name: "Продукты", link: "/show/product", title: "Редактор продуктов", lvl: 3 },
            { name: "Промоакции", link: "/show/promo", title: "Редактор промоакций", lvl: 3 },
            { name: "Заказы", link: "/show/bill", title: "Редактор заказов", lvl: 3 },
            { name: "Входящие звонки", link: "/show/phonecall", title: "Редактор входящих звонков", lvl: 3 },
            { name: "Текущий пользователь", link: "/show/user", title: "Текущий пользователь", lvl: 3 },
            { name: "Управление пользователями", link: "/show/umanager", title: "Управления пользователями", lvl: 2 }
        ];
    }

    GetUserLvl(){
        if (this.props.userRole == 'Moderator')
            return 3;
        else if (this.props.userRole == 'Admin')
            return 2;
        else if (this.props.userRole == 'Root')
            return 1;
        else
            return 4;
    }

    getUserName(){
        if (this.props.user.token == null)
            return 'Неавторизованный пользователь';

        return this.props.user.token.userName;
    }

    logout(e){
        this.props.logout();
    }

    renderMenu(){
        var lvl = this.GetUserLvl();

        return <Fragment>
            <div class="logoBox">
                <span class="companyName">Домашний погребок</span>
                <span class="description">Панель администрирования</span>
            </div>
            <Menu theme='dark' mode="inline" >
            {
                this.getMenuItems().filter(c => c.lvl >= lvl).map(function (item, index) {
                    return <Menu.Item key={index}>
                        <Link to={item.link}>{item.name}</Link>
                    </Menu.Item>
                })
            }
            </Menu>
        </Fragment>;
    }

    getHeaderTitle(){

        if (this.props.pathname == '/show')
            return 'Приветствие';

        var item = this.getMenuItems().find(x => x.link == this.props.pathname);
        return (item === undefined || item === null) ? "Раздел без названия" : item.title;
    }

    renderHeader(){
        return <Fragment>
            <span class="title">{this.getHeaderTitle()}</span>
            <div class="loginRow">
                <span class="greetings">Здравствуйте, </span>
                <span class="login">{this.getUserName()}</span>
                <Button type="primary" className="logout" danger onClick={this.logout}>
                    Выйти
                </Button>
            </div>
        </Fragment>
    }

    renderContent(){
        return <div>
            <Switch>
                <Route exact path="/show">
                    <HelloPage/>
                </Route> 
                <Route path="/show/cities">
                    <CitiesPage/>
                </Route>
                <Route path="/show/shops">
                    <ShopPage/>
                </Route>
                <Route path="/show/pgroup">
                    <ProductGroupPage/>
                </Route>
                <Route path="/show/excel">
                    <ExcelPage/>
                </Route>
                <Route path="/show/category">
                    <CategoryPage/>
                </Route>
                <Route path="/show/product">
                    <ProductPage/>
                </Route>
                <Route path="/show/promo">
                    <PromoPage/>
                </Route>
                <Route path="/show/bill">
                    <BillPage/>
                </Route>
                <Route path="/show/phonecall">
                    <PhoneCallPage/>
                </Route>
                <Route path="/show/user">
                    <UserPage/>
                </Route>
                <Route path="/show/umanager">
                    <UManagerPage/>
                </Route>
            </Switch>
        </div>;
    }

    redirectTo(){
        if (!this.props.user.token){
            return <Redirect to="/login"/>;
        }

        return <Redirect to="/show"/>;
    }

    render(){

        return <Fragment>
            { this.redirectTo() }
            <Switch>
                <Route path="/login">
                    <LoginPage/>
                </Route>
                <Route path="/show">
                    <Layout className="mainLayout">
                        <Sider className="layoutSider" width={260}>
                            { this.renderMenu() }
                        </Sider>
                        <Layout className="layoutRight">
                            <Header className="layoutHeader">
                                { this.renderHeader() }
                            </Header>
                            <Content className="layoutContent">
                                { this.renderContent() }
                            </Content>
                        </Layout>
                        <CitySelector/>
                        <ProductGroupSelector/>
                        <CategoryGroupSelector/>
                        <CategorySelector/>
                        <ProductSelector/>
                    </Layout>
                </Route>
            </Switch>
        </Fragment>
    }

}

const mapStateToProps = (state) => {
    return {
        userRole: state.user.token?.userRole,
        user: state.user
    }
}

function ML(params) {
    let location = useLocation();
    var prms = {
        ...params,
        pathname: location.pathname
    }

    return <MainLayout { ...prms }/>
}

const ppp = ML;

//export default compose(
//    withRouter,
//    connect(mapStateToProps, { logout })
//)(MainLayout);

export default connect(
    mapStateToProps,
    { logout }
)(ppp);