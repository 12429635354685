import {
    REQUEST_SHOPS,
    RECEIVE_SHOPS,
    CREATE_SHOP,
    MODIFY_SHOP,
    ON_DELETE_SHOP,
    AFT_DELETE_SHOP,
    ON_SAVE_SHOP,
    AFT_SAVE_SHOP,
    CLOSE_SHOP_EDIT,
    SELECT_SHOP,
    SHOP_MODIFIED
} from '../ActionTypes/shopActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

const removeEmpty = obj => {
    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
};

export const shopModified = (modified) => ({
    type: SHOP_MODIFIED,
    payload: { modified: modified }
})

export const selectShop = shopId => dispatch => {
    dispatch({ type: SELECT_SHOP, payload: shopId });
}

export const receiveShops = (shops, error) => dispatch => {
    dispatch({ type: RECEIVE_SHOPS, payload: { shops: shops, error: error } });
}

export const requestShops = () => dispatch => {

    dispatch({ type: REQUEST_SHOPS, payload: null });

    axios.get( new URL("shop", API_URL).toString() )
    .then((response) => {
        dispatch(receiveShops(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveShops(null, error));
        }
    });
}

export const createShop = () => dispatch => {
    dispatch(selectShop(-1));
    dispatch({ type: CREATE_SHOP, payload: null });
    dispatch(shopModified(false));
}

export const modifyShop = shop => dispatch => {
    dispatch(selectShop(shop.id));
    dispatch({ type: MODIFY_SHOP, payload: shop });
    dispatch(shopModified(false));
}

export const closeShopEdit = () => dispatch => {
    dispatch(selectShop(-1));
    dispatch({ type: CLOSE_SHOP_EDIT, payload: null });
    dispatch(shopModified(false));
}

export const aftDeleteShop = error => dispatch => {
    dispatch({ type: AFT_DELETE_SHOP, payload: { error: error } });
    dispatch(closeShopEdit());
    dispatch(requestShops());
}

export const onDeleteShop = shop => (dispatch, getState) => {
    dispatch({ type: ON_DELETE_SHOP, payload: null });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "shop/" + shop.id.toString(),
        API_URL
    );

    axios.delete( 
        url.toString(), 
        { headers: headers } 
    )
    .then((response) => {
        dispatch(aftDeleteShop(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteShop(error));
        }
    });
}

export const aftSaveShop = (shopId, shop, error, mode) => dispatch => {
    dispatch({
        type: AFT_SAVE_SHOP,
        payload: {
            shopId: shopId,
            error: error,
            mode: mode
        }
    });


    dispatch(requestShops());

    var newShop = shop;
    if (shopId != -1)
        newShop.id = shopId;
    dispatch(modifyShop(newShop));
}

export const onSaveShop = (shop, mode) => (dispatch, getState) => {

    dispatch({ type: ON_SAVE_SHOP, payload: null });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("shop", API_URL);
    if (shop.city && shop.city.id){
        url.searchParams.set('cityId', shop.city.id);
    }

    var clean = shop;

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                clean,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                clean,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSaveShop(-1, shop, null, mode));
        else
            dispatch(aftSaveShop(parseInt(response.data), shop, null, mode));
    }).catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveShop(null, error, mode));
        }
    })
}