export const REQUEST_ROOT_PGROUP = "REQUEST_ROOT_PGROUP";
export const RECEIVE_ROOT_PGROUP = "RECEIVE_ROOT_PGROUP";

export const CREATE_PGROUP = "CREATE_PGROUP";
export const MODIFY_PGROUP = "MODIFY_PGROUP";
export const CLOSE_PGROUP_EDIT = "CLOSE_PGROUP_EDIT";

export const ON_DELETE_PGROUP = "ON_DELETE_PGROUP";
export const AFT_DELETE_PRGOUP = "AFT_DELETE_PGROUP";

export const ON_SAVE_PGROUP = "ON_SAVE_PGROUP";
export const AFT_SAVE_PGROUP = "AFT_SAVE_PGROUP";

export const SELECT_PGROUP = "SELECT_PGROUP";

export const PGROUP_MODIFIED = "PGROUP_MODIFIED";

export const PGROUP_SET_PARENT_ID = "PGROUP_SET_PARENT_ID";

export const ON_PGROUP_SET_SMALL_IMG = "ON_PGROUP_SET_SMALL_IMG";
export const AFT_PGROUP_SET_SMALL_IMG = "AFT_PGROUP_SET_SMALL_IMG";

export const ON_PGROUP_DEL_SMALL_IMG = "ON_PGROUP_DEL_SMALL_IMG";
export const AFT_PGROUP_DEL_SMALL_IMG = "AFT_PGROUP_DEL_SMALL_IMG";