export const REQUEST_SHOPS = 'REQUEST_SHOPS';
export const RECEIVE_SHOPS = 'RECEIVE_SHOPS';

export const CREATE_SHOP = 'CREATE_SHOP';
export const MODIFY_SHOP = 'MODIFY_SHOP';
export const CLOSE_SHOP_EDIT = 'CLOSE_SHOP_EDIT';

export const ON_DELETE_SHOP = 'ON_DELETE_SHOP';
export const AFT_DELETE_SHOP = 'AFT_DELETE_SHOP';

export const ON_SAVE_SHOP = 'ON_SAVE_SHOP';
export const AFT_SAVE_SHOP = 'AFT_SAVE_SHOP';

export const SELECT_SHOP = 'SELECT_SHOP';

export const SHOP_MODIFIED = "SHOP_MODIFIED";