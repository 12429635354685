export const sortByOrderAndName = (item1, item2) => {

    if (item1.order == null || item2.order == null){
        if (item1.order != null && item2.order == null)
            return -1;
        if (item1.order == null && item2.order != null)
            return 1;
    }

    if (item1.order > item2.order) return -1;
    if (item1.order < item2.order) return 1;

    if (item1.name == null || item2.name == null){
        if (item1.name != null && item2.name == null)
            return -1;
        if (item1.name == null && item2.name != null)
            return 1;
    }

    if (item1.name > item2.name) return -1;
    if (item1.name < item2.name) return 1;
}