import React, { Fragment } from 'react';
import { Card, Spin, List, Empty, Button } from 'antd';

import { requestCities, createCity, modifyCity, selectCity } from '../../Redux/Actions/cityActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

import './CityList.less';

class CityList extends React.Component{
    constructor(props){
        super(props);

        this.onCreateCity = this.onCreateCity.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onCreateCity(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите сменить город? При смене внесённые изменения не будут сохранены!")){
            return;
        }
        this.props.createCity();
    }

    onCityClick(city){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите сменить город? При смене внесённые изменения не будут сохранены!")){
            return;
        }

        this.props.modifyCity(city);
        console.log("click " + city.id);
        this.forceUpdate();
    }

    renderList(){
        if (this.props.cityList == null || this.props.cityList.list == []){
            return <Empty description="Список городов пуст"/>
        }

        return <Scroll maxHeight="500" minHeight="100">
            <List
                itemLayout="horizontal"
                dataSource={this.props.cityList}
                renderItem={item => (
                    <List.Item 
                        onClick={ (e) => this.onCityClick(item) } 
                        className={ (this.props.selCity == item.id) ? `selectedItem` : `` }
                    >
                        <List.Item.Meta
                            title={ <span>{ ('name' in item) ? item.name : 'Безымянный город' }</span> }
                            description= { <span>{ ('region' in item) ? item.region : 'Регион не указан' }</span> }
                        />
                    </List.Item>
                )}
            />
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка..." spinning={this.props.isFetching}>
            <Card className="cityList">
                {this.renderList()}
                {
                    this.hasEditorRights() &&
                    <Button type="primary" onClick={this.onCreateCity} block>Добавить город</Button>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    cityList: state.city.cities_list,
    selCity: state.city.selected_city,
    isFetching: state.city.isFetching,
    editor: state.city.city_editor
});

export default connect(
    mapStateToProps,
    { requestCities, createCity, modifyCity, selectCity }
)(CityList);