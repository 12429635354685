import React, { Fragment } from 'react';
import { Card, Input, Space, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { login } from '../../Redux/Actions/userActions';

import './LoginPage.less';

class LoginPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: '',
        }

        this.onLogin = this.onLogin.bind(this);
        this.onChangeLogin = this.onChangeLogin.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    onLogin(e){
        this.props.login(this.state);
    }

    onChangeLogin(e){
        this.setState({ ...this.state, username: e.target.value });
    }

    onChangePassword(e){
        this.setState({ ...this.state, password: e.target.value });
    }

    showErr(){
        if (this.props.user.token_err)
            return <Alert
                type="error"
                message="Ошибка входа"
                description="Проверьте логин и пароль"
            />
        else
            return <Fragment/>
    }

    render(){
        return <div class="loginPage">
            <Card className="loginBlock">
                <Space direction='vertical'>
                    <span class="info">Вход в систему</span>
                    { this.showErr() }
                    <Input size="large" placeholder="Логин" prefix={<UserOutlined />}
                        onChange={this.onChangeLogin} value={this.state.username}
                    />
                    <Input type="password" size="large" placeholder="Пароль" prefix={<LockOutlined />}
                        onChange={this.onChangePassword} value={this.state.password}
                    />
                    <Button type="primary" size="large" block onClick={this.onLogin}>Войти в систему</Button>
                </Space>
            </Card>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps, 
    { login }
)(LoginPage);