import {
    ON_PRODUCT_GROUP_SELECTION,
    AFT_PRODUCT_GROUP_SELECTION,
    ON_PRODUCT_GROUP_SELECTION_REQUEST,
    ON_PRODUCT_GROUP_SELECTION_RECEIVE,
    ON_PRODUCT_GROUP_SELECTION_SELECT,
    ON_PRODUCT_GROUP_SELECTION_CLOSE
} from '../ActionTypes/productGroupSelectorActionTypes';

const initialState = {
    visible: false,
    error: null,
    isFetching: false,
    rootGroup: null,
    restrictedId: -1,
    selectedId: -1,
    selectedItem: null,
    selectHandler: null
}

export default function(state = initialState, action){
    switch (action.type){
        case ON_PRODUCT_GROUP_SELECTION: return {
            ...state,
            visible: true,
            error: null,
            isFetching: true,
            selectHandler: action.payload.selectHandler,
            restrictedId: action.payload.restrictedId
        };
        case AFT_PRODUCT_GROUP_SELECTION: return initialState;
        case ON_PRODUCT_GROUP_SELECTION_REQUEST: return {
            ...state,
            error: null,
            isFetching: true
        };
        case ON_PRODUCT_GROUP_SELECTION_RECEIVE: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            rootGroup: action.payload.rootGroup
        };
        case ON_PRODUCT_GROUP_SELECTION_SELECT: return {
            ...state,
            selectedId: action.payload.selectedId,
            selectedItem: action.payload.selectedItem
        };
        case ON_PRODUCT_GROUP_SELECTION_CLOSE: return initialState;
        default: return state;
    }
}