import {
    REQUEST_ROOT_PGROUP,
    RECEIVE_ROOT_PGROUP,
    CREATE_PGROUP,
    MODIFY_PGROUP,
    CLOSE_PGROUP_EDIT,
    ON_DELETE_PGROUP,
    AFT_DELETE_PRGOUP,
    ON_SAVE_PGROUP,
    AFT_SAVE_PGROUP,
    SELECT_PGROUP,
    PGROUP_MODIFIED,
    PGROUP_SET_PARENT_ID,
    ON_PGROUP_SET_SMALL_IMG,
    AFT_PGROUP_SET_SMALL_IMG,
    ON_PGROUP_DEL_SMALL_IMG,
    AFT_PGROUP_DEL_SMALL_IMG
} from '../ActionTypes/productGroupActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

const removeEmpty = obj => {
    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
}

function findInTreeById(group, groupId){
    if (group.id == groupId)
        return group;

    if (!group.children)
        return null;

    var childInd = group.children.map(child => child.id).indexOf(groupId);
    if (childInd != -1)
        return group.children[childInd];

    var inGroups = group.children.map(child => findInTreeById(child, groupId));

    if (inGroups.length == 0)
        return null;

    var notNull = inGroups.filter(inGroup => inGroup != null);
    if (notNull.length == 0)
        return null;
    return notNull[0];
}

export const pgroupSetParentId = parentGroupId => ({
    type: PGROUP_SET_PARENT_ID,
    payload: { parentGroupId: parentGroupId }
})

export const pgroupModified = modified => dispatch => {
    dispatch({ type: PGROUP_MODIFIED, payload: { modified: modified } });
}

export const selectPgroup = selectedGroupId => dispatch => {
    dispatch({ type: SELECT_PGROUP, payload: { selectedGroupId: selectedGroupId } });
}

export const receiveRootPgroup = (rootGroup, error, aftMod = -1) => (dispatch, getState) => {
    dispatch({ 
        type: RECEIVE_ROOT_PGROUP, 
        payload: { 
            rootGroup: rootGroup,
            error: error
        }
    });

    if (aftMod != -1){
        var root = getState().productGroup.rootGroup;
        var selected = findInTreeById(root, aftMod);
        selected = Object.assign({}, selected);
        if (selected != null)
            dispatch(modifyPgroup(selected, selected.parentGroup.id));
    }
}

export const requestRootGroup = (aftMod = -1) => dispatch => {
    dispatch({ type: REQUEST_ROOT_PGROUP, payload: null });

    var url = new URL("productgroup/root", API_URL);
    url.searchParams.set('allChildren', 'true');
    url.searchParams.set('includeAllChildrenData', 'true');
    url.searchParams.set('includeFirstParent', 'true');

    axios.get(url.toString())
    .then((response) => {
        dispatch(receiveRootPgroup(response.data, null, aftMod));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveRootPgroup(null, error, aftMod));
        }
    });
}

export const createPgroup = (parentGroupId, parentGroup) => dispatch => {
    dispatch(selectPgroup(-1));
    dispatch(pgroupModified(false));
    dispatch({ 
        type: CREATE_PGROUP, 
        payload: {
            parentGroupId: parentGroupId,
            parentGroup: { id: parentGroup.id, name: parentGroup.name }
        }
    });
}

export const modifyPgroup = (productGroup, parentGroupId) => dispatch => {
    dispatch(selectPgroup(productGroup.id));
    dispatch(pgroupModified(false));
    dispatch({
        type: MODIFY_PGROUP,
        payload: {
            productGroup: productGroup,
            parentGroupId: parentGroupId
        }
    });
}

export const closePgroupEdit = () => dispatch => {
    dispatch(selectPgroup(-1));
    dispatch(pgroupModified(false));
    dispatch({
        type: CLOSE_PGROUP_EDIT,
        payload: null
    });
}

export const aftDeletePgroup = error => dispatch => {
    dispatch({ type: AFT_DELETE_PRGOUP, payload: { error: error } });
    dispatch(closePgroupEdit());
    dispatch(requestRootGroup());
}

export const onDeletePgroup = productGroup => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_PGROUP,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "productgroup/" + productGroup.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeletePgroup(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeletePgroup(error));
        }
    });
}

export const aftSavePgroup = (productGroupId, productGroup, parentGroupId, error, mode) => dispatch => {
    dispatch({
        type: AFT_SAVE_PGROUP,
        payload: {
            productGroupId: productGroupId,
            error: error,
            mode: mode
        }
    });

    dispatch(requestRootGroup());

    var newGroup = productGroup;
    if (productGroupId != -1)
        newGroup.id = productGroupId;
    dispatch(modifyPgroup(newGroup, parentGroupId));
}

export const onSavePgroup = (productGroup, parentGroupId, mode) => (dispatch, getState) => {
    dispatch({ type: ON_SAVE_PGROUP, payload: null });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("productGroup", API_URL);
    url.searchParams.set('parentGroupId', parentGroupId);

    var clean = productGroup;

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                clean,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                clean,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSavePgroup(-1, productGroup, parentGroupId, null, mode));
        else
            dispatch(aftSavePgroup(parseInt(response.data), productGroup, parentGroupId, null, mode));
    }).catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSavePgroup(-1, null, parentGroupId, error, mode));
        }
    })
}

export const aftPgroupSetSmallImg = (productGroupId, error) => (dispatch) => {
    dispatch({
        type: AFT_PGROUP_SET_SMALL_IMG,
        payload: { error: error }
    });

    if (error == null)
        dispatch(requestRootGroup(productGroupId));
}

export const onPgroupSetSmallImg = (productGroupId, file) => (dispatch, getState) => {
    dispatch({
        type: ON_PGROUP_SET_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`productGroup/${ productGroupId.toString() }/smallimg`, API_URL);

    var formData = new FormData();
    formData.append("img", file);
    
    axios.post(
        url.toString(),
        formData,
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftPgroupSetSmallImg(productGroupId, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftPgroupSetSmallImg(productGroupId, error));
        }
    });
}

export const aftPgroupDelSmallImg = (productGroupId, error) => (dispatch) => {
    dispatch({
        type: AFT_PGROUP_DEL_SMALL_IMG,
        payload: { error: error }
    });

    if (error == null)
        dispatch(requestRootGroup(productGroupId));
}

export const onPGroupDelSmallImg = (productGroupId) => (dispatch, getState) => {
    dispatch({
        type: ON_PGROUP_DEL_SMALL_IMG,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(`productGroup/${ productGroupId.toString() }/smallimg`, API_URL);
    
    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftPgroupDelSmallImg(productGroupId, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftPgroupDelSmallImg(productGroupId, error));
        }
    });
}