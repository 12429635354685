import {
    ON_CATEGORY_SELECTION,
    AFT_CATEGORY_SELECTION,
    ON_CATEGORY_SELECTION_REQUEST,
    ON_CATEGORY_SELECTION_RECEIVE,
    ON_CATEGORY_SELECTION_SELECT,
    ON_CATEGORY_SELECTION_CLOSE
} from '../ActionTypes/categorySelectorActionTypes';

const initialState = {
    visible: false,
    error: null,
    isFetching: false,
    categoryGroups: null,
    selectedId: -1,
    selectedItem: null,
    selectHandler: null
}

export default function(state = initialState, action){
    switch (action.type){
        case ON_CATEGORY_SELECTION: return {
            ...state,
            visible: true,
            error: null,
            isFetching: true,
            selectHandler: action.payload.selectHandler
        };
        case AFT_CATEGORY_SELECTION: return initialState;
        case ON_CATEGORY_SELECTION_REQUEST: return {
            ...state,
            error: null,
            isFetching: true
        }
        case ON_CATEGORY_SELECTION_RECEIVE: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            categoryGroups: action.payload.categoryGroups
        }
        case ON_CATEGORY_SELECTION_SELECT: return {
            ...state,
            selectedId: action.payload.selectedId,
            selectedItem: action.payload.selectedItem
        }
        case ON_CATEGORY_SELECTION_CLOSE: return initialState;
        default: return state;
    }
}