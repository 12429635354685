export const REQUEST_CITIES = "REQUEST_CITIES";
export const RECEIVE_CITIES = "RECEIVE_CITIES";

export const CREATE_CITY = "CREATE_CITY";
export const MODIFY_CITY = "MODIFY_CITY";
export const CLOSE_CITY_EDIT = "CLOSE_CITY_EDIT";

export const ON_DELETE_CITY = "ON_DELETE_CITY";
export const AFT_DELETE_CITY = "AFT_DELETE_CITY";

export const ON_SAVE_CITY = "ON_SAVE_CITY";
export const AFT_SAVE_CITY = "AFT_SAVE_CITY";

export const SELECT_CITY = "SELECT_CITY";

export const CITY_MODIFIED = "CITY_MODIFIED";