import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs, Select, Empty, Space, DatePicker, InputNumber } from 'antd';
import moment from 'moment';

import {
    onDeleteUser,
    onSaveUser,
    closeUserEdit,
    userModified
} from '../../Redux/Actions/uManagerActions';
import { connect } from 'react-redux';

import './UManagerEditor.less';

const { TabPane } = Tabs;
const { Option } = Select;

class UManagerEditor extends React.Component{

    aftModify = false;

    initialState = {
        userId: 0,
        userName: "",
        userRole: "Moderator",
        nPassword: null,
        nPassCopy: null
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...props.editor.item
        }

        this.onSave = this.onSave.bind(this);
        this.onDelete =this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangeUserRole = this.onChangeUserRole.bind(this);

        this.onChangeNPassword = this.onChangeNPassword.bind(this);
        this.onChangeNPassCopy = this.onChangeNPassCopy.bind(this);
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.item,
        });
    }

    getRoleOrder(role){
        switch (role){
            case 'Root': return 0;
            case 'Admin': return 1;
            case 'Moderator': return 2;
        }
    }

    onSave(e){
        if (this.state.userName == ""){
            window.alert("Введите имя пользователя");
            return;
        }
        if (this.getRoleOrder(this.state.userRole) < this.getRoleOrder(this.props.userRole)){
            window.alert("Вы не имеете прав для выдачи таких полномочий");
            return;
        }
        if (this.props.editor.mode == 'create'){
            if (this.state.nPassword == null){
                window.alert("Укажите пароль пользователя!");
                return;
            }
        }
        if (this.state.nPassword != null){
            if (this.state.nPassword != this.state.nPassCopy){
                window.alert("Пароли не совпадают!");
                return;
            }
        }
        this.props.onSaveUser(this.state, this.props.editor.mode);
        this.setState({
            ...this.state,
            nPassword: null,
            nPassCopy: null
        })
    }

    onDelete(e){
        if (!window.confirm(`Вы действительно хотите удалить выбранный звонок?`))
            return;

        this.props.onDeleteUser(this.state);
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.closeUserEdit();
    }

    onChangeUserName(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            userName: e.target.value
        });
        this.props.userModified(true);
    }

    onChangeUserRole(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            userRole: e
        });
        this.props.userModified(true);
    }

    onChangeNPassword(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            nPassword: (e.target.value == "") ? null : e.target.value
        });
        this.props.userModified(true);
    }

    onChangeNPassCopy(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            nPassCopy: (e.target.value == "") ? null : e.target.value
        });
        this.props.userModified(true);
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание пользователя';
                break;
            case 'edit':
                title_text = 'Изменение пользователя';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null: case 'create':
                return <Fragment/>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button"
                    onClick={this.onDelete}
                >
                    Удалить пользователя
                </Button>;
        }
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span>Логин:</span>
                    <Input value={this.state.userName} onChange={this.onChangeUserName}/>
                </div>
                <div class="inpLine">
                    <span>Роль:</span>
                    <Select
                        value={this.state.userRole}
                        onChange={this.onChangeUserRole}
                        style={{ width: "100%" }}
                    >
                        <Option value="Root">Root</Option>
                        <Option value="Admin">Admin</Option>
                        <Option value="Moderator">Moderator</Option>
                    </Select>
                </div>
            </div>
        </TabPane>
    }

    renderPassTab(){
        return <TabPane tab="Новый пароль" key = "2">
        <div class="mainTab">
            <div class="inpLine">
                <span>Новый пароль:</span>
                <Input type="password" value={this.state.nPassword} onChange={this.onChangeNPassword}/>
            </div>
            <div class="inpLine">
                <span>Ещё раз:</span>
                <Input type="password" value={this.state.nPassCopy} onChange={this.onChangeNPassCopy}/>
            </div>
        </div>
        </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            {this.renderMainTab()}
            {this.renderPassTab()}
        </Tabs>
    }

    render(){
        if (this.props.editor.mode == null)
            return <Fragment/>;

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="uManagerEditor" title={this.getCardTitle()}>
                {this.renderTabs()}
                <div class="btnLine">
                    <Space direction="horizontal">
                        <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                        {this.getDeleteButton()}
                    </Space>
                </div>
            </Card>
        </Spin>
    }

}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.uManager.editor,
    isFetching: state.uManager.isFetching
});

export default connect(
    mapStateToProps,
    {
        onDeleteUser, onSaveUser,
        closeUserEdit, userModified
    }
)(UManagerEditor);