import {
    ON_CATEGORY_GROUP_SELECTION,
    AFT_CATEGORY_GROUP_SELECTION,
    ON_CATEGORY_GROUP_SELECTION_REQUEST,
    ON_CATEGORY_GROUP_SELECTION_RECEIVE,
    ON_CATEGORY_GROUP_SELECTION_SELECT,
    ON_CATEGORY_GROUP_SELECTION_CLOSE
} from '../ActionTypes/categoryGroupSelectorActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const onCategoryGroupSelectionSelect = (selectedItem, selectedId) => ({
    type: ON_CATEGORY_GROUP_SELECTION_SELECT,
    payload: {
        selectedItem: selectedItem,
        selectedId: selectedId
    }
});

export const onCategoryGroupSelectionReceive = (categoryGroups, error) => dispatch => {
    dispatch({
        type: ON_CATEGORY_GROUP_SELECTION_RECEIVE,
        payload: {
            categoryGroups: categoryGroups,
            error: error
        }
    });
    dispatch(onCategoryGroupSelectionSelect(null, -1));
}

export const onCategoryGroupSelectionRequest = () => dispatch => {
    dispatch({
        type: ON_CATEGORY_GROUP_SELECTION_REQUEST,
        payload: null
    });

    var url = new URL("categorygroup", API_URL);
    url.searchParams.set('includeCategories', 'false');
    
    axios.get(url.toString())
    .then((response) => {
        dispatch(onCategoryGroupSelectionReceive(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(onCategoryGroupSelectionReceive(null, error));
        }
    });
}

export const onCategoryGroupSelectionClose = () => ({
    type: ON_CATEGORY_GROUP_SELECTION_CLOSE,
    payload: null
});

export const onCategoryGroupSelection = (selectHandler) => dispatch => {
    dispatch({
        type: ON_CATEGORY_GROUP_SELECTION,
        payload: {
            selectHandler: selectHandler
        }
    });
    dispatch(onCategoryGroupSelectionRequest());
}

export const aftCategoryGroupSelection = () => (dispatch, getState) => {
    var handler = getState().categoryGroupSelector.selectHandler;
    var selectedItem = getState().categoryGroupSelector.selectedItem;

    dispatch({
        type: AFT_CATEGORY_GROUP_SELECTION,
        payload: null
    });

    if (handler != null && selectedItem !== undefined)
        handler(selectedItem);
}