import React from 'react';
import { Card, Spin, List, Empty, Button } from 'antd';
import Scroll from '../../Utils/Scroll/Scroll.js';

import {
    requestUsers,
    createUser,
    modifyUser,
    selectUser
} from '../../Redux/Actions/uManagerActions';
import { connect } from 'react-redux';

import './UManagerList.less';

class UManagerList extends React.Component{
    constructor(props){
        super(props);

        this.onCreate = this.onCreate.bind(this);
        this.onModify = this.onModify.bind(this);
    }

    onCreate(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.createUser();
    }

    onModify(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.modifyUser(e);
    }

    renderList(){
        if (this.props.itemList == null || this.props.itemList == []){
            return <Empty description="Список промоакций пуст"/>
        }

        return <Scroll maxHeight="500" minHeight="100">
            <List
                itemLayout="horizontal"
                dataSource={this.props.itemList}
                renderItem={ item =>
                    <List.Item
                        onClick={ e => this.onModify(item) }
                        className={ (this.props.selectedId == item.userId) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            title={ <span>{ ('userName' in item && item.userName != "") ? item.userName : 'Безымянный' }</span> }
                            description={ <span>{ ('userRole' in item && item.userRole != "") ? item.userRole : 'Без роли' }</span> }
                        />
                    </List.Item>
                }
            />
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка..." spinning={this.props.isFetching}>
            <Card className="promoList">
                {this.renderList()}
                <Button type="primary" onClick={this.onCreate} block>Добавить пользователя</Button>
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    itemList: state.uManager.itemList,
    selectedId: state.uManager.selectedId,
    isFetching: state.uManager.isFetching,
    editor: state.uManager.editor
});

export default connect(
    mapStateToProps,
    { requestUsers, createUser, modifyUser, selectUser }
)(UManagerList);