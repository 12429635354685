export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";

export const CREATE_USER = "CREATE_USER";
export const MODIFY_USER = "MODIFY_USER";
export const CLOSE_USER_EDIT = "CLOSE_USER_UDIT";

export const ON_DELETE_USER = "ON_DELETE_USER";
export const AFT_DELETE_USER = "AFT_DELETE_USER";

export const ON_SAVE_USER = "ON_SAVE_USER";
export const AFT_SAVE_USER = "AFT_SAVE_USER";

export const SELECT_USER = "SELECT_USER";
export const USER_MODIFIED = "USER_MODIFIED";

export const RESET_UMANAGER_ERROR = "RESET_UMANAGER_ERROR";