import React, { Fragment } from 'react';
import CityEditor from '../CityEditor/CityEditor.js';
import CityList from '../CityList/CityList.js';
import { requestCities } from '../../Redux/Actions/cityActions';
import { Alert, Space } from 'antd';


import './CitiesPage.less';
import { connect } from 'react-redux';

class CitiesPage extends React.Component{
    
    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.requestCities();
    }

    showErr(){
        if (this.props.error)
            return <Alert
                type="error"
                message="Внутренняя ошибка"
                description="Ошибка при выполнении операции с городами"
            />
        else
            return <Fragment/>
    }

    render(){
        return <div class="citiesPage">
            <Space direction='vertical'>
                { this.showErr() }
                <CityList/>
                <CityEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.city.error,
    editor: state.city.city_editor
});

export default connect(
    mapStateToProps,
    { requestCities }
)(CitiesPage);