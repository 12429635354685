import {
    REQUEST_PROMOS,
    RECEIVE_PROMOS,
    CREATE_PROMO,
    MODIFY_PROMO,
    CLOSE_PROMO_EDIT,
    ON_DELETE_PROMO,
    AFT_DELETE_PROMO,
    ON_SAVE_PROMO,
    AFT_SAVE_PROMO,
    SELECT_PROMO,
    PROMO_MODIFIED,
    ON_SAVE_PROMO_SMALL_IMG,
    AFT_SAVE_PROMO_SMALL_IMG,
    ON_DELETE_PROMO_SMALL_IMG,
    AFT_DELETE_PROMO_SMALL_IMG,
    ON_ADD_PROMO_PRODUCT,
    AFT_ADD_PROMO_PRODUCT,
    ON_DELETE_PROMO_PRODUCT,
    AFT_DELETE_PROMO_PRODUCT
} from '../ActionTypes/promoActionTypes';

const initialState = {
    error: null,
    isFetching: false,
    promoList: null,
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: {}
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case REQUEST_PROMOS: return {
            ...state,
            error: null,
            isFetching: true
        }
        case RECEIVE_PROMOS: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            promoList: action.payload.promoList
        }
        case CREATE_PROMO: return {
            ...state,
            editor: {
                ...state.editor,
                mode: 'create',
                item: {}
            }
        }
        case MODIFY_PROMO: return {
            ...state,
            editor: {
                ...state.editor,
                mode: 'edit',
                item: action.payload.item
            }
        }
        case CLOSE_PROMO_EDIT: return {
            ...state,
            editor: initialState.editor
        }
        case ON_DELETE_PROMO: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_DELETE_PROMO: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        }
        case ON_SAVE_PROMO: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_SAVE_PROMO: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        }
        case SELECT_PROMO: return {
            ...state,
            selectedId: action.payload.selectedId
        }
        case PROMO_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        }
        case ON_SAVE_PROMO_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_SAVE_PROMO_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    image: (action.payload.newImg == null)
                        ? state.editor.item.image
                        : action.payload.newImg
                }
            }
        }
        case ON_DELETE_PROMO_SMALL_IMG: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_DELETE_PROMO_SMALL_IMG: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    image: (action.payload.newImg == null)
                        ? state.editor.item.image
                        : action.payload.newImg
                }
            }
        }
        case ON_ADD_PROMO_PRODUCT: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_ADD_PROMO_PRODUCT: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    products: (action.payload.newProducts == null)
                        ? state.editor.item.products
                        : action.payload.newProducts
                }
            }
        }
        case ON_DELETE_PROMO_PRODUCT: return {
            ...state,
            error: null,
            isFetching: true
        }
        case AFT_DELETE_PROMO_PRODUCT: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            editor: {
                ...state.editor,
                item: {
                    ...state.editor.item,
                    products: (action.payload.newProducts == null)
                        ? state.editor.item.products
                        : action.payload.newProducts
                }
            }
        }
        default: return state;
    }
}