import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import { requestRootGroup } from '../../Redux/Actions/productGroupActions';
import { connect } from 'react-redux';

import ProductGroupTree from '../ProductGroupTree/ProductGroupTree.js';
import ProductGroupEditor from '../ProductGroupEditor/ProductGroupEditor.js';

import './ProductGroupPage.less';

class ProductGroupPage extends React.Component{

    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.requestRootGroup();
    }

    showErr(){
        if (this.props.error)
            return <Alert
                type="error"
                message="Внутренняя ошибка"
                description="Ошибка при выполнении операции с группами магазинов"
            />
        else
            return <Fragment/>
    }

    render(){
        return <div class="productGroupPage">
            <Space direction="vertical">
                { this.showErr() }
                <ProductGroupTree/>
                <ProductGroupEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.productGroup.error
});

export default connect(
    mapStateToProps,
    { requestRootGroup }
)(ProductGroupPage);