export const REQUEST_CATEGORY_GROUPS = "REQUEST_CATEGORY_GROUPS";
export const RECEIVE_CATEGORY_GROUPS = "RECEIVE_CATEGORY_GROUPS";

export const CREATE_CATEGORY_GROUP = "CREATE_CATEGORY_GROUP";
export const MODIFY_CATEGORY_GROUP = "MODIFY_CATEGORY_GROUP";
export const CLOSE_CATEGORY_GROUP_EDIT = "CLOSE_CATEGORY_GROUP_EDIT";

export const ON_SAVE_CATEGORY_GROUP = "ON_SAVE_CATEGORY_GROUP";
export const AFT_SAVE_CATEGORY_GROUP = "AFT_SAVE_CATEGORY_GROUP";
export const ON_DELETE_CATEGORY_GROUP = "ON_DELETE_CATEGORY_GROUP";
export const AFT_DELETE_CATEGORY_GROUP = "AFT_DELETE_CATEGORY_GROUP";

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const MODIFY_CATEGORY = "MODIFY_CATEGORY";
export const CLOSE_CATEGORY_EDIT = "CLOSE_CATEGORY_EDIT";

export const ON_SAVE_CATEGORY = "ON_SAVE_CATEGORY";
export const AFT_SAVE_CATEGORY = "AFT_SAVE_CATEGORY";
export const ON_DELETE_CATEGORY = "ON_DELETE_CATEGORY";
export const AFT_DELETE_CATEGORY = "AFT_DELETE_CATEGORY";

export const SELECT_CATEGORY_ITEM = "SELECT_CATEGORY_ITEM";
export const CATEGORY_ITEM_MODIFIED = "CATEGORY_ITEM_MODIFIED";