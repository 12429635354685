import {
    ON_PRODUCT_SELECTION,
    AFT_PRODUCT_SELECTION,
    ON_PRODUCT_SELECTION_REQUEST,
    ON_PRODUCT_SELECTION_RECEIVE,
    ON_PRODUCT_SELECTION_SET_FILTER,
    ON_PRODUCT_SELECTION_SET_PAGING,
    ON_PRODUCT_SELECTION_PAGINATE,
    ON_PRODUCT_SELECTION_SELECT,
    ON_PRODUCT_SELECTION_CLOSE
} from '../ActionTypes/productSelectorActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";


export const onProductSelectionSelect = (selectedItem, selectedId) => ({
    type: ON_PRODUCT_SELECTION_SELECT,
    payload: {
        selectedItem: selectedItem,
        selectedId: selectedId
    }
});

export const onProductSelectionSetFilter = (name, article) => dispatch => {
    dispatch({
        type: ON_PRODUCT_SELECTION_SET_FILTER,
        payload: {
            name: name,
            article: article
        }
    });
}

export const onProductSelectionSetPaging = (selectedPage, totalPages) => dispatch => {
    dispatch({
        type: ON_PRODUCT_SELECTION_SET_PAGING,
        payload: {
            selectedPage: selectedPage,
            totalPages: totalPages
        }
    });
}

export const onProductSelectionReceive = (productList, error, selectedPage, totalPages) => dispatch =>{
    dispatch({
        type: ON_PRODUCT_SELECTION_RECEIVE,
        payload: {
            error: error,
            productList: productList
        }
    });
    dispatch(onProductSelectionSetPaging(selectedPage, totalPages));
}

export const onProductSelectionRequest = () => (dispatch, getState) => {
    dispatch({
        type: ON_PRODUCT_SELECTION_REQUEST,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
    };

    var url = new URL("product/search", API_URL);

    var querry = {
        withCateg: false,
        article: getState().productSelector.filter.article,
        searchText: getState().productSelector.filter.name,
        pageNum: getState().productSelector.productListPaging.selectedPage,
        pageCount: 10,
        getParams: {
            smallImg: true,
            gallery: true,
            about: true,
            info: true,
            counts: true,
            group: true,
            filters: true
        }
    }

    axios.post(
        url.toString(),
        querry,
        { headers: headers }
    )
    .then((response) => {
        dispatch(onProductSelectionReceive(response.data.products, null, response.data.page, response.data.pages));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(onProductSelectionReceive(null, error, 0, 0));
        }
    })
}

export const onProductSelectionPaginate = (selectedPage) => dispatch => {
    dispatch({
        type: ON_PRODUCT_SELECTION_PAGINATE,
        payload: {
            selectedPage: selectedPage
        }
    });
    dispatch(onProductSelectionRequest());
}

export const onProductSelectionClose = () => ({
    type: ON_PRODUCT_SELECTION_CLOSE,
    payload: null
});

export const onProductSelection = (selectHandler) => dispatch => {
    dispatch({
        type: ON_PRODUCT_SELECTION,
        payload: {
            selectHandler: selectHandler
        }
    });
    dispatch(onProductSelectionRequest());
}

export const aftProductSelection = () => (dispatch, getState) => {
    var handler = getState().productSelector.selectHandler;
    var selectedItem = getState().productSelector.selectedItem;

    dispatch({
        type: AFT_PRODUCT_SELECTION,
        payload: null
    });

    if (handler != null && selectedItem !== undefined)
        handler(selectedItem);
}

//onProductSelection