import React from 'react';
import { Spin, Tree, Empty, Button, Modal } from 'antd';

import {
    onProductGroupSelection,
    aftProductGroupSelection,
    onProductGroupSelectionSelect,
    onProductGroupSelectionClose
} from '../../Redux/Actions/productGroupSelectorActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

import './ProductGroupSelector.less';

class ProductGroupSelector extends React.Component{
    constructor(props){
        super(props);

        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    onCancel(e){
        this.props.onProductGroupSelectionClose();
    }
    onOk(e){
        this.props.aftProductGroupSelection();
    }

    onCheck(checkedKeys, info){
        this.props.onProductGroupSelectionSelect(info.node, info.node.id);
    }

    renderTree(){
        if (this.props.rootGroup == null)
            return <Empty description="Не удалось загрузить дерево групп товаров"/>

        return <Scroll maxHeight="500" minHeight="100">
            <Spin tip="Загрузка..." spinning={this.props.isFetching}>
                <Tree
                    checkedKeys={ (this.props.selectedId == -1) ? [] : [ this.props.selectedId.toString() ]}
                    onCheck={this.onCheck}
                    treeData={[this.props.rootGroup]}
                    checkable={true}
                    showLine={true}
                    selectable={false}
                    checkStrictly={true}
                />
            </Spin>
        </Scroll>
    }

    render(){
        return <Modal
            visible={this.props.visible}
            title="Выбор группы товаров"
            confirmLoading={this.props.isFetching}
            className="productGroupSelector"
            footer={[
                <Button key="submit" type="primary" onClick={this.onOk} disabled={ this.props.selectedId == -1} >
                    Выбрать
                </Button>
            ]}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            {this.renderTree()}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    visible: state.productGroupSelector.visible,
    error: state.productGroupSelector.error,
    isFetching: state.productGroupSelector.isFetching,
    rootGroup: state.productGroupSelector.rootGroup,
    selectedId: state.productGroupSelector.selectedId
})

export default connect(
    mapStateToProps,
    { onProductGroupSelection, aftProductGroupSelection, onProductGroupSelectionSelect, onProductGroupSelectionClose }
)(ProductGroupSelector)