import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import {
    requestProducts 
} from '../../Redux/Actions/productActions';
import { connect } from 'react-redux';

import ProductList from '../ProductList/ProductList.js';
import ProductEditor from '../ProductEditor/ProductEditor.js';

import './ProductPage.less';

class ProductPage extends React.Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.requestProducts();
    }

    showErr(){
        if (this.props.error == null)
            return <Fragment/>

        return <Alert
            type="error"
            message="Внутренняя ошибка"
            description="Ошибка при выполнении операции с товаром"
        />
    }

    render(){
        return <div class="productPage">
            <Space direction='vertical'>
                {this.showErr()}
                <ProductList/>
                <ProductEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.product.error
});

export default connect(
    mapStateToProps,
    { requestProducts }
)(ProductPage);