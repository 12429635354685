import {
    ON_PRODUCT_SELECTION,
    AFT_PRODUCT_SELECTION,
    ON_PRODUCT_SELECTION_REQUEST,
    ON_PRODUCT_SELECTION_RECEIVE,
    ON_PRODUCT_SELECTION_SET_FILTER,
    ON_PRODUCT_SELECTION_SET_PAGING,
    ON_PRODUCT_SELECTION_PAGINATE,
    ON_PRODUCT_SELECTION_SELECT,
    ON_PRODUCT_SELECTION_CLOSE
} from '../ActionTypes/productSelectorActionTypes';

const initialState = {
    visible: false,
    error: null,
    isFetching: false,
    productList: null,
    selectedId: -1,
    selectedItem: null,
    selectHandler: null,
    filter: {
        article: null,
        name: null
    },
    productListPaging: {
        selectedPage: 0,
        totalPages: 0
    },
}

export default function(state = initialState, action){
    switch (action.type){
        case ON_PRODUCT_SELECTION: return {
            ...initialState,
            visible: true,
            selectHandler: action.payload.selectHandler
        }
        case AFT_PRODUCT_SELECTION: return initialState;
        case ON_PRODUCT_SELECTION_REQUEST: return {
            ...state,
            error: null,
            isFetching: true
        }
        case ON_PRODUCT_SELECTION_RECEIVE: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            productList: action.payload.productList
        }
        case ON_PRODUCT_SELECTION_SET_FILTER: return {
            ...state,
            filter: {
                name: action.payload.name,
                article: action.payload.article
            }
        };
        case ON_PRODUCT_SELECTION_SET_PAGING: return {
            ...state,
            productListPaging: {
                selectedPage: action.payload.selectedPage,
                totalPages: action.payload.totalPages
            }
        }
        case ON_PRODUCT_SELECTION_PAGINATE: return {
            ...state,
            productListPaging: {
                ...state.productListPaging,
                selectedPage: action.payload.selectedPage
            }
        }
        case ON_PRODUCT_SELECTION_SELECT: return {
            ...state,
            selectedId: action.payload.selectedId,
            selectedItem: action.payload.selectedItem
        }
        case ON_PRODUCT_SELECTION_CLOSE: return initialState;
        default: return state;
    }
}