import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router } from "react-router-dom";
import { store, persistor } from './Redux/store';

import MainLayout from './Layout/MainLayout.js';

function App() {
  return <Router>
    <Provider store={store}> 
      <PersistGate persistor={persistor}>
        <MainLayout/>
      </PersistGate>
    </Provider>
  </Router>;
}

export default App;