import React from 'react';

export default class Scroll extends React.Component{

    getStyle(){
        return {
            display: `flex`,
            flexDirection: `column`,
            alignItems: `stretch`,
            minHeight: (this.props.minHeight) ? `${ this.props.minHeight }px` : `500px`,
            maxHeight: (this.props.maxHeight) ? `${ this.props.maxHeight }px` : `500px`,
            overflowY: `auto`
        }
    }

    render(){
        return <div style={ this.getStyle() }>
            { this.props.children }
        </div>
    }

}