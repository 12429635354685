import React from 'react';
import { Spin, Tree, Empty, Button, Modal } from 'antd';

import {
    onCategoryGroupSelection,
    aftCategoryGroupSelection,
    onCategoryGroupSelectionSelect,
    onCategoryGroupSelectionClose
} from '../../Redux/Actions/categoryGroupSelectorActions';
import { connect } from 'react-redux';
import Scroll from '../../Utils/Scroll/Scroll.js';

class CategoryGroupSelector extends React.Component{
    constructor(props){
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    onCancel(e){
        this.props.onCategoryGroupSelectionClose();
    }

    onOk(e){
        this.props.aftCategoryGroupSelection();
    }

    onCheck(checkedKeys, info){
        this.props.onCategoryGroupSelectionSelect(info.node, info.node.id);
    }

    renderTree(){
        if (this.props.categoryGroups == null || this.props.categoryGroups.length == 0)
            return <Empty description="Не удалось найти ни одной группы категорий"/>

        return <Scroll maxHeight="500" minHeight="100">
            <Spin tip="Загрузка..." spinning={this.props.isFetching}>
                <Tree
                    checkedKeys={ (this.props.selectedId == -1) ? [] : [ this.props.selectedId.toString() ]}
                    onCheck={this.onCheck}
                    treeData={this.props.categoryGroups}
                    checkable={true}
                    showLine={true}
                    selectable={false}
                    checkStrictly={true}
                />
            </Spin>
        </Scroll>
    }

    render(){
        return <Modal
            visible={this.props.visible}
            title="Выбор группы категорий"
            confirmLoading={this.props.isFetching}
            className="productGroupSelector"
            footer={[
                <Button key="submit" type="primary" onClick={this.onOk} disabled={ this.props.selectedId == -1} >
                    Выбрать
                </Button>
            ]}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            {this.renderTree()}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    visible: state.categoryGroupSelector.visible,
    error: state.categoryGroupSelector.error,
    isFetching: state.categoryGroupSelector.isFetching,
    categoryGroups: state.categoryGroupSelector.categoryGroups,
    selectedId: state.categoryGroupSelector.selectedId
});

export default connect (
    mapStateToProps,
    { onCategoryGroupSelection, aftCategoryGroupSelection, onCategoryGroupSelectionSelect, onCategoryGroupSelectionClose }
)(CategoryGroupSelector);