import React, { Fragment } from 'react';
import { Input, Space, Checkbox, Button, Empty } from 'antd';

import './PhoneList.less';

export default class PhoneList extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            phones: (props.phones == null) ? [] : props.phones
        }
    }

    componentWillReceiveProps(newProps){
        if (newProps === this.props)
            return;

        this.setState({
            ...this.state,
            phones: (newProps.phones == null) ? [] : newProps.phones
        });
    }

    onNumberChange(index, value){
        var pt = this.state.phones;
        pt[index].number = value;
        this.setState({
            ...this.state,
            phones: pt
        });
        this.props.onModify(this.state.phones);
    }

    onViberChange(index, value){
        var pt = this.state.phones;
        pt[index].canViber = value;
        this.setState({
            ...this.state,
            phones: pt
        });
        this.props.onModify(this.state.phones);
    }

    onWhatsappChange(index, value){
        var pt = this.state.phones;
        pt[index].canWhatsApp = value;
        this.setState({
            ...this.state,
            phones: pt
        });
        this.props.onModify(this.state.phones);
    }

    onItemAdd(){
        var pt = this.state.phones;
        pt.push({ number: "", canViber: false, canWhatsApp: false });
        this.setState({
            ...this.state,
            phones: pt
        });
        this.props.onModify(this.state.phones);
    }

    onItemDelete(index){
        var pt = this.state.phones;
        pt.splice(index, 1);
        this.setState({
            ...this.state,
            phones: pt
        });
        this.props.onModify(this.state.phones);
    }

    renderList(){
        if (this.state.phones.length == 0)
            return <Empty description="Список пуст"/>;

        return <Space direction="vertical">
        {
            this.state.phones.map((item, index) => {
                return <Input size="large"
                    addonBefore={
                        <Fragment>
                            <Checkbox 
                                checked={item.canViber}
                                onChange={ e => this.onViberChange(index, e.target.checked) }
                                disabled={ this.props.disabled }
                            >Viber</Checkbox>
                            <Checkbox  
                                checked={item.canWhatsApp}
                                onChange={ e => this.onWhatsappChange(index, e.target.checked) }
                                disabled={ this.props.disabled }
                            >WhatsApp</Checkbox>
                        </Fragment>
                    }
                    addonAfter = {
                        !this.props.disabled &&
                        <Button danger type='link' onClick={ e => this.onItemDelete(index) }>Удалить</Button>
                    }
                    value={item.number}
                    onChange= { e => this.onNumberChange(index, e.target.value) }
                />
            })
        }
        </Space>
    }

    render(){
        return <div class="phoneList">
            { this.renderList() }
            {
                !this.props.disabled &&
                <Button type='primary' className="addBtn" onClick={ e => this.onItemAdd() }>Добавить</Button>
            }
        </div>
    }
}