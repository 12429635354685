import {
    REQUEST_CATEGORY_GROUPS,
    RECEIVE_CATEGORY_GROUPS,
    CREATE_CATEGORY_GROUP,
    MODIFY_CATEGORY_GROUP,
    CLOSE_CATEGORY_GROUP_EDIT,
    ON_SAVE_CATEGORY_GROUP,
    AFT_SAVE_CATEGORY_GROUP,
    ON_DELETE_CATEGORY_GROUP,
    AFT_DELETE_CATEGORY_GROUP,
    CREATE_CATEGORY,
    MODIFY_CATEGORY,
    CLOSE_CATEGORY_EDIT,
    ON_SAVE_CATEGORY,
    AFT_SAVE_CATEGORY,
    ON_DELETE_CATEGORY,
    AFT_DELETE_CATEGORY,
    SELECT_CATEGORY_ITEM,
    CATEGORY_ITEM_MODIFIED
} from '../ActionTypes/categoryActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";
import { sortByOrderAndName } from "../../funcs";

function getSelected(items, id){
    var selected = null;
    items.forEach(group => {
        if (group.id == id){
            selected = group;
            return;
        }
        
        if (group.children != null){
            group.children.forEach(category => {
                if (category.id == id){
                    selected = category;
                    return;
                }
            })
        }
    });

    return selected;
}

export const categoryItemModified = modified => dispatch => {
    dispatch({ type: CATEGORY_ITEM_MODIFIED, payload: { modified: modified } });
}

export const selectCategoryItem = id => dispatch => {
    dispatch({ type: SELECT_CATEGORY_ITEM, payload: { id: id } });
}

export const receiveCategoryGroups = (categoryGroupList, error) => dispatch => {

    var sorted = categoryGroupList;
    sorted.sort(sortByOrderAndName);
    sorted.forEach(item => {
        if (item.children == null) return;
        item.children.sort(sortByOrderAndName);
    });

    dispatch({
        type: RECEIVE_CATEGORY_GROUPS,
        payload: {
            error: error,
            categoryGroupList: categoryGroupList
        }
    });
}

export const requestCategoryGroups = () => dispatch => {
    dispatch({
        type: REQUEST_CATEGORY_GROUPS,
        payload: null
    });

    var url = new URL("categorygroup", API_URL);
    url.searchParams.set("includeCategories", 'true');

    axios.get(url.toString())
    .then((response) => {
        dispatch(receiveCategoryGroups(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveCategoryGroups(null, error));
        }
    });
}

export const createCategoryGroup = () => dispatch => {
    dispatch(selectCategoryItem(-1));
    dispatch(categoryItemModified(false));
    dispatch({
        type: CREATE_CATEGORY_GROUP,
        payload: null
    });
}

export const modifyCategoryGroup = (categoryGroup) => dispatch => {
    dispatch(selectCategoryItem(categoryGroup.id));
    dispatch(categoryItemModified(false));
    dispatch({
        type: MODIFY_CATEGORY_GROUP,
        payload: { item: categoryGroup }
    });
}

export const closeCategoryGroupEdit = () => dispatch => {
    dispatch(selectCategoryItem(-1));
    dispatch(categoryItemModified(false));
    dispatch({
        type: CLOSE_CATEGORY_GROUP_EDIT,
        payload: null
    });
}

export const aftDeteteCategoryGroup = error => dispatch => {
    dispatch({
        type: AFT_DELETE_CATEGORY_GROUP,
        payload: { error: error }
    });
    dispatch(closeCategoryGroupEdit());
    dispatch(requestCategoryGroups());
}

export const onDeleteCategoryGroup = categoryGroup => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_CATEGORY_GROUP,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "categorygroup/" + categoryGroup.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeteteCategoryGroup(null))
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeteteCategoryGroup(error));
        }
    });
}

export const aftSaveCategoryGroup = (categoryGroup, categoryGroupId, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_CATEGORY_GROUP,
        payload: { error: error }
    });

    if (error == null){
        var newGroup = categoryGroup;
        if (categoryGroupId != -1)
            newGroup.id = categoryGroupId;
        dispatch(modifyCategoryGroup(newGroup));
    }
    dispatch(requestCategoryGroups());
}

export const onSaveCategoryGroup = (categoryGroup, mode) => (dispatch, getState) =>{
    dispatch({
        type: ON_SAVE_CATEGORY_GROUP,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("categorygroup", API_URL);
    
    var savePromise = null;
    switch (mode){
        case 'createGroup': {
            savePromise = axios.post(
                url.toString(),
                categoryGroup,
                { headers: headers }
            );
            break;
        }
        case 'editGroup': {
            savePromise = axios.put(
                url.toString(),
                categoryGroup,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSaveCategoryGroup(categoryGroup, -1, null));
        else
            dispatch(aftSaveCategoryGroup(categoryGroup, parseInt(response.data), null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveCategoryGroup(categoryGroup, -1, error));
        }
    });
}

export const createCategory = () => (dispatch, getState) => {
    
    var selectedId = getState().category.selectedId;
    var categoryGroupList = getState().category.categoryGroupList;
    var selected = getSelected(categoryGroupList, selectedId);
    var group = (selected.group == null)
        ? selected
        : selected.group;

    dispatch(selectCategoryItem(-1));
    dispatch(categoryItemModified(false));
    dispatch({
        type: CREATE_CATEGORY,
        payload: { group: group }
    });
}

export const modifyCategory = (category) => dispatch => {
    dispatch(selectCategoryItem(category.id));
    dispatch(categoryItemModified(false));
    dispatch({
        type: MODIFY_CATEGORY,
        payload: { item: category }
    });
}

export const closeCategoryEdit = () => dispatch => {
    dispatch(selectCategoryItem(-1));
    dispatch(categoryItemModified(false));
    dispatch({
        type: CLOSE_CATEGORY_EDIT,
        payload: null
    });
}

export const aftDeleteCategory = error => dispatch => {
    dispatch({
        type: AFT_DELETE_CATEGORY,
        payload: { error: error }
    });
    dispatch(closeCategoryEdit());
    dispatch(requestCategoryGroups());
}

export const onDeleteCategory = category => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_CATEGORY,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL(
        "category/" + category.id.toString(),
        API_URL
    );

    axios.delete(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(aftDeleteCategory(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteCategory(error));
        }
    });
}

export const aftSaveCategory = (category, categoryId, error) => dispatch => {
    dispatch({
        type: AFT_SAVE_CATEGORY,
        payload: { error: error }
    });

    if (error == null){
        var newCategory = category;
        if (categoryId != -1)
            newCategory.id = categoryId;
        dispatch(modifyCategory(newCategory));
    }
    dispatch(requestCategoryGroups());
}

export const onSaveCategory = (category, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_CATEGORY,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("category", API_URL);
    url.searchParams.set("groupId", category.group.id);

    var savePromise = null;
    switch (mode){
        case 'createCategory': {
            savePromise = axios.post(
                url.toString(),
                category,
                { headers: headers }
            );
            break;
        }
        case 'editCategory': {
            savePromise = axios.put(
                url.toString(),
                category,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        if (!response.data || response.data == "")
            dispatch(aftSaveCategory(category, -1, null));
        else
            dispatch(aftSaveCategory(category, parseInt(response.data), null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveCategory(category, -1, error));
        }
    });
}