import {
    REQUEST_USERS,
    RECEIVE_USERS,
    CREATE_USER,
    MODIFY_USER,
    CLOSE_USER_EDIT,
    ON_DELETE_USER,
    AFT_DELETE_USER,
    ON_SAVE_USER,
    AFT_SAVE_USER,
    SELECT_USER,
    USER_MODIFIED,
    RESET_UMANAGER_ERROR
} from '../ActionTypes/uManagerActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const resetUManagerError = () => (dispatch) => {
    dispatch({
        type: RESET_UMANAGER_ERROR,
        payload: null
    });
}

export const userModified = (modified) => (dispatch) => {
    dispatch({
        type: USER_MODIFIED,
        payload: { modified: modified }
    });
}

export const selectUser = (selectedId) => dispatch => {
    dispatch({
        type: SELECT_USER,
        payload: { selectedId: selectedId }
    });
}

export const receiveUsers = (itemList, error) => dispatch => {
    dispatch({
        type: RECEIVE_USERS,
        payload: {
            itemList: itemList,
            error: error
        }
    });
}

export const requestUsers = () => (dispatch, getState) => {
    dispatch({
        type: REQUEST_USERS,
        payload: null
    });

    var headers = {
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("auth/users", API_URL);

    axios.get(
        url.toString(),
        { headers: headers }
    )
    .then((response) => {
        dispatch(receiveUsers(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(receiveUsers(null, error));
        }
    })
}

export const createUser = () => dispatch => {
    dispatch(selectUser(-1));
    dispatch(userModified(false));
    dispatch({
        type: CREATE_USER,
        payload: null
    });
}

export const modifyUser = (user) => dispatch => {
    dispatch(selectUser(user.userId));
    dispatch(userModified(false));
    dispatch({
        type: MODIFY_USER,
        payload: { item: user }
    });
}

export const closeUserEdit = () => dispatch => {
    dispatch(selectUser(-1));
    dispatch(userModified(false));
    dispatch({
        type: CLOSE_USER_EDIT,
        payload: null
    });
}

export const aftDeleteUser = error => dispatch => {
    dispatch({
        type: AFT_DELETE_USER,
        payload: { error: error }
    });
    dispatch(closeUserEdit());
    dispatch(requestUsers());
}

export const onDeleteUser = user => (dispatch, getState) => {
    dispatch({
        type: ON_DELETE_USER,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var url = new URL("auth/delete", API_URL);
    
    axios.delete(
        url.toString(),
        { 
            data: user,
            headers: headers
        }
    )
    .then((response) => {
        dispatch(aftDeleteUser(null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftDeleteUser(error));
        }
    })
}

export const aftSaveUser = (user, error) => dispatch => {

    var errText = "Неизвестная ошибка";
    if (error == null)
        errText = null;
    else if (error != null && error.response && error.response.data)
        errText = error.response.data.error; 

    dispatch({
        type: AFT_SAVE_USER,
        payload: { error: errText }
    });

    if (error == null)
        dispatch(modifyUser(user));
    dispatch(requestUsers());
}

export const onSaveUser = (user, mode) => (dispatch, getState) => {
    dispatch({
        type: ON_SAVE_USER,
        payload: null
    });

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ (getState().user.token != null) ? getState().user.token.accessToken : '' }`
    };

    var urlPath = (mode == 'create') ? 'auth/signup' : 'auth/change';
    var url = new URL(urlPath, API_URL);

    var savePromise = null;
    switch (mode){
        case 'create': {
            savePromise = axios.post(
                url.toString(),
                user,
                { headers: headers }
            );
            break;
        }
        case 'edit': {
            savePromise = axios.put(
                url.toString(),
                user,
                { headers: headers }
            );
            break;
        }
    }

    savePromise.then((response) => {
        dispatch(aftSaveUser(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(aftSaveUser(null, error));
        }
    })
}