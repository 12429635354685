import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import CategoryTree from '../CategoryTree/CategoryTree.js';
import CategoryEditor from '../CategoryEditor/CategoryEditor.js';

import { requestCategoryGroups } from '../../Redux/Actions/categoryActions';
import { connect } from 'react-redux';

import './CategoryPage.less';

class CategoryPage extends React.Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.requestCategoryGroups();
    }

    showErr(){
        if (this.props.error)
            return <Alert
                type="error"
                message="Внутренняя ошибка"
                description="Ошибка при выполнении операции с категориями товаров"
            />
        else
            return <Fragment/>
    }

    render(){
        return <div class="categoryPage">
            <Space direction='vertical'>
                {this.showErr()}
                <CategoryTree/>
                <CategoryEditor/>
            </Space>
        </div>
    }

}

const mapStateToProps = (state) => ({
    error: state.category.error
});

export default connect(
    mapStateToProps,
    { requestCategoryGroups }
)(CategoryPage);