export const ON_PRODUCT_SELECTION = "ON_PRODUCT_SELECTION";
export const AFT_PRODUCT_SELECTION = "AFT_PRODUCT_SELECTION";

export const ON_PRODUCT_SELECTION_REQUEST = "ON_PRODUCT_SELECTION_REQUEST";
export const ON_PRODUCT_SELECTION_RECEIVE = "ON_PRODUCT_SELECTION_RECEIVE";

export const ON_PRODUCT_SELECTION_SET_FILTER = "ON_PRODUCT_SELECTION_SET_FILTER";
export const ON_PRODUCT_SELECTION_SET_PAGING = "ON_PRODUCT_SELECTION_SET_PAGING";
export const ON_PRODUCT_SELECTION_PAGINATE = "ON_PRODUCT_SELECTION_PAGINATE";

export const ON_PRODUCT_SELECTION_SELECT = "ON_PRODUCT_SELECTION_SELECT";

export const ON_PRODUCT_SELECTION_CLOSE = "ON_PRODUCT_SELECTION_CLOSE";