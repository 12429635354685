import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, Tabs, InputNumber, Empty, Space, DatePicker, TimePicker, Checkbox } from 'antd';
import moment from 'moment';

import AboutTab from './SubSections/AboutTab/AboutTab.js';
import SmallImgTab from './SubSections/SmallImgTab/SmallImgTab.js';
import GalleryTab from './SubSections/GalleryTab/GalleryTab.js';

import {
    onDeleteProduct,
    onSaveProduct,
    closeProductEdit,
    productModified,
    onProductAddCategory,
    onProductDelCategory,
    onProductAddSmallImg,
    onProductDelSmallImg,
    onProductAddGallery,
    onProductDelGallery
} from '../../Redux/Actions/productActions';
import { onProductGroupSelection } from '../../Redux/Actions/productGroupSelectorActions';
import { onCategorySelection } from '../../Redux/Actions/categorySelectorActions';
import { connect } from 'react-redux';

import './ProductEditor.less';

const { TabPane } = Tabs;

class ProductEditor extends React.Component{
    
    aftModify = false;

    initialState = {
        id: 0,
        name: "",
        disabled: false,
        popular: false,
        smallImg: [],
        gallery: [],
        about: "",
        productCounts: [],
        productGroup: {},
        categories: [],
        productInfo: {
            article: "",
            fullName: "",
            cost: 0.0,
            discountCost: null,
            costUpdateDate: new Date(0)
        }
    }

    isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }
      
        return JSON.stringify(obj) === JSON.stringify({});
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.initialState,
            ...props.editor.item,
            productInfo: {
                ...this.initialState.productInfo,
                ...props.editor.item.productInfo
            },
            productGroup: {
                ...this.initialState.productGroup,
                ...props.editor.item.productGroup
            }
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onAddCategory = this.onAddCategory.bind(this);
        this.aftSelectAddCategory = this.aftSelectAddCategory.bind(this);
        this.onDeleteCategory = this.onDeleteCategory.bind(this);

        this.onAddSmallImg = this.onAddSmallImg.bind(this);
        this.onDelSmallImg = this.onDelSmallImg.bind(this);
        this.onAddGallery = this.onAddGallery.bind(this);
        this.onDelGallery = this.onDelGallery.bind(this);

        this.onModifyName = this.onModifyName.bind(this);
        this.onModifyArticle = this.onModifyArticle.bind(this);
        this.onModifyCost = this.onModifyCost.bind(this);
        this.onModifyDiscountCost = this.onModifyDiscountCost.bind(this);
        this.onModifyProductGroup = this.onModifyProductGroup.bind(this);
        this.onModifyAbout = this.onModifyAbout.bind(this);

        this.onModifyDisabled = this.onModifyDisabled.bind(this);
        this.onModifyPopular = this.onModifyPopular.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    componentWillReceiveProps(newProps){
        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.item,
            productInfo: {
                ...this.initialState.productInfo,
                ...newProps.editor.item.productInfo
            },
            productGroup: {
                ...this.initialState.productGroup,
                ...newProps.editor.item.productGroup
            }
        });
    }

    onSave(e){
        this.props.onSaveProduct(this.state, this.props.editor.mode);
    }

    onDelete(e){
        if (!window.confirm(`Вы действительно хотите удалить выбранный товар?`))
            return;

        this.props.onDeleteProduct(this.state);
    }

    onClose(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.closeProductEdit();
    }

    getCardTitle(){
        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание продукта';
                break;
            case 'edit':
                title_text = 'Изменение продукта';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onClose}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null: case 'create':
                return <Fragment/>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button" 
                    onClick={this.onDelete}
                >
                    Удалить продукт
                </Button>;
        }
    }

    onModifyName(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            name: e.target.value,
            productInfo: {
                ...this.state.productInfo,
                fullName: e.target.value
            }
        });
        this.props.productModified(true);
    }

    onModifyArticle(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            productInfo: {
                ...this.state.productInfo,
                article: e.target.value
            }
        });
        this.props.productModified(true);
    }

    onModifyDisabled(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            disabled: e.target.checked
        });
        this.props.productModified(true);
    }

    onModifyPopular(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            popular: e.target.checked
        });
        this.props.productModified(true);
    }

    onModifyCost(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            productInfo: {
                ...this.state.productInfo,
                cost: (e != "") ? e : 0,
                costUpdateDate: new Date()
            }
        });
    }

    onModifyDiscountCost(e){
        this.aftModify = true;
        this.setState({
            ...this.state,
            productInfo: {
                ...this.state.productInfo,
                discountCost: (e != "") ? e : null
            }
        });
    }

    onModifyProductGroup(e){
        this.aftModify = true;
        this.setState({ ...this.state, productGroup: e });
        this.props.productModified(true);
    }

    onModifyAbout(e){
        if (e != this.state.about){
            this.aftModify = true;
            this.props.productModified(true);
        }
        this.setState({ ...this.state, about: e });
    }

    onModifyCategory(e){

    }

    aftSelectAddCategory(e){
        this.props.onProductAddCategory(this.state, e);
    }

    onAddCategory(e){
        this.props.onCategorySelection(this.aftSelectAddCategory);
    }

    onDeleteCategory(e){
        this.props.onProductDelCategory(this.state, e);
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span>Название:</span>
                    <Input onChange={this.onModifyName} value={ this.state.name } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Артикул:</span>
                    <Input onChange={this.onModifyArticle} value={ this.state.productInfo.article } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Цена:</span>
                    <InputNumber onChange={this.onModifyCost} value={ this.state.productInfo.cost } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Ск. цена:</span>
                    <InputNumber onChange={this.onModifyDiscountCost} value={ this.state.productInfo.discountCost } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Скрытый:</span>
                    <Checkbox onChange={this.onModifyDisabled} checked={this.state.disabled} disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Популярный:</span>
                    <Checkbox onChange={this.onModifyPopular} checked={this.state.popular} disabled={!this.hasEditorRights()}/>
                </div>
            </div>
        </TabPane>
    }

    renderPgroup(){
        if (this.state.productGroup == null || this.isEmpty(this.state.productGroup))
            return <Empty description="Данный товар не принадлежит группе"/>

        return <Fragment>
            <span>Данный товар входит в группу <b>{this.state.productGroup.name}</b></span>
            <br/>
        </Fragment>
    }

    renderPgroupSelect(){
        return <TabPane tab="Группа товаров" key="2">
            { this.renderPgroup() }
            {
                this.hasEditorRights() &&
                <Button
                    type="primary"
                    onClick={ e => this.props.onProductGroupSelection(this.onModifyProductGroup, -1) }
                >Сменить группу товаров</Button>
            }
        </TabPane>
    }

    onAddSmallImg(e){
        this.props.onProductAddSmallImg(this.state, e);
    }

    onDelSmallImg(e){
        if (!window.confirm("Действительно удалить изображение?"))
            return;

        this.props.onProductDelSmallImg(this.state);
    }

    onAddGallery(e){
        this.props.onProductAddGallery(this.state, e.file, e.pos);
    }

    onDelGallery(e){
        if (!window.confirm("Действительно удалить изображение?"))
            return;

        this.props.onProductDelGallery(this.state, e.pos);
    }

    renderAboutTab(){
        return <TabPane tab="Описание товара" key="3">
            <AboutTab
                value={this.state.about}
                onChange={this.onModifyAbout}
                disabled={!this.hasEditorRights()}
                key={this.state.id}
            />
        </TabPane>
    }

    renderCatList(){
        if (this.state.categories.length == 0)
            return <Empty description="список категорий пуст"/>

        return <Fragment>
        {
            this.state.categories.map(category => {
                return <Space direction="horizontal">
                    { (category.group) && <span><b>{category.group.name}</b></span> }
                    <span>{category.name}</span>
                    {
                        this.hasEditorRights() &&
                        <Button type="ghost" danger onClick={e => this.onDeleteCategory(category)}>Удалить</Button>
                    }
                </Space>
            })
        }
        </Fragment>
    }

    renderCategoryTab(){
        return <TabPane tab="Категории" key="4">
            <div class="catList">
                <Space direction="vertical">
                    {this.renderCatList()}
                    {
                        this.hasEditorRights() &&
                        <Button block type="primary" onClick={this.onAddCategory}>Добавить категорию</Button>
                    }
                </Space>
            </div>
        </TabPane>
    }

    renderSmallImgTab(){
        if (this.props.editor.mode != 'edit')
            return <Fragment/>

        return <TabPane tab="Аватар" key="5">
            <SmallImgTab 
                imageList={this.state.smallImg}
                onSave={this.onAddSmallImg}
                onDelete={this.onDelSmallImg}
                disabled={!this.hasEditorRights()}
            />
        </TabPane>
    }

    renderGalleryTab(){
        if (this.props.editor.mode != 'edit')
            return <Fragment/>

            return <TabPane tab="Галерея" key="6">
                <GalleryTab
                    imageList={this.state.gallery}
                    onAdd={this.onAddGallery}
                    onDelete={this.onDelGallery}
                    disabled={!this.hasEditorRights()}
                />
            </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            {this.renderMainTab()}
            {this.renderPgroupSelect()}
            {this.renderAboutTab()}
            {this.props.editor.mode == 'edit' && this.renderCategoryTab() }
            {this.props.editor.mode == 'edit' && this.renderSmallImgTab() }
            {this.props.editor.mode == 'edit' && this.renderGalleryTab() }
        </Tabs>
    }

    render(){

        if (this.props.editor.mode == null)
            return <Fragment/>;

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="productEditor" title={this.getCardTitle()}>
                {this.renderTabs()}
                {
                    this.hasEditorRights() &&
                    <div class="btnLine">
                        <Space direction="horizontal">
                            <Button type="primary" onClick={this.onSave}>Сохранить</Button>
                            {this.getDeleteButton()}
                        </Space>
                    </div>
                }
            </Card>
        </Spin>

    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.product.editor,
    isFetching: state.product.isFetching
});

export default connect(
    mapStateToProps,
    { onDeleteProduct, onSaveProduct, closeProductEdit, productModified, onProductGroupSelection,
      onCategorySelection, onProductAddCategory, onProductDelCategory,
      onProductAddSmallImg, onProductDelSmallImg, onProductAddGallery, onProductDelGallery }
)(ProductEditor);