import React, { Fragment } from 'react';
import { Spin, Space, Input, Pagination, Avatar, Empty, Button, Modal, List } from 'antd';
import Scroll from '../../Utils/Scroll/Scroll.js';
import { API_URL } from '../../Variables';

import {
    onProductSelection,
    aftProductSelection,
    onProductSelectionRequest,
    onProductSelectionSetFilter,
    onProductSelectionSelect,
    onProductSelectionPaginate,
    onProductSelectionClose
} from '../../Redux/Actions/productSelectorActions';
import { connect } from 'react-redux';

import './ProductSelector.less';

class ProductSelector extends React.Component{
    constructor(props){
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.onChangeArticle = this.onChangeArticle.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
    }

    getAvatarSrc(item){
        try{
            var id = item.smallImg[0].Id;
        } catch {
            id = 0;
        }

        var urlPath = `file/${id.toString()}`;
        var url = new URL(urlPath, API_URL);
        return url.toString();
    }

    onCancel(e){
        this.props.onProductSelectionClose();
    }

    onOk(e){
        this.props.aftProductSelection();
    }

    onSelect(e){
        this.props.onProductSelectionSelect(e, e.id);
    }

    onSearch(e){
        this.props.onProductSelectionRequest();
    }

    onPaging(page, pageSize){
        this.props.onProductSelectionPaginate(page);
    }

    onChangeArticle(e){
        this.props.onProductSelectionSetFilter(null, (e.target.value == "") ? null : e.target.value);
    }

    onChangeName(e){
        this.props.onProductSelectionSetFilter(e.target.value, null);
    }

    renderSearch(){
        return <div class="search">
            <Space direction="horizontal">
                <span>Артикул:</span>
                <Input size="small" value={this.props.filter.article} onChange={this.onChangeArticle}/>
                <span>Название:</span>
                <Input size="small" value={this.props.filter.name} onChange={this.onChangeName}/>
                <Button type="primary" size="small" onClick={this.onSearch}>Поиск</Button>
            </Space>
        </div>
    }

    renderPagination(){
        if (this.props.productListPaging.totalPages == 0)
            return <Fragment/>

        return <div class="pagination">
            <Pagination
                current={this.props.productListPaging.selectedPage}
                total={this.props.productListPaging.totalPages * 10}
                onChange={this.onPaging}
            />
        </div>
    }

    renderList(){
        if (this.props.productList == null || this.props.productList.length == 0)
            return <Empty description="Список продуктов пуст"/>

        return <Scroll maxHeight="400" minHeight="0">
            <List
                itemLayout="horizontal"
                dataSource={this.props.productList}
                renderItem={item => (
                    <List.Item
                        onClick={ (e) => this.onSelect(item) }
                        className={ (this.props.selectedId == item.id) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            avatar={ <Avatar src={this.getAvatarSrc(item)}/> }
                            title={ <span>{ item.name }</span> }
                            description={ <span>{ item.productGroup?.name }</span> }
                        />
                    </List.Item>
                )}
            />
        </Scroll>
    }

    render(){
        return <Modal
            visible={this.props.visible}
            title="Выбор продукта"
            confirmLoading={this.props.isFetching}
            className="productSelector"
            footer={[
                <Button key="submit" type="primary" onClick={this.onOk} disabled={ this.props.selectedId == -1} >
                    Выбрать
                </Button>
            ]}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            {this.renderSearch()}
            {this.renderList()}
            {this.renderPagination()}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    visible: state.productSelector.visible,
    error: state.productSelector.error,
    isFetching: state.productSelector.isFetching,
    productList: state.productSelector.productList,
    selectedId: state.productSelector.selectedId,
    filter: state.productSelector.filter,
    productListPaging: state.productSelector.productListPaging
});

export default connect(
    mapStateToProps,
    { onProductSelection, aftProductSelection, onProductSelectionRequest, onProductSelectionSelect, onProductSelectionPaginate, onProductSelectionClose, onProductSelectionSetFilter }
)(ProductSelector);