import React, { Fragment } from 'react';
import { Button, Card, Input, Spin, InputNumber, Tabs, Empty, Space } from 'antd';

import { onDeleteCity, onSaveCity, closeCityEdit, cityModified } from '../../Redux/Actions/cityActions';

import './CityEditor.less';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

class CityEditor extends React.Component{
    constructor(props){
        super(props);

        this.initialState = {
            id: 0,
            name: "",
            region: "",
            sypexGeoId: 0,
            deliveryBlock: []
        }

        this.state = {
            ...this.initialState,
            ...props.editor.city
        };

        this.aftModify = false;

        this.onSaveCity = this.onSaveCity.bind(this);
        this.onModifyName = this.onModifyName.bind(this);
        this.onModifyRegion = this.onModifyRegion.bind(this);
        this.onModifySypexId = this.onModifySypexId.bind(this);
        this.onDeleteCity = this.onDeleteCity.bind(this);
        this.onCloseEditor = this.onCloseEditor.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    componentWillReceiveProps(newProps){

        if (this.aftModify == true){
            this.aftModify = false;
            return;
        }

        this.setState({
            ...this.initialState,
            ...newProps.editor.city
        });
    }

    getCardTitle(){

        var title_text = '';
        switch (this.props.editor.mode) {
            case null:
                title_text = 'Закрытый редактор';
                break;
            case 'create':
                title_text = 'Создание города';
                break;
            case 'edit':
                title_text = 'Изменение города';
                break;
        }

        return <div class="editorTitle">
            <span>{title_text}</span>
            <Button type="danger" size="small" onClick={this.onCloseEditor}>Закрыть</Button>
        </div>;
    }

    getDeleteButton(){
        switch (this.props.editor.mode){
            case null:
                return <Fragment></Fragment>;
            case 'create':
                return <Fragment></Fragment>;
            case 'edit':
                return <Button 
                    type="danger" 
                    htmlType="button" 
                    onClick={this.onDeleteCity}
                >
                    Удалить город
                </Button>;
        }
    }

    onModifyName(e){
        this.aftModify = true;
        this.setState({ ...this.state, name: e.target.value });
        this.props.cityModified(true);
    }

    onModifyRegion(e){
        this.aftModify = true;
        this.setState({ ...this.state, region: e.target.value });
        this.props.cityModified(true);
    }

    onModifySypexId(e){
        this.aftModify = true;
        this.setState({ ...this.state, sypexGeoId: (e != "") ? e : 0 });
        this.props.cityModified(true);
    }

    onSaveCity(e){
        this.props.onSaveCity(this.state, this.props.editor.mode);
    }

    onDeleteCity(e){
        if (!window.confirm("Вы действительно хотите удалить выбранный город?"))
            return;

        this.props.onDeleteCity(this.state);
    }

    onCloseEditor(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Вы действительно хотите закрыть редактор? При закрытии внесённые изменения не будут сохранены!")){
            return;
        }

        this.props.closeCityEdit();
    }

    renderMainTab(){
        return <TabPane tab="Основные данные" key="1">
            <div class="mainTab">
                <div class="inpLine">
                    <span>Название:</span>
                    <Input onChange={this.onModifyName} value={ this.state.name } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Регион:</span>
                    <Input onChange={this.onModifyRegion} value={ this.state.region } disabled={!this.hasEditorRights()}/>
                </div>
                <div class="inpLine">
                    <span>Id Sypex:</span>
                    <InputNumber onChange={this.onModifySypexId} value={ this.state.sypexGeoId } style={{ width: "100%" }} disabled={!this.hasEditorRights()}/>
                </div>
            </div>
        </TabPane>
    }

    renderDeliveryBlockTab(){
        return <TabPane tab="Способы доставки" key="2">
            <Empty description="в разработке"/>
        </TabPane>
    }

    renderTabs(){
        return <Tabs defaultActiveKey="1">
            { this.renderMainTab() }
            { this.renderDeliveryBlockTab() }
        </Tabs>
    }

    render(){

        if (this.props.editor.mode == null){
            return <Fragment/>;
        }

        return <Spin tip="Выполнение операции..." spinning={this.props.isFetching}>
            <Card className="cityEditor" title={this.getCardTitle()}>
                    { this.renderTabs() }
                    {
                        this.hasEditorRights() &&
                        <div class="btnLine">
                            <Button type="primary" onClick={this.onSaveCity}>Сохранить</Button>
                            { this.getDeleteButton() }
                        </div>
                    }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    editor: state.city.city_editor,
    isFetching: state.city.isFetching
});

export default connect(
    mapStateToProps,
    { onSaveCity, onDeleteCity, closeCityEdit, cityModified }
)(CityEditor);