import {
    ON_CATEGORY_SELECTION,
    AFT_CATEGORY_SELECTION,
    ON_CATEGORY_SELECTION_REQUEST,
    ON_CATEGORY_SELECTION_RECEIVE,
    ON_CATEGORY_SELECTION_SELECT,
    ON_CATEGORY_SELECTION_CLOSE
} from '../ActionTypes/categorySelectorActionTypes';
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

function setTopNotCheckable(arr){
    arr.forEach(item => {
        item.disabled = true;
    });
}

export const onCategorySelectionSelect = (selectedItem, selectedId) => ({
    type: ON_CATEGORY_SELECTION_SELECT,
    payload: {
        selectedItem: selectedItem,
        selectedId: selectedId
    }
});

export const onCategorySelectionReceive = (categoryGroups, error) => dispatch => {
    dispatch({
        type: ON_CATEGORY_SELECTION_RECEIVE,
        payload: {
            categoryGroups: categoryGroups,
            error: error
        }
    });
    dispatch(onCategorySelectionSelect(null, -1));
}

export const onCategorySelectionRequest = () => dispatch => {
    dispatch({
        type: ON_CATEGORY_SELECTION_REQUEST,
        payload: null
    });

    var url = new URL("categorygroup", API_URL);
    url.searchParams.set('includeCategories', 'true');
    
    axios.get(url.toString())
    .then((response) => {
        setTopNotCheckable(response.data);
        dispatch(onCategorySelectionReceive(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(onCategorySelectionReceive(null, error));
        }
    });
}

export const onCategorySelectionClose = () => ({
    type: ON_CATEGORY_SELECTION_CLOSE,
    payload: null
});

export const onCategorySelection = (selectHandler) => dispatch => {
    dispatch({
        type: ON_CATEGORY_SELECTION,
        payload: {
            selectHandler: selectHandler
        }
    });
    dispatch(onCategorySelectionRequest());
}

export const aftCategorySelection = () => (dispatch, getState) => {
    var handler = getState().categorySelector.selectHandler;
    var selectedItem = getState().categorySelector.selectedItem;

    dispatch({
        type: AFT_CATEGORY_SELECTION,
        payload: null
    });

    if (handler != null && selectedItem !== undefined)
        handler(selectedItem);
}