import React, { Fragment } from 'react';
import { List, Button, Space, Avatar } from 'antd'
import Scroll from '../../../../Utils/Scroll/Scroll.js';

import { API_URL } from '../../../../Variables';

import './ProductSublist.less';

export default class ProductSublist extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            selectedId: -1,
            selectedItem: null
        }

        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentWillReceiveProps(newProps){
        this.setState({
            selectedId: -1,
            selectedItem: null
        });
    }

    getAvatarSrc(item){
        try{
            var id = item.smallImg[0].Id;
        } catch {
            id = 0;
        }

        var urlPath = `file/${id.toString()}`;
        var url = new URL(urlPath, API_URL);
        return url.toString();
    }

    onAdd(e){
        this.props.onAddProduct();
    }

    onDelete(e){
        this.props.onDeleteProduct(this.state.selectedItem);
    }

    onClick(e){
        this.setState({
            selectedId: e.id,
            selectedItem: e
        });
    }

    renderList(){
        return <Scroll maxHeight="400" minHeight="0">
            <List
                itemLayout="horizontal"
                dataSource={this.props.products}
                renderItem={ item => (
                    <List.Item
                        onClick={ e => this.onClick(item) }
                        className={ (this.state.selectedId == item.id) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            avatar={ <Avatar src={this.getAvatarSrc(item)}/> }
                            title={ <span>{ item.name }</span> }
                            description={ <span>{ item.productGroup?.name }</span> }
                        />
                    </List.Item>
                )}
            />
        </Scroll>
    }

    renderButtons(){
        if (this.props.disabled == true)
            return <Fragment/>

        return <Space direction="horizontal">
            <Button type="primary" onClick={this.onAdd}>Добавить продукт</Button>
            <Button type="primary" danger onClick={this.onDelete} disabled={this.state.selectedItem == null}>
                Удалить продукт
            </Button>
        </Space>
    }

    render(){
        return <div class="productSublist">
            <Space direction="vertical">
                {this.renderList()}
                {this.renderButtons()}
            </Space>
        </div>
    }
}