import React, { Fragment } from 'react';
import { TreeNode } from 'antd/lib/tree-select';
import { Card, Spin, Tree, Empty, Button } from 'antd';

import { requestRootGroup, createPgroup, modifyPgroup, selectPgroup, closePgroupEdit } from '../../Redux/Actions/productGroupActions';
import { connect } from 'react-redux';

import './ProductGroupTree.less';

class ProductGroupTree extends React.Component{
    constructor(props){
        super(props);

        this.selectedNode = null;
        this.onCreate = this.onCreate.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onCreate(e){
        if (   this.props.productGroupEditor.modified == true
            && !window.confirm("Вы действительно хотите сменить группу? При смене внесённые изменения не будут сохранены!")){
            return;
        }

        this.props.createPgroup(this.props.selectedGroupId, this.selectedNode);
    }

    onCheck(checkedKeys, info){

        if (   this.props.productGroupEditor.modified == true
            && !window.confirm("Вы действительно хотите сменить группу? При смене внесённые изменения не будут сохранены!")){
            return;
        }

        this.selectedNode = info.node;    
        if (info.node.name == "Root"){
            this.props.closePgroupEdit();
            this.props.selectPgroup(info.node.id);
        }
        else
            this.props.modifyPgroup(info.node, (info.node.parentGroup == null) ? -1 : info.node.parentGroup.id);
    }


    renderTree(){
        if (this.props.rootGroup == null)
            return <Empty description="Дерево пусто"/>

        return <Tree
            checkedKeys={ (this.props.selectedGroupId == -1) ? [] : [ this.props.selectedGroupId.toString() ]}
            onCheck={this.onCheck}
            treeData={[this.props.rootGroup]}
            checkable={true}
            showLine={true}
            selectable={false}
            checkStrictly={true}
            defaultExpandAll={true}
        />
    }

    render(){
        return <Spin tip="Обновление дерева..." spinning={this.props.isFetching}>
            <Card className="productGroupList">
                {this.renderTree()}
                {
                    this.hasEditorRights() &&
                    <Button type="primary" onClick={this.onCreate} disabled={this.props.selectedGroupId == -1}>
                        Добавить группу
                    </Button>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    rootGroup: state.productGroup.rootGroup,
    selectedGroupId: state.productGroup.selectedGroupId,
    isFetching: state.productGroup.isFetching,
    productGroupEditor: state.productGroup.productGroupEditor
});

export default connect(
    mapStateToProps,
    { requestRootGroup, createPgroup, modifyPgroup, selectPgroup, closePgroupEdit }
)(ProductGroupTree);