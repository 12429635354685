export const SET_BILL_FILTER = "SET_BILL_FILTER";
export const SET_BILL_LIST_PAGING = "SET_BILL_LIST_PAGING";

export const REQUEST_BILLS = "REQUEST_BILLS";
export const RECEIVE_BILLS = "RECEIVE_BILLS";
export const PAGINATE_BILLS = "PAGINATE_BILLS";

export const CREATE_BILL = "CREATE_BILL";
export const MODIFY_BILL = "MODIFY_BILL";
export const CLOSE_BILL_EDIT = "CLOSE_BILL_EDIT";

export const BILL_MODIFIED = "BILL_MODIFIED";
export const SELECT_BILL = "SELECT_BILL";

export const ON_SAVE_BILL = "ON_SAVE_BILL";
export const AFT_SAVE_BILL = "AFT_SAVE_BILL";

export const ON_DELETE_BILL = "ON_DELETE_BILL";
export const AFT_DELETE_BILL = "AFT_DELETE_BILL";