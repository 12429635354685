import React, { Fragment } from 'react';
import { Alert, Space } from 'antd';

import PhoneCallList from '../PhoneCallList/PhoneCallList.js';
import PhoneCallEditor from '../PhoneCallEditor/PhoneCallEditor.js';

import {
    requestPhoneCalls
} from '../../Redux/Actions/phoneCallActions';
import { connect } from 'react-redux';

import './PhoneCallPage.less';

class PhoneCallPage extends React.Component{
    
    constructor(props){
        super(props);
    }

    componentWillMount(){
        this.props.requestPhoneCalls();
    }

    showErr(){
        if (this.props.error == null)
            return <Fragment/>

        return <Alert
            type="error"
            message="Внутренняя ошибка"
            description="Ошибка при выполнении операции со звонками"
        />
    }

    render(){
        return <div class="phoneCallPage">
            <Space direction="vertical">
                {this.showErr()}
                <PhoneCallList/>
                <PhoneCallEditor/>
            </Space>
        </div>
    }
}

const mapStateToProps = (state) => ({
    error: state.phoneCall.error
});

export default connect(
    mapStateToProps,
    { requestPhoneCalls }
)(PhoneCallPage);