import {
    ON_CITY_SELECTION,
    AFT_CITY_SELECTION,
    ON_CITY_SELECTION_REQUEST,
    ON_CITY_SELECTION_RECEIVE,
    ON_CITY_SELECTION_SELECT,
    ON_CITY_SELECTION_CLOSE
} from "../ActionTypes/citySelectorActionTypes";
import { logout } from '../Actions/userActions';
import axios from 'axios';
import { API_URL } from "../../Variables";

export const onCitySelectionSelect = (selected ,selectedId) => ({
    type: ON_CITY_SELECTION_SELECT,
    payload: { 
        selectedId: selectedId, 
        selectedItem: selected
    }
});

export const onCitySelectionReceive = (itemList, error) => dispatch => {
    dispatch({ 
        type: ON_CITY_SELECTION_RECEIVE,
        payload: { itemList: itemList, error: error } 
    });
    dispatch(onCitySelectionSelect(null, -1));
};

export const onCitySelectionRequest = () => dispatch => {
    dispatch({
        type: ON_CITY_SELECTION_REQUEST,
        payload: null
    });

    axios.get( new URL("city", API_URL).toString() )
    .then((response) => {
        dispatch(onCitySelectionReceive(response.data, null));
    })
    .catch((error) => {
        if (error.request || error.response){
            if (error.response.status == 401){
                dispatch(logout());
                return;
            }
            dispatch(onCitySelectionReceive(null, error));
        }
    })
}

export const onCitySelectionClose = () => ({
    type: ON_CITY_SELECTION_CLOSE,
    payload: null
});

export const onCitySelection = (selectHandler) => dispatch => {
    dispatch({
        type: ON_CITY_SELECTION,
        payload: {
            selectHandler: selectHandler
        }
    });
    dispatch(onCitySelectionRequest());
}

export const aftCitySelection = () => (dispatch, getState) => {

    var handler = getState().citySelector.selectHandler;
    var selectedId = getState().citySelector.selectedId;
    var selected = getState().citySelector.itemList.find(x => x.id == selectedId);

    dispatch({
        type: AFT_CITY_SELECTION,
        payload: null
    });

    if (handler != null && selected !== undefined){
        handler(selected);
    }
}