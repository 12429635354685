import {
    SET_BILL_FILTER,
    SET_BILL_LIST_PAGING,
    REQUEST_BILLS,
    RECEIVE_BILLS,
    PAGINATE_BILLS,
    CREATE_BILL,
    MODIFY_BILL,
    CLOSE_BILL_EDIT,
    BILL_MODIFIED,
    SELECT_BILL,
    ON_SAVE_BILL,
    AFT_SAVE_BILL,
    ON_DELETE_BILL,
    AFT_DELETE_BILL
} from '../ActionTypes/billActionTypes';

const initialState = {
    filter: {
        minDate: null,
        maxDate: null,
        status: null,
        order: 0
    },
    error: null,
    isFetching: false,
    billList: null,
    billListPaging: {
        selectedPage: 0,
        totalPages: 0
    },
    selectedId: -1,
    editor: {
        mode: null,
        modified: false,
        item: {}
    }
}

export default function(state = initialState, action){
    switch (action.type){
        case SET_BILL_FILTER: return {
            ...state,
            filter: {
                minDate: action.payload.minDate,
                maxDate: action.payload.maxDate,
                status: action.payload.status,
                order: action.payload.order
            }
        };
        case SET_BILL_LIST_PAGING: return {
            ...state,
            billListPaging: {
                selectedPage: action.payload.selectedPage,
                totalPages: action.payload.totalPages
            }
        };
        case REQUEST_BILLS: return {
            ...state,
            error: null,
            isFetching: true
        };
        case RECEIVE_BILLS: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
            billList: action.payload.billList
        };
        case PAGINATE_BILLS: return {
            ...state,
            error: null,
            billListPaging: {
                ...state.billListPaging,
                selectedPage: action.payload.selectedPage
            }
        };
        case CREATE_BILL: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'create',
                item: {}
            }
        };
        case MODIFY_BILL: return {
            ...state,
            error: null,
            editor: {
                ...state.editor,
                mode: 'edit',
                item: action.payload.item
            }
        };
        case CLOSE_BILL_EDIT: return {
            ...state,
            editor: initialState.editor
        };
        case BILL_MODIFIED: return {
            ...state,
            editor: {
                ...state.editor,
                modified: action.payload.modified
            }
        }
        case SELECT_BILL: return {
            ...state,
            selectedId: action.payload.selectedId
        }
        case ON_SAVE_BILL: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_SAVE_BILL: return {
            ...state,
            error: action.payload.error,
            isFetching: false,
        };
        case ON_DELETE_BILL: return {
            ...state,
            error: null,
            isFetching: true
        };
        case AFT_DELETE_BILL: return {
            ...state,
            error: action.payload.error,
            isFetching: false
        };
        default: return state;
    }
}