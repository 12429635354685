import React from 'react';
import { Card, Spin, List, Empty, Button } from 'antd';

import Scroll from '../../Utils/Scroll/Scroll.js';

import {
    requestPromos,
    createPromo,
    modifyPromo,
    selectPromo
} from '../../Redux/Actions/promoActions';
import { connect } from 'react-redux';

import './PromoList.less';

class PromoList extends React.Component{
    constructor(props){
        super(props);

        this.onCreate = this.onCreate.bind(this);
        this.onModify = this.onModify.bind(this);
    }

    hasEditorRights(){
        return this.props.userRole != 'Moderator';
    }

    onCreate(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.createPromo();
    }

    onModify(e){
        if (   this.props.editor.modified == true
            && !window.confirm("Присутствуют несохранённые изменения! Продолжить?")){
            return;
        }

        this.props.modifyPromo(e);
    }

    renderList(){
        if (this.props.promoList == null || this.props.promoList.list == []){
            return <Empty description="Список промоакций пуст"/>
        }

        return <Scroll maxHeight="500" minHeight="100">
            <List
                itemLayout="horizontal"
                dataSource={this.props.promoList}
                renderItem={ item =>
                    <List.Item
                        onClick={ e => this.onModify(item) }
                        className={ (this.props.selectedId == item.id) ? `selected` : `` }
                    >
                        <List.Item.Meta
                            title={ <span>{ ('mainText' in item && item.mainText != "") ? item.mainText : 'Без названия' }</span> }
                            description={ <span>{ ('subText' in item && item.subText != "") ? item.subText : 'Без текста' }</span> }
                        />
                    </List.Item>
                }
            />
        </Scroll>
    }

    render(){
        return <Spin tip="Обновление списка..." spinning={this.props.isFetching}>
            <Card className="promoList">
                {this.renderList()}
                {
                    this.hasEditorRights() &&
                    <Button type="primary" onClick={this.onCreate} block>Добавить промоакцию</Button>
                }
            </Card>
        </Spin>
    }
}

const mapStateToProps = (state) => ({
    userRole: state.user.token?.userRole,
    promoList: state.promo.promoList,
    selectedId: state.promo.selectedId,
    isFetching: state.promo.isFetching,
    editor: state.promo.editor
});

export default connect(
    mapStateToProps,
    { requestPromos, createPromo, modifyPromo, selectPromo }
)(PromoList);